.list{
  ul{
    color: $text-black;
    li{
      list-style: none;
      margin: 5px 0;
      position: relative;
      line-height: 28px;
      font-size: 16px;
      &:before{
        font-family: 'FontAwesome', serif;
        content: "\f105";
        padding-right: 20px;
        color: $theme-color;
      }
    }
  }

  ol{
    color: #333333;
    padding: 0 15px;
    li {
      margin: 5px 0;
      position: relative;
      line-height: 28px;
      padding-left: 10px;

      &::marker{
        color: $theme-color;
      }
    }
  }
}
