/*************************************
      slider-parallax one page
**************************************/
/*scroll-down*/
/*@-moz-keyframes "fadebounce" {
  0% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -moz-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes "fadebounce" {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-o-keyframes "fadebounce" {
  0% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -o-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-ms-keyframes "fadebounce" {
  0% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    -ms-transform: translateY(25px);
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes "fadebounce" {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(25px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}*/
.slider-parallax {
  height: 86vh;
  position: relative;
}
.slider-content-middle {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0,-50%);
  -moz-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  transform: translate(0,-50%);
}
.scroll-down {
  text-align: center;
  width: 34px;
  height: 58px;
  display: inline-block;
  border-radius: 20px;
  border: 2px solid $white;
  position: absolute;
  left: 50%;
  bottom: 60px;
  margin-left: -17px;
  line-height: $l-height-50;
  i {
    height: 10px;
    width: 4px;
    background: $white;
    display: inline-block;
    border-radius: 3px;
    -webkit-animation-name: fadebounce;
    -moz-animation-name: fadebounce;
    -ms-animation-name: fadebounce;
    -o-animation-name: fadebounce;
    animation-name: fadebounce;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -ms-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}
.personal-typer-banner {
  span.sub-text {
    font-size: $fs-22;
    line-height: $l-height-22;
    margin-bottom: 16px;
    display: block;
  }
  b {
    border-bottom: 2px solid $theme-color;
  }
  h1 {
    font-size: $fs-100;
    line-height: $l-height-100;
    font-weight: $fw-6;
    color: $theme-color;
    span {
      color: $white;
      font-size: $fs-50;
      display: inline-block;
      transform: rotate(-90deg);
      line-height: $l-height-50;
      position: relative;
      top: -11px;
      left: -20px;
    }
  }
  h2 {
    border-left: 3px solid $theme-color;
    padding-left: 10px;
    margin-top: 20px;
  }
}
.typer-banner {
  h1 {
    font-size: $fs-100;
    line-height: $l-height-100;
    font-weight: $fw-6;
  }
  p {
    font-size: $fs-22;
    line-height: $l-height-22;
  }
}
.slider-content {
  .typer {
    color: $theme-color !important;
  }
}
.popup-video-banner {
  h1 {
    font-size: $fs-60;
    line-height: $l-height-80;
    font-weight: $fw-4;
  }
  a {
    span {
      margin-left: 6px;
      width: 50px;
      height: 50px;
      line-height: $l-height-50;
      display: inline-block;
      text-align: center;
      background: $white;
      color: $theme-color;
      border-radius: 50%;
      /*      @include transition (all, 0.5s, ease);*/
    }
    &:hover {
      span {
        background: $theme-color;
        color: $white;
      }
    }
    strong {
      padding-left: 10px;
      display: inline-block;
    }
  }
}
#video-background.bg-overlay-black-50 {
  &:before {
    z-index: 0;
  }
}
.video-background-banner {
  h1 {
    font-size: $fs-80;
    line-height: $l-height-80;
    font-weight: $fw-6;
    text-transform: uppercase;
  }
  .slider-content {
    border: 20px solid $theme-color;
    width: 50%;
    margin: 0 auto;
    padding: 60px 30px;
    span {
      font-size: $fs-22;
      line-height: $l-height-22;
      color: $white;
      display: block;
      margin-bottom: 20px;
    }
    p {
      font-size: $fs-18;
      line-height: $l-height-22;
      color: $white;
      margin-top: 20px;
    }
  }
}
.portfolio-banner {
  h1 {
    font-size: $fs-80;
    line-height: $l-height-80;
    font-weight: $fw-6;
  }
  .slider-content {
    background: $white;
    width: 50%;
    margin: 0 auto;
    padding: 60px 30px;
    /*    @include box-shadow(0px, 0px, 80px, rgba(0, 0, 0, 0.02));*/
    span {
      text-transform: uppercase;
      letter-spacing: 10px;
      font-size: $fs-18;
      line-height: $l-height-18;
      display: block;
      margin-bottom: 20px;
      font-weight: $fw-6;
      i {
        line-height: 20px;
      }
    }
    h1 {
      font-size: $fs-70;
      margin-bottom: 0;
    }
  }
}


/*************************************
        home page section
 **************************************/

/*split-section*/
.our-service-home {
  padding-bottom: 230px;
}
.custom-content {
  margin-top: -220px;
  background: $theme-color;
  position: relative;
  z-index: 9;
  padding: 50px;
}
.decolines {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
.decolines--fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.decoline {
  position: absolute;
}
.feature-step {
  h1 {
    font-size: $fs-60;
  }
  h3 {
    font-weight: 300;
    margin: 30px 0px 20px;
  }
  a {
    font-size: $fs-14;
    color: $theme-color;
    &:hover {
      color: $black;
    }
  }
}
.feature-step-2-box {
  padding: 70px 30px;
  z-index: 99;
  position: relative;
}
.feature-step-2.bg-01 {
  background: url(../images/blog/05.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.feature-step-2.bg-02 {
  background: url(../images/blog/06.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.feature-step-2.bg-03 {
  background: url(../images/blog/09.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.feature-step-2.bg-overlay-black-50 {
  &:before {
    z-index: 0;
  }
}
.feature-step-2 {
  .feature-step-2-title-left {
    h1 {
      font-size: 75px;
      line-height: $l-height-60;
      color: $white;
    }
    opacity: 0.5;
    display: table-cell;
    padding-right: 26px;
  }
  h3 {
    margin-bottom: 0;
  }
  h2 {
    font-weight: 200;
    position: relative;
    padding-bottom: 10px;
    font-size: 28px;
  }
  img {
    display: block;
    width: 100%;
  }
  .button {
    margin-top: 20px;
  }
}
.feature-step-2-title-2-right {
  display: table-cell;
  vertical-align: top;
}
.feature-step-2.black-bg {
  .feature-step-2-box {
    position: inherit;
    top: inherit;
    transform: inherit;
    display: inline-block;
  }
}
.feature-step-2.theme-bg {
  .feature-step-2-box {
    position: inherit;
    top: inherit;
    transform: inherit;
    display: inline-block;
  }
}
.service-blog {
  padding-top: 50px;
  padding-right: 25px;
  margin-bottom: 100px;
  p {
    position: relative;
    z-index: 9;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  b {
    position: absolute;
    z-index: 0;
    top: -35px;
    font-family: $font-base;
    right: 0;
    font-size: 300px;
    color: #dde1eb;
    line-height: normal;
    opacity: 0.3;
  }
  ul {
    border-right: 6px solid $theme-color;
    padding-right: 20px;
  }
  li {
    color: $text-dark-gray;
    font-size: $fs-16;
    line-height: 22px;
    padding: 2px 0 7px;
  }
}
.service-blog.left {
  b {
    left: 0;
    right: inherit;
  }
  ul {
    border-left: 6px solid $theme-color;
    border-right: 0 none;
    padding-left: 20px;
    padding-right: 0;
  }
}
.split-section {
  position: relative;
  z-index: 10;
  .img-holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .img-side.img-right {
    right: 0;
  }
  .img-side.img-left {
    left: 0;
  }
  .img-side {
    right: 0;
    padding: 0;
    position: absolute !important;
    top: 0px;
    height: 100%;
    overflow: hidden;
    z-index: 100;
  }
}
.our-awesome-core {
  .img-side {
    top: 20px;
  }
}


/*@-webkit-keyframes "sonarEffect" {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    -webkit-transform: scale(1.5);
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
  }
}
@-moz-keyframes "sonarEffect" {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    -moz-transform: scale(1.5);
    opacity: 0;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
  }
}
@keyframes "sonarEffect" {
  0% {
    opacity: 0.3;
  }
  40% {
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(255,255,255,0.1), 0 0 10px 10px $white, 0 0 0 10px rgba(255,255,255,0.5);
    transform: scale(1.5);
    opacity: 0;
  }
}*/

.who-we-are-left {
  .owl-carousel {
    .owl-dots {
      bottom: 26px;
      position: absolute;
    }
  }
  .owl-carousel.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          width: 13px;
          height: 13px;
          border-radius: 50%;
        }
      }
    }
  }
}
.resume-page {
  .container-fluid {
    padding: 0 70px;
  }
}
.resume-block {
  .resume-icon {
    float: left;
    margin-right: 30px;
    span {
      font-size: $fs-30;
      line-height: $l-height-50;
      color: $theme-color;
    }
  }
  .resume-name {
    display: table-cell;
    span {
      color: #9ea8b6;
      font-size: $fs-16;
    }
  }
}
.resume-contact {
  .g-map {
    padding-top: 120%;
  }
  .contact-form-main {
    padding: 80px 80px;
  }
  .footer-widget-social {
    a {
      i {
        color: $white;
        background: rgba(43, 43, 43, 0.12);
        width: 45px;
        height: 45px;
        line-height: 45px;
        &:hover {
          background: rgba(43, 43, 43, 0.19);
        }
      }
    }
  }
}
.isotope-filters {
  display: table;
  margin: 0 auto 50px;
  text-align: center;
  button {
    margin: 4px;
    cursor: pointer;
    padding: 6.5px 25px;
    font-size: $fs-15;
    border-radius: 3px;
    background: transparent;
    color: $text-black;
    font-weight: $fw-5;
    border: 1px solid #ccc;
    text-transform: capitalize;
    /*    @include transition (all, 0.2s, ease);*/
    &:focus {
      outline: none;
      outline-style: none;
      outline-offset: 0;
    }
    &:hover {
      background: $theme-color;
      color: $white;
      border-color: $theme-color;
    }
  }
  button.active {
    background: $theme-color;
    color: $white;
    border-color: $theme-color;
  }
  button+button {
    margin-left: 10px;
  }
}
.isotope-filters.text-left {
  display: block;
  margin: 30px 0;
  text-align: left;
}
.isotope {
  margin: 0 -15px 0 0;
  .grid-item {
    img {
      width: 100%;
    }
  }
}
.masonry {
  margin: 0 -15px 0 0;
  .masonry-item {
    img {
      width: 100%;
    }
  }
}
.isotope.columns-1 {
  .grid-item {
    width: 100%;
    padding: 0 15px 15px 0;
  }
}
.isotope.columns-2 {
  .grid-item {
    width: 50%;
    padding: 0 15px 15px 0;
  }
}
.isotope.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 0 15px 15px 0;
  }
}
.isotope.columns-4 {
  .grid-item {
    width: 25%;
    padding: 0 15px 15px 0;
  }
  .portfolio-item {
    .portfolio-overlay {
      h4 {
        font-size: $fs-16;
      }
      h6 {
        display: none;
      }
    }
  }
}
.isotope.columns-5 {
  .grid-item {
    width: 20%;
    padding: 0 15px 15px 0;
  }
  .portfolio-item {
    .portfolio-overlay {
      h4 {
        font-size: $fs-16;
      }
      h6 {
        display: none;
      }
    }
  }
}
.isotope.no-padding {
  .grid-item {
    padding: 0 !important;
  }
}
.masonry.no-padding {
  .masonry-item {
    padding: 0 !important;
  }
}
.masonry.columns-2 {
  .masonry-item {
    width: 50%;
    padding: 0 15px 15px 0;
  }
}
.masonry.columns-3 {
  .masonry-item {
    width: 33.33333333%;
    padding: 0 15px 15px 0;
  }
}
.masonry.columns-4 {
  .masonry-item {
    width: 25%;
    padding: 0 15px 15px 0;
  }
  .portfolio-item {
    .portfolio-overlay {
      h4 {
        font-size: $fs-16;
      }
      h6 {
        display: none;
      }
    }
  }
}
.masonry.columns-5 {
  .masonry-item {
    width: 20%;
    padding: 0 15px 15px 0;
  }
  .portfolio-item {
    .portfolio-overlay {
      h4 {
        font-size: $fs-16;
      }
      h6 {
        display: none;
      }
    }
  }
}
.filters-vertical {
  margin: 0;
  button {
    display: block;
    margin: 0;
    border: none;
    padding: 6.5px 0px;
    text-align: left;
  }
}
.filters-vertical.isotope-filters {
  button+button {
    margin: 0;
  }
}
.isotope-filters.filters-vertical {
  button.active {
    color: $theme-color;
    background: transparent;
  }
  button {
    &:hover {
      color: $theme-color;
      background: transparent;
    }
  }
}
.portfolio-item.rs-hero {
  &:before {
    content: none;
  }
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
}
.portfolio-title {
  padding: 100px 60px 30px;
  span {
    display: block;
  }
}
.portfolio-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  color: $white;
  width: 100%;
  border: 0;
  position: relative;
  img {
    width: 100%;
    -webkit-transition: transform 0.35s;
    -o-transition: transform 0.35s;
    -ms-transition: transform 0.35s;
    -moz-transition: transform 0.35s;
    transition: transform 0.35s;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .portfolio-overlay {
    width: 100%;
    background: $theme-color;
    padding: 10px 0px;
    position: absolute;
    bottom: -80px;
    display: block;
    margin-top: 0;
    float: left;
    z-index: 9;
    text-align: left;
    padding-left: 30px;
    left: 0;
    /*    @include transition (all, 0.5s, ease);*/
    h4 {
      $fw-6-weight: 600;
      margin-bottom: 5px;
    }
    a {
      &:hover {
        color: $black;
      }
    }
    span {
      font-weight: $fw-4;
      font-size: $fs-14;
      a {
        color: $white;
        &:hover {
          color: $black;
        }
      }
    }
    a.tag {
      background: $theme-color;
      color: $white;
      padding: 2px 10px;
      border-radius: 3px;
      display: inline-block;
      &:hover {
        background: $black;
        color: $white;
      }
    }
  }
  a.popup {
    position: absolute;
    right: 20px;
    top: -20px;
    color: $white;
    z-index: 9;
    display: inline-block;
    width: 40px;
    height: 40px;
    background: $white;
    color: $text-dark-gray;
    border-radius: 50%;
    margin-right: 10px;
    padding-left: 0px;
    text-align: center;
    line-height: $l-height-40;
    opacity: 0;
    /*    @include transition (all, 0.5s, ease);*/
    &:hover {
      background: $theme-color;
      color: $white;
    }
  }
  &:hover {
    img {
      -webkit-transform: scale(1.20);
      -moz-transform: scale(1.20);
      -ms-transform: scale(1.20);
      -o-transform: scale(1.20);
      transform: scale(1.20);
    }
    a.popup {
      opacity: 1;
      top: 20px;
    }
    .portfolio-overlay {
      bottom: 0;
    }
  }
  &:before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: 115%;
    -webkit-transition: all 0.7s cubic-bezier(.68,.11,.13,.98);
    -moz-transition: all 0.7s cubic-bezier(.68,.11,.13,.98);
    transition: all 0.7s cubic-bezier(.68,.11,.13,.98);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.owl-carousel {
  .owl-item {
    .portfolio-item {
      img {
        width: 100%;
      }
    }
  }
  img {
    width: 100%;
  }
}
.portfolio-item.only-popup {
  width: 100%;
  text-align: center;
  a.popup {
    display: inline-block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
}
.no-title {
  .portfolio-item {
    .portfolio-overlay {
      position: inherit;
      bottom: inherit;
      background: $gray-bg-02;
      a {
        color: $black;
        &:hover {
          color: $theme-color;
        }
      }
      span {
        color: $black;
      }
    }
  }
  .portfolio-item-2 {
    overflow: visible;
    .portfolio-hover {
      position: inherit;
      border: inherit;
      opacity: 1;
      background: $gray-bg-02;
      clear: both;
      .hover-name {
        bottom: inherit;
        position: inherit;
        display: inline-block;
        clear: both;
        span {
          color: $black;
        }
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
      .hover-icon {
        bottom: inherit;
        position: inherit;
        display: inline-block;
        float: right;
        padding-top: 20px;
        text-align: right;
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
    &:hover {
      .portfolio-hover {
        bottom: inherit;
        background: inherit;
        background: $gray-bg-02;
        .hover-name {
          bottom: inherit;
        }
        .hover-icon {
          bottom: inherit;
        }
      }
      img {
        opacity: 1;
        -webkit-transform: inherit;
        -moz-transform: inherit;
        -o-transform: inherit;
        transform: inherit;
      }
    }
  }
}
.mfp-bottom-bar {
  display: none;
}
.portfolio-home {
  .isotope {
    margin-bottom: 0;
  }
}
.corporate-02-portfolio {
  .portfolio-item {
    .portfolio-overlay {
      a {
        &:hover {
          color: rgba(255,255,255,.7);
        }
      }
      span {
        a {
          &:hover {
            color: rgba(255,255,255,.7);
          }
        }
      }
    }
  }
}
.portfolio-item-2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  &:before {
    background: transparent;
  }
  .portfolio-hover {
    padding: 20px;
    position: absolute;
    z-index: 99;
    bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    /*    @include transition (all, 0.5s, ease);*/
    .hover-name {
      position: absolute;
      bottom: -10px;
      /*      @include transition (all, 0.5s, ease);*/
      span {
        font-size: $fs-14;
        color: $white;
        display: block;
      }
      a {
        font-size: $fs-22;
        text-transform: uppercase;
        color: $white;
        display: block;
        margin-top: 5px;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .hover-icon {
      position: absolute;
      bottom: -10px;
      right: 30px;
      /*      @include transition (all, 0.8s, ease);*/
      a {
        color: $white;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
  &:hover {
    .portfolio-hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.9);
      .hover-name {
        bottom: 30px;
      }
      .hover-icon {
        bottom: 40px;
      }
    }
    img {
      opacity: 0.1;
      -webkit-transform: scale(1.10);
      -moz-transform: scale(1.10);
      -ms-transform: scale(1.10);
      -o-transform: scale(1.10);
      transform: scale(1.10);
    }
  }
  img {
    opacity: 1;
    /*    @include transition (all, 0.1s, ease);*/
  }
}
.tags {
  h5 {
    display: inline-block;
    padding-right: 20px;
  }
  ul {
    display: inline-block;
  }
  li {
    display: inline-block;
    a {
      background: $gray-bg-02;
      color: $black;
      border-radius: 3px;
      padding: 8px 16px;
      line-height: $l-height-40;
      font-size: $fs-14;
      margin-right: 2px;
      &:hover {
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
        background: $white;
      }
    }
  }
}
.share {
  .social {
    ul {
      display: inline-block;
      li {
        display: inline-block;
        padding: 0px 6px;
        a {
          &:hover {
            color: #353535;
          }
        }
      }
    }
  }
}
.port-meta {
  ul {
    margin-bottom: 30px;
  }
  li {
    margin-bottom: 20px;
    font-size: $fs-16;
    b {
      color: $theme-color;
      width: 200px;
      font-weight: $fw-4;
      width: 100px;
      display: inline-block;
    }
    span {
      font-weight: normal;
    }
  }
}
.port-info {
  p {
    margin-bottom: 0px;
  }
}
.port-navigation {
  padding: 20px 0;
  border-bottom: 1px dashed #d0d0d0;
  border-top: 1px dashed #d0d0d0;
  a {
    color: $text-dark-gray;
  }
}
.port-arrow {
  height: 74px;
  width: 40px;
  text-align: center;
  background: $gray-bg;
  display: inline-block;
  i {
    text-align: center;
    font-size: 25px;
    line-height: 75px;
  }
}
.port-photo {
  width: 124px;
  height: 77px;
  display: inline-block;
  img {
    width: 100%;
  }
}
.port-post {
  background: $gray-bg;
  padding: 15px;
}
.port-post-photo {
  width: 170px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  img {
    width: 100%;
  }
}
.port-post-info {
  padding-left: 190px;
  h3 {
    display: inline-block;
    font-size: 24px;
  }
  span {
    color: $text-dark-gray;
  }
  p {
    margin-top: 10px;
  }
}
.port-post-social {
  strong {
    font-size: $fs-14;
  }
  a {
    color: $text-dark-gray;
    margin-left: 8px;
    font-size: $fs-14;
    &:hover {
      color: $theme-color;
    }
  }
}
.port-singal {
  .owl-carousel {
    .owl-item {
      img {
        width: 100%;
      }
    }
  }
}
.blog-box {
  padding: 30px;
  border: 1px solid #ddd;
  position: relative;
  z-index: 1;
  .post-format-icon {
    text-align: right;
    position: relative;
    z-index: 9;
    i {
      padding-right: 0;
      font-size: 35px;
      color: #e1e1e1;
    }
  }
  .post-category {
    a {
      display: inline-block;
      padding: 5px 15px;
      font-size: 12px;
      font-weight: $fw-5;
      border-radius: 3px;
      color: $white;
      background-color: $theme-color;
      /*      @include transition (all, 0.3s, ease);*/
      margin-bottom: 20px;
    }
  }
  .border {
    position: absolute;
    background: $theme-color;
    height: 0px;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    /*    @include transition (all, 0.3s, ease);*/
  }
  .blog-box-img {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    /*    @include transition (all, 0.3s, ease);*/
    background-size: cover;
    background-position: center center;
  }
  &:hover {
    .blog-box-img {
      opacity: 1;
      /*      @include transition (all, 0.3s, ease);*/
      &:before {
        background: rgba(0, 0, 0, 0.6);
        color: $white;
        content: "";
        display: inline-block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }
}
.blog-info {
  position: relative;
  z-index: 9;
  span {
    color: $text-dark-gray;
    font-size: $fs-14;
    margin-right: 6px;
  }
  h4 {
    font-weight: $fw-6;
    font-size: $fs-18;
  }
  p {
    padding: 0px 0 10px;
    font-size: $fs-14;
  }
}
a.blog-btn {
  text-align: center;
  font-size: $fs-14;
  color: $theme-color;
  display: block;
  padding-bottom: 12px;
  position: relative;
  z-index: 1;
  i {
    margin-left: 10px;
    /*    @include transition (all, 0.3s, ease);*/
  }
}
.blog-box.active {
  .blog-box-img {
    opacity: 1;
    /*    @include transition (all, 0.3s, ease);*/
    &:before {
      background: rgba(0, 0, 0, 0.6);
      color: $white;
      content: "";
      display: inline-block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
.blog-1 {
  &:hover {
    h4 {
      color: $white;
    }
    i {
      color: $white;
    }
    span {
      color: $white;
    }
    p {
      color: $white;
    }
    a.blog-btn {
      color: $white;
    }
    .post-category {
      a {
        color: $white;
      }
    }
    .post-format-icon {
      i {
        color: $white;
      }
    }
  }
}
.blog-1.active {
  h4 {
    color: $white;
  }
  i {
    color: $white;
  }
  span {
    color: $white;
  }
  p {
    color: $white;
  }
  a.blog-btn {
    color: $white;
  }
  .post-category {
    a {
      color: $white;
    }
  }
  .post-format-icon {
    i {
      color: $white;
    }
  }
}
.blog-box.blog-2 {
  padding: 0;
  position: relative;
  border: none;
  /*  @include transition (all, 0.5s, ease);*/
  height: 100%;
  .blog-info {
    background: $white;
    padding: 30px;
  }
  .blog-box-img {
    opacity: 1;
  }
  .post-format-icon {
    position: absolute;
    right: 30px;
    top: 30px;
  }
  .button.icon-color {
    text-transform: capitalize;
    float: right;
  }
  &:hover {
    /*    @include box-shadow(0px, 0px, 30px, rgba(0, 0, 0, 0.07));*/
  }
}
.blog-box.blog-2.gray-bg {
  .blog-info {
    background: $gray-bg;
  }
  &:hover {
    box-shadow: none;
  }
}
.blog-box.blog-2.transparent {
  .blog-info {
    background: transparent;
    padding: 30px 0;
    span {
      color: $white;
    }
  }
}
.blog-entry {
  /*  @include box-shadow(0px, 0px, 10px, rgba(0, 0, 0, 0.06));
    @include transition (all, 0.5s, ease);*/
  &:hover {
    /*    @include box-shadow(0px, 0px, 30px, rgba(0, 0, 0, 0.1));*/
  }
  .entry-image {
    width: 100%;
    max-width: 415px;
    height: 190px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .blog-detail {
    background: $white;
    padding: 30px;
    border-top: 0px;
    .entry-title {
      a {
        font-size: $fs-22;
        font-weight: $fw-5;
        line-height: $l-height-24;
        color: #353535;
        line-height: $l-height-26;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
  .entry-meta {
    ul {
      li {
        display: inline-block;
        margin-right: 12px;
        i {
          color: $theme-color;
          padding-right: 6px;
        }
        a {
          color: #353535;
          line-height: 0px;
          padding-right: 5px;
          i {
            padding-right: 6px;
            color: $theme-color;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .social {
    strong {
      display: inline-block;
      margin-right: 10px;
    }
    ul {
      display: inline-block;
      li {
        display: inline-block;
        padding: 0px 6px;
        a {
          &:hover {
            color: #353535;
          }
        }
      }
    }
  }
  .entry-button {
    display: inline-block;
  }
  .entry-share {
    margin-top: 20px;
    display: block;
  }
  .grid-post {
    li {
      float: left;
      width: 50%;
      border-right: 4px solid $white;
      border-bottom: 4px solid $white;
      list-style: none;
      img {
        width: 100%;
      }
      .portfolio-item {
        width: 100%;
      }
    }
  }
  .blog-entry-audio {
    padding: 136px 20px;
    background: url(../images/blog/04.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
  }
}
.blog-entry.blockquote {
  background: $theme-color;
  border: 0px;
  padding: 36px 30px;
  blockquote {
    background: $theme-color;
    border-left: 0px;
    color: $white;
    padding: 0px;
    position: relative;
    margin-top: 0 !important;
    padding-top: 60px;
    &:before {
      content: "\201C";
      font-family: Georgia, serif;
      font-size: 100px;
      font-weight: bold;
      color: $white;
      position: absolute;
      left: 0px;
      top: -20px;
    }
  }
  .blog-detail {
    border: 0px;
    background: $theme-color;
    padding: 0px;
    .entry-title {
      a {
        color: $white;
        &:hover {
          color: $black;
        }
      }
    }
  }
  .social {
    ul {
      li {
        a {
          color: $white;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  .entry-meta {
    ul {
      li {
        a {
          color: $white;
          i {
            color: $white !important;
          }
          &:hover {
            color: $black;
          }
        }
        i {
          color: $white;
        }
      }
    }
  }
  .button.arrow.white {
    &:hover {
      color: $black;
    }
  }
}
.js-video {
  height: 0;
  padding-top: 25px;
  padding-bottom: 54%;
  position: relative;
  overflow: hidden;
  embed {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
  }
  iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
  }
  object {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
  }
  video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: none;
  }
}
.js-video.widescreen {
  padding-bottom: 34.34%;
}
.js-video.big {
  padding-top: 30px;
}
.blog-grid-3-column {
  .blog-entry {
    .blog-entry-audio {
      padding: 63px 20px;
    }
  }
}
.blog-grid-2-sidebar {
  .blog-entry {
    .blog-entry-audio {
      padding: 96px 20px;
    }
  }
}
.service-image-holder {
  position: relative;
  height: 496px;
  .section-title-2 {
    display: inline-block;
    left: 0;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 100%;
  }
}
.services-text-box {
  background: $white;
  padding: 10% 15%;
}
.services-text-box-black {
  background: $black;
  padding: 80px 50px !important;
}
.services-text-box-green {
  background: $theme-color;
  padding: 80px 50px !important;
}
.creativity {
  h1 {
    line-height: 65px;
    text-transform: uppercase;
    font-size: $fs-50;
    font-weight: bold;
  }
  p {
    font-size: $fs-30;
  }
  .button {
    display: inline-block;
    float: none;
  }
}
.contact-map {
  iframe {
    width: 100%;
    height: 100%;
  }
}
#formmessage-accept, #formmessage-error-name, #formmessage-error-email, #formmessage-error-phone, #formmessage-error-comment,
.formmessage-accept, .formmessage-error-name, .formmessage-error-email, .formmessage-error-phone, .formmessage-error-comment {
  display: none;
}
.contact-form {
  .section-field {
    position: relative;
    width: 31.3%;
    margin-right: 3%;
    float: left;
    &:nth-child(3) {
      margin-right: 0px;
    }
    &:nth-child(6) {
      margin-right: 0px;
    }
  }
  .form-control {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }
  textarea.form-control {
    width: 100%;
  }
}
.contact-box.contact-box-top {
  margin-top: -1px;
  position: relative;
}
.touch-in {
  .contact-box {
    /*    @include box-shadow(0px, 0px, 30px, rgba(0, 0, 0, 0.09));*/
    display: inline-block;
    margin-bottom: 0;
    padding: 60px 20px;
    width: 100%;
  }
}
.contact-box {
  i {
    background: $theme-color;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: $l-height-80;
    border-radius: 5px;
    font-size: 34px;
    color: $white;
    display: inline-block;
  }
  .contact-icon {
    display: table-cell;
    padding-right: 20px;
    i {
      background: transparent;
      line-height: normal;
      font-size: 34px;
      height: auto;
      width: auto;
    }
  }
  .contact-info {
    display: table-cell;
    vertical-align: top;
    padding: 0;
    background: none;
  }
}
.section-field.textarea {
  width: 100%;
}
.g-recaptcha.section-field {
  width: 100%;
  margin-bottom: 20px;
}
.contact-box.theme-bg {
  .contact-icon {
    i {
      color: $white !important;
    }
  }
}
.contact-2 {
  .addresss-info {
    p {
      color: $white;
    }
    i {
      color: $white;
    }
    li {
      color: $white;
    }
  }
  .contact-add {
    padding: 80px 0;
    i {
      font-size: $fs-40;
    }
  }
  .g-map {
    padding-top: 100%;
  }
}
.contact-3 {
  .g-map {
    padding-top: 101%;
  }
  .contact-add {
    padding: 80px 15px;
    i {
      font-size: $fs-40;
      color: $theme-color;
    }
  }
  .contact-form {
    .section-field {
      width: 100%;
    }
  }
  .theme-bg.contact-add {
    i {
      color: $white !important;
    }
  }
}
.map-side {
  left: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 9;
}
.map-side.map-right {
  left: auto;
  right: 0;
}
.contact-social {
  ul {
    li {
      display: inline-block;
      a {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: $black;
        background: #eceff8;
        font-size: $fs-14;
        border-radius: 3px;
        margin-right: 5px;
        &:hover {
          background: #353535 !important;
          color: $white;
        }
      }
    }
  }
}
.footer-contact {
  .footer-nav {
    ul {
      li {
        a {
          color: #353535;
          &:hover {
            color: #2046f2;
          }
        }
      }
    }
  }
  .contact-box {
    .contact-icon {
      i {
        background: transparent;
        color: $theme-color;
        margin-bottom: 10px;
      }
      display: block;
      padding-right: 0;
      color: #2046f2;
    }
    text-align: center;
    .contact-info {
      display: block;
      background: none;
      padding: 0;
      width: 100%;
      .section-title {
        margin-bottom: 0px;
      }
      h5 {
        color: #353535;
      }
    }
  }
  .social {
    ul {
      background: $gray-bg-02;
      li {
        a {
          i {
            color: #353535;
            &:hover {
              color: #2046f2;
            }
          }
        }
      }
    }
  }
  .usefull-link {
    ul {
      li {
        a {
          color: #353535;
          &:hover {
            color: #2046f2;
          }
        }
      }
    }
  }
  /* @include box-shadow(0px, 15px, 30px, rgba(0, 0, 0, 0.1));*/
  margin-bottom: 80px;
  margin-top: -220px;
  padding: 60px 0;
}
.footer.footer-topbar {
  hr {
    border-color: #d9d9d9;
  }
  img#logo-footer {
    height: 34px;
    margin-bottom: 10px;
  }
  .copyright {
    ul {
      li {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}
.our-clients.theme-bg {
  padding: 60px 0 170px;
}
.footer-Newsletter {
  .form-control {
    &::-moz-placeholder {
      color: rgba(255,255,255,0.5);
    }
    &::-ms-input-placeholder {
      color: rgba(255,255,255,0.5);
    }
    &::-webkit-input-placeholder {
      color: rgba(255,255,255,0.5);
    }
    border-left: 0px;
    height: 50px;
    border-radius: 3px;
    font-size: $fs-14;
    color: rgba(255,255,255,0.3);
    border: none;
    background: rgba(255, 255, 255, 0.08);
    padding: 10px 25px;
    width: 100%;
    /*    @include transition (all, 0.3s, ease);*/
    &:focus {
      border: 0;
      box-shadow: none;
      background: rgba(255, 255, 255, 0.10);
    }
  }
  display: inline-block;
  width: 100%;
  .button.button-border {
    border-color: rgba(255,255,255,0.2);
    color: rgba(255,255,255,0.5);
    font-size: 12px;
    /*    @include transition (all, 0.3s, ease);*/
    &:hover {
      color: $white;
    }
    &:focus {
      border-color: transparent;
    }
  }
}
.history-main {
  width: 100%;
}
.liner {
  background: $white;
  height: 2px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 50%;
  width: 96%;
  z-index: 1;
  position: relative;
  i {
    position: absolute;
    right: -2px;
    top: -9px;
    color: $white;
  }
}
.history-content {
  list-style: outside none none;
  padding: 125px 0;
  width: auto;
}
.history-1 {
  position: relative;
  left: 45px;
}
.history-2 {
  position: relative;
  margin-left: 30%;
}
.history-3 {
  position: relative;
  margin-left: 58%;
}
.history-4 {
  position: relative;
  margin-left: 95%;
}
.history-line {
  background: $white;
  height: 60px;
  left: 3px;
  position: absolute;
  top: -75px;
  width: 2px;
  z-index: 9;
}
.history-time-lin {
  position: relative;
  top: 10px;
  z-index: 2;
  &:before {
    bottom: 0;
    background: $white;
    border-radius: 50%;
    content: "";
    height: 20px;
    left: -5px;
    position: absolute;
    width: 20px;
    z-index: 1;
  }
  &:after {
    border: 2px solid $white;
    background: #000;
    border-radius: 50%;
    content: "";
    height: 30px;
    left: -5px;
    position: absolute;
    width: 30px;
    left: -10px;
    top: -25px;
  }
}
.history-item {
  left: -20px;
  position: absolute;
  top: -125px;
  a {
    border-radius: 50%;
    border: 2px solid $white;
    background: rgba(255, 255, 255, 0.2);
    line-height: 45px;
    font-size: $fs-14;
    color: $white;
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
  }
}
.history-5 {
  position: relative;
  margin-left: 15%;
}
.history-6 {
  position: relative;
  margin-left: 53%;
}
.history-7 {
  position: relative;
  margin-left: 78%;
}
.history-line-2 {
  background: $white none repeat scroll 0 0;
  height: 60px;
  left: 3px;
  position: absolute;
  top: 15px;
  width: 2px;
  z-index: 9;
}
.history-item-2 {
  left: -20px;
  position: absolute;
  top: 75px;
  a {
    border-radius: 50%;
    border: 2px solid $white;
    background: rgba(255, 255, 255, 0.2);
    line-height: 45px;
    font-size: $fs-14;
    color: $white;
    width: 50px;
    height: 50px;
    text-align: center;
    display: block;
  }
}
.our-history {
  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 2px;
      background-color: #e6e6e6;
      left: 50%;
      margin-left: -0.5px;
    }
    >li {
      margin-bottom: 20px;
      position: relative;
      text-align: right;
      &:before {
        content: " ";
        display: table;
        content: " ";
        display: table;
      }
      &:after {
        content: " ";
        display: table;
        clear: both;
        content: " ";
        display: table;
        clear: both;
      }
      >.timeline-panel {
        width: 46%;
        float: left;
        padding: 15px 00px 25px 00px;
        position: relative;
        &:before {
          position: absolute;
          top: 23px;
          right: -25px;
          display: inline-block;
          width: 25px;
          height: 2px;
          background: #e6e6e6;
          content: " ";
        }
      }
      >.timeline-badge {
        color: $white;
        width: 18px;
        height: 18px;
        line-height: 10px;
        font-size: 0.7em;
        text-align: center;
        position: absolute;
        top: 16px;
        left: 50%;
        border: 3px solid $theme-color;
        margin-left: -9px;
        background-color: $white;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        /*        @include box-shadow(0px, 0px, 5px, rgba(255, 255, 255, 1));*/
        p {
          width: 200px;
          margin-top: -9px;
          margin-left: 25px;
          font-size: 17px;
          font-weight: bold;
          text-align: left;
        }
      }
    }
    >li.timeline-inverted {
      >.timeline-panel {
        float: right;
        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -32px;
          right: auto;
        }
        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
      text-align: left;
      .timeline-badge {
        p {
          text-align: right;
          float: right;
          margin-right: 25px;
        }
      }
    }
    .timeline-arrow {
      position: absolute;
      width: 100%;
      left: 0.5px;
      right: 0;
      text-align: center;
      color: #e6e6e6;
      bottom: -29px;
    }
  }
  .timeline-body {
    >p {
      margin-bottom: 0;
      + {
        p {
          margin-top: 5px;
        }
      }
    }
    >ul {
      margin-bottom: 0;
    }
  }
}
.our-service {
  position: relative;
  padding-bottom: 280px;
  z-index: 1;
}
.objects-left {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: -1;
  .objects-1 {
    position: absolute;
    left: 50px;
    top: 0;
  }
  .objects-2 {
    position: absolute;
    left: 100px;
    bottom: 170px;
  }
  .objects-3 {
    position: absolute;
    left: 50%;
    bottom: 150px;
    margin-left: -150px;
  }
}
.objects-right {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 80px;
  z-index: -1;
  .objects-4 {
    position: absolute;
    right: 0px;
    top: 0;
  }
  .objects-5 {
    position: absolute;
    right: 0px;
    bottom: 170px;
  }
  .objects-bottom {
    position: absolute;
    right: 0px;
    bottom: 0;
  }
}
.sidebar-widget {
  p {
    margin-bottom: 0px;
  }
  .recent-post {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
    margin-bottom: 20px;
    .recent-post-image {
      float: left;
      margin-right: 15px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .recent-post-info {
      overflow: hidden;
      a {
        display: block;
        margin-bottom: 5px;
        color: $black;
        &:hover {
          color: $theme-color;
        }
      }
      span {
        color: $theme-color;
        font-style: italic;
        font-size: $fs-14;
        i {
          padding-right: 10px;
        }
      }
    }
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .widget-categories {
    li {
      display: block;
      margin-bottom: 6px;
      a {
        font-size: $fs-14;
        color: $text-dark-gray;
        line-height: 32px;
        display: block;
        &:hover {
          color: $theme-color;
          i {
            border-color: $theme-color;
            color: $theme-color;
          }
        }
      }
      i {
        font-size: $fs-16;
        line-height: 17px;
        padding-left: 2px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        text-align: center;
      }
    }
  }
  .widget-link {
    li {
      display: block;
      margin-bottom: 6px;
      a {
        font-size: $fs-14;
        color: $text-dark-gray;
        line-height: 32px;
        display: block;
        &:hover {
          color: $theme-color;
        }
      }
      i {
        font-size: $fs-16;
        line-height: 17px;
        padding-left: 2px;
        margin-right: 20px;
        width: 20px;
        height: 20px;
        text-align: center;
      }
    }
  }
  .widget-tags {
    li {
      display: inline-block;
      &.active {
        a {
          background: #354a6b;
          color: #fff;
        }
        a:hover {
          cursor: pointer;
          background: #404040;
        }
      }
      a {
        background: #dddddd;
        color: $black;
        border-radius: 3px;
        padding: 8px 16px;
        line-height: $l-height-40;
        font-size: $fs-14;
        margin-right: 2px;
        &:hover {
          /*          @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
          background: #cccccc;
          cursor: pointer;
        }
      }
    }
  }
  .testimonial-widget {
    .testimonial-content {
      background: $gray-bg-02;
      padding: 20px;
      font-style: italic;
      border-radius: 5px;
      position: relative;
      &:before {
        position: absolute;
        content: "";
        bottom: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 7.5px 0 7.5px;
        border-color: $gray-bg-02 transparent transparent transparent;
      }
    }
    .testimonial-info {
      .testimonial-avtar {
        display: table-cell;
        padding-right: 20px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .testimonial-name {
        display: table-cell;
        vertical-align: top;
        span {
          display: block;
          font-style: italic;
          font-size: $fs-14;
        }
      }
      &:before {
        display: none;
      }
    }
  }
  .widget-gallery {
    ul {
      li {
        float: left;
        width: 58px;
        margin: 2px;
        list-style: none;
      }
    }
  }
  .widget-newsletter {
    background: $gray-bg-02;
    padding: 30px;
    position: relative;
    z-index: 9;
    overflow: hidden;
    .newsletter-icon {
      position: absolute;
      right: 0;
      top: -16px;
      i {
        font-size: 100px;
        z-index: 0;
        color: rgba(0, 0, 0, 0.05);
      }
    }
    .form-control {
      background: $white;
      box-shadow: none;
    }
  }
  .widget-clients {
    .item {
      background: $gray-bg-02;
      padding: 30px 30px;
    }
  }
  .recent-item {
    margin-bottom: 20px;
    .recent-image {
      display: table-cell;
      padding-right: 10px;
      width: 50px;
      float: left;
    }
    .recent-info {
      display: table-cell;
      vertical-align: top;
    }
    .recent-title {
      a {
        color: #353535;
        font-weight: bold;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .recent-meta {
      li {
        display: inline-block;
        color: #353535;
      }
    }
  }
  .recent-post.medium {
    .recent-post-image {
      width: 150px;
      img {
        width: inherit;
        height: inherit;
        border-radius: inherit;
      }
    }
  }
}
.widget-search {
  position: relative;
  input {
    padding-right: 30px;
    color: #353535;
    box-shadow: none;
    background: $gray-bg-02;
    &:focus {
      border-color: $theme-color;
    }
  }
  i {
    position: absolute;
    right: 0px;
    padding: 16px 22px;
    font-size: $fs-14;
    color: $text-dark-gray;
    cursor: pointer;
  }
}
.opening-time {
  ul {
    li {
      font-size: $fs-14;
      line-height: 20px;
      color: rgba(255,255,255,0.5);
      border-bottom: 1px solid #262626;
      padding-bottom: 14px;
      margin-bottom: 14px;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.about-me {
  .custom-content {
    margin-top: 0px;
  }
  .maintenance-progress-bar {
    margin: 0;
    .progress-bar {
      margin: 0 44px;
    }
  }
  .portfolio-item {
    width: 100%;
  }
  .contact-me {
    p {
      color: $text-black;
      margin: 15px 0;
      display: table;
    }
    i {
      float: left;
      color: $theme-color;
      margin-right: 15px;
      line-height: 23px;
      width: 20px;
    }
  }
  .follow-me {
    ul {
      margin-top: 20px;
    }
    li {
      display: inline-block;
      a {
        background: #e4e4e4;
        color: $theme-color;
        border-radius: 3px;
        display: block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin-right: 2px;
        text-align: center;
        font-size: $fs-16;
        &:hover {
          background: $theme-color;
          color: $white;
        }
      }
    }
  }
}
.maintenance-progress-bar {
  .progress-bar {
    .rotate {
      background: $theme-color !important;
    }
    .right {
      background: $theme-color !important;
    }
  }
}
.team-details {
  .social {
    display: table;
    float: right;
    li {
      a {
        background: $gray-bg none repeat scroll 0 0;
        border-radius: 3px;
        color: $theme-color;
        display: block;
        font-size: $fs-15;
        height: 35px;
        line-height: 35px;
        margin-left: 0px;
        text-align: center;
        width: 35px;
        &:hover {
          background: $theme-color;
          color: $white;
        }
      }
    }
  }
  .info {
    i {
      color: $gray-bg;
      font-size: 126px;
      left: 0;
      position: absolute;
      z-index: 0;
    }
    p {
      font-size: $fs-16;
      font-style: italic;
      line-height: $l-height-26;
      padding-left: 64px;
      position: relative;
      z-index: 9;
    }
  }
}
.pagination {
  margin: 0px;
  li {
    a {
      font-size: $fs-15;
      font-weight: $fw-5;
      margin: 0 5px;
      color: $text-dark-gray;
      border-radius: 3px;
      &:focus {
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
        background-color: $theme-color;
        color: $white;
      }
      &:hover {
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
        background-color: $theme-color;
        color: $white;
      }
    }
    span {
      &:focus {
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
        background-color: $theme-color;
        color: $white;
      }
      &:hover {
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
        background-color: $theme-color;
        color: $white;
      }
    }
  }
  >li {
    &:first-child {
      >a {
        border-radius: 3px;
      }
      >span {
        border-radius: 3px;
      }
    }
    &:last-child {
      >a {
        border-radius: 3px;
      }
      >span {
        border-radius: 3px;
      }
    }
    >a {
      background: $gray-bg-02;
      border: 0px;
      text-align: center;
    }
    >span {
      background: $gray-bg-02;
      border: 0px;
      text-align: center;
    }
  }
  >.active {
    >a {
      color: $text-dark-gray;
      background-color: $theme-color;
      color: $white;
      /*      @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      &:hover {
        color: $text-dark-gray;
        background-color: $theme-color;
        color: $white;
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      }
      &:focus {
        background-color: $theme-color;
        color: $white;
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      }
    }
    >span {
      background-color: $theme-color;
      color: $white;
      /*      @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      &:focus {
        background-color: $theme-color;
        color: $white;
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      }
      &:hover {
        background-color: $theme-color;
        color: $white;
        /*        @include box-shadow(0px, 3px, 10px, rgba(0, 0, 0, 0.1));*/
      }
    }
  }
}
.dataTables_paginate {
  li.paginate_button.previous {
    a {
      width: inherit;
      height: inherit;
      background: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
    }
  }
  li.paginate_button.next {
    a {
      width: inherit;
      height: inherit;
      background: transparent !important;
      color: inherit !important;
      box-shadow: none !important;
    }
  }
}



.blog {
  .timeline {
    list-style: none;
    padding: 20px 0 20px;
    position: relative;
    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 4px;
      background-color: $gray-bg;
      left: 50%;
      margin-left: -1.5px;
    }
    >li {
      margin-bottom: 20px;
      position: relative;
      width: 46%;
      float: left;
      clear: left;
      &:before {
        content: " ";
        display: table;
        content: " ";
        display: table;
      }
      &:after {
        content: " ";
        display: table;
        clear: both;
        content: " ";
        display: table;
        clear: both;
      }
      >.timeline-panel {
        width: 95%;
        float: left;
        border: 1px solid #d4d4d4;
        position: relative;
        border: 1px solid $gray-bg;
        padding: 20px;
        /*        @include transition (all, 0.3s, ease);*/
        &:before {
          position: absolute;
          top: 26px;
          right: -15px;
          display: inline-block;
          border-top: 15px solid transparent;
          border-left: 15px solid $gray-bg;
          border-right: 0 solid $gray-bg;
          border-bottom: 15px solid transparent;
          content: " ";
          /*          @include transition (all, 0.3s, ease);*/
        }
        &:after {
          position: absolute;
          top: 27px;
          right: -14px;
          display: inline-block;
          border-top: 14px solid transparent;
          border-left: 14px solid $white;
          border-right: 0 solid $white;
          border-bottom: 14px solid transparent;
          content: " ";
        }
      }
      >.timeline-badge {
        color: $white;
        width: 54px;
        height: 54px;
        line-height: $l-height-18;
        padding: 6px;
        font-size: $fs-22;
        border: 4px solid $gray-bg;
        background: $white;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
        position: absolute;
        color: $gray-bg;
        top: 16px;
        right: -73px;
        span {
          font-size: $fs-13;
          font-weight: normal;
          display: block;
        }
      }
      &:nth-child(2) {
        margin-top: 60px;
      }
      &:nth-child(3) {
        margin-top: 60px;
      }
    }
    >li.timeline-inverted {
      >.timeline-panel {
        float: right;
        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }
        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
      float: right;
      clear: right;
      margin-top: 30px;
      margin-bottom: 0px;
      >.timeline-badge {
        left: -73px;
      }
    }
    .entry-title {
      i {
        font-size: 24px;
      }
    }
    li.timeline-inverted {
      .timeline-panel {
        &:after {
          top: 98px;
        }
        &:before {
          top: 97px;
        }
      }
    }
    li.entry-date {
      top: 0;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 100%;
      span {
        background: $gray-bg;
        color: $text-black;
        font-size: $fs-16;
        font-weight: $fw-4;
        text-transform: capitalize;
        padding: 10px 25px;
        border-radius: 30px;
      }
    }
    li.entry-date-bottom {
      bottom: -30px;
      left: 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 100%;
      a {
        background: $gray-bg;
        padding: 7px 10px;
        color: $text-black;
        font-size: $fs-16;
        padding: 10px 25px;
        border-radius: 30px;
        &:hover {
          background: $theme-color;
          color: $white;
        }
      }
    }
    li {
      &:hover {
        .timeline-panel {
          border-color: $theme-color;
          &:before {
            border-left-color: $theme-color;
            border-right-color: $theme-color;
          }
        }
        .timeline-badge {
          a {
            color: $white;
          }
          background: $theme-color;
          border-color: $theme-color;
        }
      }
    }
  }
  .timeline-title {
    margin-top: 0;
    color: inherit;
  }
  .timeline-body {
    >p {
      padding: 20px;
      margin-bottom: 0;
      + {
        p {
          margin-top: 5px;
        }
      }
    }
    >ul {
      padding: 20px;
      margin-bottom: 0;
    }
  }
  .timeline-footer {
    padding: 20px;
    background-color: #f4f4f4;
    >a {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .timeline-inverted {
    .timeline-badge {
      margin-top: 70px;
    }
  }
  .blog.timeline {
    .entry-quote {
      p {
        font-size: $fs-16;
      }
    }
  }
}
.blog {
  .timeline {
    li {

      &:nth-child(2) {
        margin-top: 60px;
      }
      &:nth-child(3) {
        margin-top: 60px;
      }
    }
  }
}
.blog.timeline-sidebar {
  .timeline {
    >li {
      width: 89%;
      &:nth-child(3) {
        margin-top: 30px;
      }
    }
    li {
      .blog-entry-grid {
        .grid-post {
          li {
            width: 50%;
          }
        }
      }
      .share {
        ul {
          li {
            width: auto;
          }
        }
      }
    }
    &:before {
      left: 54px;
    }
    li.entry-date {
      text-align: left;
      top: 3px;
      span {
        font-size: $fs-14;
        padding: 10px 25px;
      }
    }
    li.entry-date-bottom {
      text-align: left;
      bottom: -48px;
      a {
        font-size: $fs-14;
        padding: 10px 25px;
      }
    }
    li.timeline-inverted {
      .timeline-badge {
        left: -66px;
      }
    }
    >li.timeline-inverted {
      margin-bottom: 0px;
    }
  }
}
.blog-single {
  .blog-entry {
    box-shadow: none;
    .blog-detail {
      padding: 0;
      margin-top: 20px;
    }
  }
  .blockquote {
    font-style: italic;
    line-height: 30px;
    background: $gray-bg-02 !important;
  }
  .blog-entry.blockquote {
    blockquote {
      background: $gray-bg-02;
      color: $text-dark-gray;
      cite {
        color: $theme-color;
      }
      &:before {
        color: $black;
        top: 20px;
      }
    }
  }
  .entry-share {
    .tags {
      display: inline-block;
    }
  }
}
.page-item.active {
  .page-link {
    background: $theme-color;
    border-color: $theme-color;
  }
}
.comments-1 {
  .comments-photo {
    width: 106px;
    height: auto;
    float: left;
    border: 5px solid $gray-bg;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .comments-info {
    display: table;
    background: $gray-bg;
    padding: 20px;
    margin-bottom: 20px;
    h4 {
      display: inline-block;
    }
    span {
      font-size: $fs-13;
      color: $text-dark-gray;
    }
    a {
      color: $theme-color;
      &:hover {
        color: $text-black;
      }
    }
  }
}
.comments-1.comments-2 {
  padding-left: 127px;
  .comments-info {
    background: $white;
    margin-bottom: 0px;
    padding: 20px 20px 10px;
  }
}
.error-block {
  position: relative;
  z-index: 9;
  padding: 20px 0 130px 0px;
  .error-text {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    z-index: -1;
    h2, h3{
      line-height: 300px;
      color: rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 0;
      top: 0;
    }
    h2{
      font-size: 505px;
    }
    h3{
      font-size: 380px;
    }
    span {
      display: inline-block;
      position: absolute;
      right: -100px;
      top: 135px;
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      font-size: 124px;
      line-height: $l-height-90;
      color: rgba(0, 0, 0, 0.1);
      font-weight: bold;
    }
  }
  h1 {
    font-size: 87px;
    padding-top: 110px;
  }
  p {
    font-size: 33px;
  }
}
.error-text-02 {
  position: relative;
  text-align: center;
  h2 {
    font-size: 420px;
    line-height: 300px;
    color: rgba(255, 255, 255, 0.2);
    position: relative;
    left: -70px;
    top: 0;
  }
  span {
    display: inline-block;
    position: absolute;
    right: -50px;
    top: 109px;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    font-size: 117px;
    line-height: 100px;
    color: rgba(255, 255, 255, 0.2);
    font-weight: bold;
  }
}
.error-middle {
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  width: 100%;
}
.coming-soon {
  background: transparent;
  .coming-soon-form {
    input {
      color: $text-dark-gray;
    }
  }
}
.coming-soon-countdown {
  ul.countdown {
    list-style: none;
    margin: 75px 0;
    padding: 0;
    display: block;
    text-align: center;
    li {
      display: inline-block;
      width: 24%;
      span {
        font-size: $fs-90;
        font-weight: bold;
        line-height: $l-height-80;
        color: $theme-color;
      }
      p {
        color: $text-dark-gray;
        font-size: $fs-20;
        text-transform: capitalize;
        margin-bottom: 30px;
      }
    }
  }
}
.coming-soon-form {
  input {
    width: 50%;
    color: $white;
    display: block;
    margin: 0 auto;
  }
}
.coming-soon-form.contact-form {
  input {
    width: 50%;
    display: block;
    margin: 0 auto;
  }
}
.coming-soon-middle {
  padding: 100px;
  display: inline-block;
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  width: 100%;
}
.register-form {
  .section-field {
    display: block;
  }
  .field-widget {
    position: relative;
    input {
      width: 100%;
      margin-bottom: 20px;
    }
    select {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 15px;
    }
    input.gui-input {
      padding-left: 140px;
    }
    .button {
      background: $dark-gray-bg;
      height: 46px;
      left: 0;
      margin: 2px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 115px;
      border: 0;
      line-height: 26px;
    }
  }
  .nice-select {
    float: none;
    margin-bottom: 20px;
    background: $gray-bg-02;
    border: 0;
  }
}
.remember-checkbox {
  a {
    color: $theme-color;
  }
  label {
    position: relative;
    padding-left: 30px;
    font-size: $fs-14;
    cursor: pointer;
    &:before {
      font-family: FontAwesome;
      font-size: 17px;
      position: absolute;
      top: -3px;
      left: 0;
      padding-left: 2px;
      border-radius: 3px;
      border: 2px solid rgba(0, 0, 0, 0.1);
      content: "";
      height: 20px;
      margin-top: 3px;
      width: 20px;
      color: $theme-color;
    }
    &:after {
      content: '\f00c';
      max-width: 0;
      overflow: hidden;
      opacity: 0.5;
      /*      @include transition (all, 0.35s, ease);*/
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      + {
        label {
          &:after {
            max-width: 25px;
            opacity: 1;
          }
        }
      }
    }
  }
  after {
    &:before {
      color: $theme-color;
    }
  }
}
label {
  &:after {
    font-family: FontAwesome;
    font-size: 17px;
    position: absolute;
    top: -3px;
    left: 0;
    padding-left: 2px;
    border-radius: 3px;
  }
}
.full-height-bg {
  height: 100vh;
}
.login-social {
  li {
    display: inline-block;
    margin: 2px 0px;
    a {
      /*      @include transition (all, 0.5s, ease);*/
    }
    a.fb {
      display: block;
      padding: 12px 20px;
      color: $white;
      font-size: $fs-14;
      background: #4c70ad;
      border-radius: 3px;
      &:hover {
        background: $theme-color;
      }
    }
    a.twitter {
      display: block;
      padding: 12px 20px;
      color: $white;
      font-size: $fs-14;
      background: #25b6e6;
      border-radius: 3px;
      &:hover {
        background: $theme-color;
      }
    }
    a.pinterest {
      display: block;
      padding: 12px 20px;
      color: $white;
      font-size: $fs-14;
      background: #dd4b39;
      border-radius: 3px;
      &:hover {
        background: $theme-color;
      }
    }
    i {
      border-right: 1px solid $white;
      padding-right: 10px;
      margin-right: 10px;
    }
  }
}
.section-transparent {
  background: transparent;
}
.login-bg {
  background: $white;
  .login-title {
    background: $theme-color;
    padding: 30px;
  }
  .login-form {
    padding: 40px 30px;
  }
  .login-social {
    padding: 30px;
    background: $gray-bg-02;
  }
}
.register-bg {
  background: $white;
  .register-title {
    background: $theme-color;
    padding: 30px;
  }
  .register-form {
    padding: 30px;
  }
}
img.logo-small {
  height: 40px;
  margin: 30px 0;
  position: relative;
}
.maintenance-main {
  i {
    font-size: 100px;
    margin-bottom: 10px;
    color: #e4e4e4;
  }
  h1 {
    font-size: 62px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: $fs-40;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-size: $fs-26;
    line-height: 35px;
    margin-bottom: 70px;
  }
}
.maintenance-form {
  width: 76%;
  margin: 0 auto;
  p {
    font-size: 19px;
  }
  input {
    width: 50%;
    margin: 0 auto;
  }
  #mc_embed_signup_scroll {
    p {
      margin-bottom: 20px !important;
    }
  }
}
.maintenance-contant {
  p {
    line-height: 25px;
    margin-top: 20px;
    font-size: $fs-14;
  }
}
.progress-new {
  margin-top: 50px;
  height: 50px;
  background: $gray-bg-02;
  border-radius: 0;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.progress-bar-new {
  line-height: $l-height-50;
  background-color: $theme-color;
  color: $white;
  font-size: $fs-20;
  padding-left: 20px;
  span {
    position: absolute;
    right: 30px;
    color: $black;
    font-weight: bold;
    font-size: $fs-20;
  }
}
.clients-box {
  background: $gray-bg;
  padding: 20px;
}
.clients-photo {
  position: relative;
  margin-right: 20px;
  width: 200px;
  height: 200px;
  float: left;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background: $white;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.clients-info {
  display: table;
  i {
    padding-right: 10px;
  }
  a {
    font-size: $fs-14;
    color: $theme-color;
    &:hover {
      color: $text-dark-gray;
    }
  }
  p {
    margin-top: 10px;
  }
}
.happy-clients {
  .tab-content {
    padding: 0 30px;
    position: relative;
    text-align: left;
    .quoter-icon {
      position: absolute;
      left: 0;
      top: -10px;
      font-size: 120px;
      line-height: 1;
      color: $theme-color;
    }
    p {
      padding-left: 30px;
      font-size: $fs-18;
      padding-top: 10px;
      font-style: italic;
    }
  }
  .nav-tabs {
    li {
      overflow: visible;
      display: inline-block;
      padding: 0 5px;
      img {
        width: 96px;
        opacity: 0.1;
        height: 96px;
        border-radius: 50%;
      }
      a.active {
        img {
          border: 4px solid $white;
          opacity: 1;
          top: -2px;
          /*          @include box-shadow(0px, 0px, 40px, rgba(0, 0, 0, 0.3));*/
        }
      }
      a {
        &:focus {
          img {
            border: 4px solid $white;
            opacity: 1;
            top: -2px;
            /*            @include box-shadow(0px, 0px, 40px, rgba(0, 0, 0, 0.3));*/
          }
        }
      }
    }
    border: 0;
  }
  .nav.nav-tabs {
    li {
      a {
        background: none;
        padding: 0;
        border: 0;
        outline: none;
        background: transparent !important;
        &:hover {
          background: none;
          padding: 0;
          border: 0;
          outline: none;
          background: transparent !important;
        }
        &:focus {
          background: none;
          padding: 0;
          border: 0;
          outline: none;
          background: transparent !important;
        }
      }
    }
  }
  .testimonial-avatar {
    position: relative;
    right: inherit;
    left: inherit;
    top: inherit;
    width: inherit;
    height: inherit;
    margin-left: inherit;
    margin-top: 30px;
  }
}
.process-list {
  .col-sm-12 {
    padding: 0 50px;
  }
}
.process {
  margin: 0px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  position: relative;
  width: 100%;
  float: left;
  padding-bottom: 50px;
  padding-top: 50px;
  min-height: inherit;
  .border-area.left-bottom {
    position: absolute;
    bottom: -2px;
    background: $white;
    height: 110px;
    width: 110px;
    left: -2px;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0;
      top: 0;
      border-bottom-left-radius: 70px;
      border-left: 2px dashed rgba(0, 0, 0, 0.3);
      border-bottom: 2px dashed rgba(0, 0, 0, 0.3);
    }
  }
  .border-area.right-top {
    position: absolute;
    top: -2px;
    background: $white;
    height: 110px;
    width: 110px;
    right: -2px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0;
      top: 0;
      border-top-right-radius: 70px;
      border-right: 2px dashed rgba(0, 0, 0, 0.3);
      border-top: 2px dashed rgba(0, 0, 0, 0.3);
    }
  }
  .border-area.right-bottom {
    position: absolute;
    bottom: -2px;
    background: $white;
    height: 110px;
    width: 110px;
    right: -2px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0;
      top: 0;
      border-bottom-right-radius: 70px;
      border-right: 2px dashed rgba(0, 0, 0, 0.3);
      border-bottom: 2px dashed rgba(0, 0, 0, 0.3);
    }
  }
  .border-area.left-top {
    position: absolute;
    top: -2px;
    background: $white;
    height: 110px;
    width: 110px;
    left: -2px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0;
      top: 0;
      border-top-left-radius: 70px;
      border-left: 2px dashed rgba(0, 0, 0, 0.3);
      border-top: 2px dashed rgba(0, 0, 0, 0.3);
    }
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0;
    .border-area.left-bottom {
      bottom: 0;
      &:before {
        border-bottom-left-radius: 0;
        border-bottom: 0;
      }
    }
  }
  .process-step {
    strong {
      width: 70px;
      height: 70px;
      line-height: $l-height-70;
      text-align: center;
      background: $theme-color;
      border-radius: 100%;
      display: inline-block;
      z-index: 99;
      font-size: $fs-20;
      color: $white;
    }
  }
  .process-content {
    position: relative;
    z-index: 99;
    span {
      font-size: $fs-80;
      line-height: $l-height-80;
      color: $theme-color;
    }
  }
}
.process.left {
  border-right: 0;
  border-top: 0;
  &:first-child {
    .process-step {
      top: 0;
    }
  }
  .process-step {
    position: absolute;
    top: 50px;
    left: -35px;
    z-index: 998;
  }
  .process-content {
    padding-left: 80px;
    .process-icon {
      display: table-cell;
      padding-right: 30px;
    }
    .process-info {
      display: block;
      vertical-align: top;
    }
  }
}
.process.right {
  border-left: 0;
  border-top: 0;
  .process-step {
    position: absolute;
    top: 50px;
    right: -35px;
    z-index: 99;
  }
  .process-content {
    padding-right: 80px;
    .process-icon {
      float: right;
      padding-left: 30px;
    }
    .process-info {
      display: block;
      vertical-align: top;
    }
  }
}
.agency-about {
  padding-bottom: 100px;
}
.careers {
  .acd-des {
    span {
      display: block;
    }
  }
}
.careers-from {
  .section-field {
    label {
      margin-bottom: 10px;
    }
  }
}
.sitemap {
  h4 {
    margin-bottom: 25px;
    text-transform: capitalize;
    i {
      padding-right: 10px;
    }
  }
  ul {
    li {
      display: block;
      line-height: 34px;
      i {
        padding-right: 10px;
      }
      a {
        font-size: $fs-14;
        color: $text-dark-gray;
        text-transform: capitalize;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}
.privacy-policy {
  ul {
    li {
      font-size: $fs-14;
      line-height: 30px;
      i {
        padding-right: 10px;
      }
    }
  }
}
.page-sidebar {
  .page-content {
    ul {
      li {
        font-size: $fs-14;
        line-height: 30px;
        i {
          padding-right: 10px;
        }
      }
    }
  }
}
.terms-and-conditions {
  ul {
    li {
      font-size: $fs-14;
      line-height: 30px;
      i {
        padding-right: 10px;
      }
    }
  }
}
.special-feature {
  .feature-text {
    padding: 40px;
  }
  padding-bottom: -117px;
  .row-eq-height.no-gutter {
    position: relative;
    z-index: 99;
    margin-top: -117px;
  }
}
.parallax {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -ms-background-size: cover !important;
  position: relative;
  z-index: 0;
  background-origin: initial;
  background-position: center center !important;
  background-repeat: no-repeat;
}
.gradient-overlay {
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    /*    background: url(../images/construction/bg/gradient-overlay1.png) repeat-y left 0;*/
  }
}
.footer.black-bg {
  background: #1a1a1a;
  p {
    color: rgba(255,255,255,0.5);
  }
  .social-icons {
    li {
      a {
        color: rgba(255,255,255,0.5);
        &:hover {
          color: rgba(255,255,255,1) !important;
        }
      }
    }
  }
}
.footer {
  p {
    font-size: $fs-14;
  }
  h6 {
    font-size: $fs-14;
  }
  a {
    font-size: $fs-14;
  }
  .footer-nav {
    ul {
      li {
        display: inline-block;
        a {
          margin: 0 5px;
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .social {
    ul {
      li {
        display: inline-block;
        a {
          i {
            font-size: $fs-14;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            float: none;
            color: $black;
            /*            @include transition (all, 0.5s, ease);*/
            &:hover {
              color: $theme-color;
            }
          }
        }
      }
      background: $gray-bg-02;
      display: inline-block;
      border-radius: 3px;
      padding: 0px 10px;
    }
  }
  .usefull-link {
    ul {
      li {
        display: block;
        border-bottom: 0px;
        line-height: $l-height-24;
        padding: 5px 0;
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .copyright {
    padding: 30px 0 20px;
    ul {
      li {
        a {
          color: $black;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  .footer-text {
    p {
      color: rgba(255, 255, 255, 0.5) !important;
      padding-top: 10px;
    }
  }
  .footer-social {
    ul {
      li {
        a {
          color: rgba(255, 255, 255, 0.5) !important;
          padding-top: 10px;
          &:hover {
            color: $theme-color !important;
          }
        }
      }
    }
  }
  .footer-gallery {
    ul {
      li {
        float: left;
        width: 58px;
        margin: 2px;
        list-style: none;
      }
    }
  }
  .footer-tags {
    li {
      display: inline-block;
      a {
        background: transparent;
        color: $white;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 3px;
        padding: 8px 16px;
        font-size: $fs-13;
        margin-right: 2px;
        margin-bottom: 5px;
        display: block;
        &:hover {
          background: $theme-color;
        }
      }
    }
  }
  .container-fluid {
    padding: 0 60px;
  }
}
.footer-logo {
  img {
    width: 65%;
  }
}
.addresss-info {
  li {
    font-size: $fs-14;
    margin-bottom: 15px;
    color: rgba(255,255,255,0.5);
    list-style: none;
    a {
      color: rgba(255,255,255,0.5);
    }
  }
  i {
    color: rgba(255,255,255,0.5);
    padding-right: 5px;
    float: left;
    display: table-cell;
    width: 30px;
    line-height: 23px;
    font-size: $fs-14;
  }
  p {
    display: table;
    color: rgba(255,255,255,0.5);
    margin-bottom: 0px;
  }
}
.addresss-info.text-black {
  li {
    color: $text-dark-gray;
    a {
      color: $text-dark-gray;
    }
  }
  p {
    color: $text-dark-gray;
  }
  i {
    color: $theme-color;
    font-size: $fs-20;
    width: 40px;
  }
}

.footer-useful-link {
  ul {
    li {
      list-style: none;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: rgba(255,255,255,0.5);
        font-size: $fs-14;
        vertical-align: top;
        display: block;
        line-height: 26px;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
}
.footer-Newsletter.transparent {
  .form-control {
    background: rgba(0, 0, 0, 0.04);
    &::-moz-placeholder {
      color: $black;
    }
    &::-ms-input-placeholder {
      color: $black;
    }
    &::-webkit-input-placeholder {
      color: $black;
    }
  }
}
.footer-widget {
  padding: 20px 0;
  border-top: 1px solid #262626;
  p {
    font-size: $fs-14;
    color: rgba(255,255,255,0.5);
    letter-spacing: 0.5px;
  }
  a {
    color: $theme-color;
    &:hover {
      color: $theme-color;
    }
  }
}
.footer-widget-social {
  margin-top: 5px;
  li {
    display: inline-block;
  }
  a {
    i {
      display: inline-block;
      color: rgba(255,255,255,0.5);
      background: none;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      text-align: center;
      line-height: 37px;
      font-size: $fs-14;
      /*      @include transition (all, 0.3s, ease);*/
      &:hover {
        background: $theme-color;
      }
    }
  }
}
img#logo-footer {
  height: 40px;
  width: auto;
}
.footer.light {
  .footer-nav {
    ul {
      li {
        a {
          color: $white;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  h6 {
    color: $white;
  }
  .about-content {
    p {
      color: $white;
    }
  }
  .usefull-link {
    ul {
      li {
        a {
          color: $white;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  .copyright {
    ul {
      li {
        color: $white;
        a {
          color: $white;
          font-size: $fs-14;
          &:hover {
            color: $black;
          }
        }
      }
    }
    p {
      color: $white;
    }
  }
  .social {
    ul {
      li {
        a {
          i {
            color: $theme-color;
          }
          &:hover {
            i {
              color: $black;
            }
          }
        }
      }
    }
  }
}
.footer.light.footer-topbar {
  .copyright {
    ul {
      li {
        color: $white;
      }
    }
  }
}
.footer.footer-one-page {
  .contact-add {
    i {
      font-size: $fs-20;
    }
  }
  h5 {
    color: rgba(255,255,255,0.5);
  }
  p {
    color: rgba(255,255,255,0.5);
  }
}
.footer-one-page.white-bg {
  .footer-Newsletter {
    .form-control {
      &:focus {
        background: rgba(0,0,0,0.06);
      }
    }
  }
}
.footer.transparent {
  h6 {
    color: $white;
  }
  p {
    color: $white;
  }
  ul {
    li {
      a {
        color: $white;
      }
    }
  }
  span {
    color: $white;
  }
}
.footer-social-big {
  ul {
    li {
      display: inline-block;
      padding: 10px;
      width: 70px;
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      i {
        color: $white;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        margin: 0 auto;
      }
      span {
        display: block;
        text-align: center;
        color: $white;
        margin-top: 10px;
        font-size: $fs-11;
      }
    }
    li.facebook {
      background: #5d82d1;
    }
    li.pinterest {
      background: #e13138;
    }
    li.linkedin {
      background: #238cc8;
    }
  }
}
.footer-box {
  background: rgba(102, 102, 102, 0.2);
  padding: 30px;
  clear: both;
  margin-bottom: 40px;
  margin-top: 60px;
  .box-content {
    display: table-cell;
    vertical-align: top;
  }
  .box-link {
    display: table-cell;
    padding-left: 20px;
    vertical-align: middle;
    a {
      display: block;
      padding: 8px 16px;
      width: 140px;
    }
  }
}
.google-map {
  .row {
    [class*='col-'] {
      min-height: 0;
    }
  }
  .map-icon {
    &:before {
      cursor: pointer;
      content: "\f041";
      font-family: "FontAwesome";
      font-size: $fs-30;
      font-weight: normal;
      right: 0;
      position: absolute;
      text-indent: 0;
      top: -32px;
      width: 60px;
      height: 60px;
      line-height: $l-height-60;
      border-radius: 50%;
      background: $white;
      color: $theme-color;
      text-align: center;
    }
  }
  .map-open {
    height: 0;
    overflow: hidden;
    transition: all 900ms ease;
    iframe {
      height: 300px;
    }
  }
}
.map-icon {
  position: relative;
  z-index: 999;
  cursor: pointer;
}
.google-map.google-map-open {
  .map-icon {
    &::before {
      content: "\f00d";
    }
  }
}
.google-map-open {
  .map-open {
    height: 300px;
  }
}
.pos-r {
  position: relative;
}
.img-side {
  img {
    max-width: 100%;
  }
  padding: 0;
  position: absolute !important;
  top: 0px;
  height: 100%;
  overflow: hidden;
  z-index: 100;
}
.img-side.img-left {
  left: 0;
}
.font-bold {
  font-weight: $fw-6;
}
.no-gutter {
  margin-right: 0;
  margin-left: 0;
  >[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
.popup-video {
  position: relative;
  .play-video {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 50px;
    height: 50px;
    a {
      width: 50px;
      height: 50px;
      i {
        line-height: $l-height-50;
      }

      &:focus {
        color: $white;
      }
    }
    &:hover {
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      background-color: $white;
      a {
        color: $theme-color;
      }
    }
  }
}
.play-video {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  /*  @include transition (all, 0.5s, ease);*/
  background-color: $theme-color;
  a {
    color: $white;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    left: 50%;
    padding-left: 5px;
    transform: translateX(-50%);
    i {
      line-height: 100px;
    }
  }
  &:hover {
    a {
      color: $theme-color;
    }
    background: $white;
    color: $theme-color;
    border-color: transparent !important;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 2px 6px 18px transparent !important;
    -moz-box-shadow: 2px 6px 18px transparent !important;
    box-shadow: 2px 6px 18px transparent !important;
    &:after {
      -webkit-animation: sonarEffect 1.3s ease-out 75ms;
      -moz-animation: sonarEffect 1.3s ease-out 75ms;
      animation: sonarEffect 1.3s ease-out 75ms;
    }
  }
  &:after {
    content: '';
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    /*    @include box-shadow(0px, 0px, 2px, rgba(255, 255, 255, 0.4));*/
  }
}
.my-contact {
  .contact-icon {
    float: left;
    margin-right: 25px;
    span {
      color: $white ! important;
    }
  }
}
.portfolio-item.simple-effect {
  .portfolio-overlay {
    span {
      color: $text-black;
      a {
        color: $text-black;
        &:hover {
          color: $theme-color;
        }
      }
    }
    a {
      &:hover {
        color: $theme-color;
      }
    }
    z-index: 9;
    opacity: 0;
    bottom: inherit;
    background: transparent;
    top: 60%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    text-align: center;
  }
  &:hover {
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: rgba(255, 255, 255, 1);
      z-index: 1;
      background-size: 115%;
      -webkit-transition: all 0.7s cubic-bezier(.68,.11,.13,.98);
      -moz-transition: all 0.7s cubic-bezier(.68,.11,.13,.98);
      transition: all 0.6s cubic-bezier(.68,.11,.13,.98);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    .portfolio-overlay {
      top: 50%;
      opacity: 1;
    }
  }
}
#footer-fixed {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: -1;
  box-shadow: inset 0 27px 60px -25px rgba(0,0,0,0.3);
}
.footer.footer-simple {
  ul {
    li {
      a {
        color: $white;
        font-weight: bold;
        font-size: $fs-16;
        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
  p {
    color: $white;
    font-weight: bold;
    font-size: $fs-16;
  }
  .addresss-info {
    i {
      color: $white;
      font-weight: bold;
      font-size: $fs-16;
    }
  }
  li {
    color: $white;
    font-weight: bold;
    font-size: $fs-16;
  }
  .social-icons {
    li {
      a {
        color: $white;
        width: 100%;
        &:hover {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
.full-width {
  width: 100%;
}
.height-100vh {
  height: 100vh;
}
.pos-bot {
  position: absolute;
  bottom: 0;
}
.vertical-align {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.portfolio-03-about {
  padding: 0 50px;
  p {
    font-size: $fs-16;
    font-weight: $fw-6;
  }
}
.popup-video-image {
  position: relative;
  &:before {
    background: rgba(37, 41, 51, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
  a {
    i {
      color: $white;
      text-align: center;
      line-height: $l-height-60;
      width: 60px;
      height: 60px;
      background: $theme-color;
      display: inline-block;
      margin: 0 auto;
      border-radius: 50%;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      z-index: 99;
      font-size: $fs-20;
      /*      @include transition (all, 0.5s, ease);*/
      &:hover {
        background: $black;
      }
    }
  }
}
.slider-parallax.typer-banner.business {
  h1 {
    font-family: $font-dosis;
  }
  p {
    padding: 0 200px;
    line-height: 34px;
    font-size: $fs-26;
    font-weight: $fw-6;
    display: block;
  }
}
.custom-content-02 {
  .feature-text {
    p {
      line-height: $l-height-26;
    }
  }
}
.play-video-section {
  .play-video {
    box-shadow: none;
    background: $white;
    width: 60px;
    height: 60px;
    line-height: $l-height-60;
    a {
      color: $theme-color;
      line-height: $l-height-60;
      i {
        line-height: $l-height-60;
        padding-right: 3px;
      }
    }
    &:after {
      box-shadow: none;
    }
  }
  .content {
    padding: 0 100px;
    h2 {
      font-family: $font-dosis;
    }
  }
}
.custom-content-03 {
  h2 {
    font-family: $font-dosis;
    $fs-40-size: 40px;
    line-height: $l-height-50;
  }
}
.play-video-section.black-button {
  .play-video {
    background: $black;
    a {
      color: $white;
    }
    &:hover {
      background: $theme-color;
    }
  }
}
.blockquote-section {
  position: relative;
  z-index: 9;
  blockquote {
    font-size: $fs-50;
    font-weight: $fw-6;
    line-height: $l-height-60;
    border-left: 0;
    padding-left: 0;
    font-family: $font-dosis;
    cite {
      font-size: $fs-16;
      font-weight: $fw-6;
      margin-top: 40px;
      line-height: 30px;
      font-family: $font-hedding;
      font-style: normal;
      span {
        display: block;
        font-size: $fs-14;
      }
    }
  }
  blockquote.quote {
    padding-top: 100px;
    &:before {
      color: $theme-color;
      left: 0;
      top: 0;
      font-size: 120px;
      line-height: 120px;
      right: 0;
    }
  }
  .blockquote-section-left {
    margin-left: 60px;
  }
  .blockquote-section-right {
    margin-left: -120px;
    position: relative;
    z-index: -1;
  }
}
.footer.gray-footer {
  .footer-Newsletter {
    .form-control {
      background: $white;
      color: $black;
      height: 60px;
      line-height: $l-height-60;
      /*      @include box-shadow(0px, 10px, 50px, rgba(0, 0, 0, 0.1));*/
      &::-moz-placeholder {
        color: $black;
      }
      &::-ms-input-placeholder {
        color: $black;
      }
      &::-webkit-input-placeholder {
        color: $black;
      }
    }
  }
  .footer-widget {
    border-top: none;
    p {
      color: $black;
    }
  }
}
.portfolio-item.image-text {
  position: relative;
  .portfolio-overlay {
    bottom: 30px;
    background: transparent;
    span {
      display: block;
    }
    a.button {
      padding: 6px 16px;
      span {
        font-size: 12px;
      }
      &:hover {
        color: $white;
      }
    }
  }
  &:before {
    position: absolute;
    width: 100%;
    bottom: 0;
    content: "";
    ackground: -moz-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(0,0,0,0)),color-stop(100%,rgba(0,0,0,0.9)));
    background: -webkit-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
    background: -o-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
    background: -ms-linear-gradient(top,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.9) 100%);
    top: auto;
    height: 75%;
    transition: all .35s;
  }
}
.coming-soon-big {
  .countdown.medium {
    span {
      font-size: 120px;
      line-height: 120px;
      font-weight: bold;
    }
    p {
      font-size: $fs-30;
      line-height: 30px;
      font-weight: bold;
    }
  }
  .coming-soon-form {
    p {
      font-weight: bold;
    }
  }
  .contact-form.transparent-form {
    .form-control {
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .button {
    font-weight: bold;
  }
}
.about-05-split {
  .shop-split-content {
    padding: 100px 0;
  }
}
.about-mission {
  .popup-video-image {
    a {
      i {
        position: relative;
        top: inherit;
        left: inherit;
        bottom: inherit;
        right: inherit;
      }
    }
  }
  .popup-content {
    position: absolute;
    bottom: 0;
    padding: 30px 130px 30px 30px;
  }
  margin-top: -150px;
}
.about-mission-box {
  /* @include box-shadow(0px, 20px, 46px, -13px rgba(0, 0, 0, 0.09));*/
  .content {
    padding: 24px;
  }
}
.about-mission-title {
  padding-bottom: 250px;
}
.faq-page-title {
  .form {
    position: relative;
    z-index: 9;
  }
  a.button {
    padding: 12px 16px;
  }
}
.faq-box {
  /*  @include box-shadow(0px, 20px, 46px, -13px rgba(0, 0, 0, 0.09));*/
  padding: 40px;
  border-radius: 3px;
  background: $white;
  margin-top: -60px;
  span {
    font-size: $fs-40;
    line-height: $l-height-40;
    margin-bottom: 20px;
    display: block;
  }
  strong {
    display: block;
  }
  a.button {
    padding: 8px 16px;
  }
}
.login-fancy {
  padding: 30px;
  height: 100%;
  /*  @include box-shadow(0px, 15px, 20px, rgba(0, 0, 0, 0.06));*/
  h2 {
    font-size: $fs-70;
    line-height: $l-height-70;
  }
}
.login-fancy-bg {
  position: relative;
  z-index: 1;
}
.login-gradient {
  position: relative;
  &:before {
    z-index: 0;
    opacity: .8;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #9af1e1), color-stop(100%, #4003f6));
    background: -webkit-linear-gradient(-315deg, #9af1e1 0%, #4003f6 100%);
    background: -webkit-linear-gradient(45deg, #9af1e1 0%, #4003f6 100%);
    background: linear-gradient(45deg, #9af1e1 0%, #4003f6 100%);
  }
}
.login-gradient-02 {
  position: relative;
  &:before {
    z-index: 0;
    opacity: .8;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee38a6), color-stop(100%, #ffc863));
    background: -webkit-linear-gradient(-315deg, #ee38a6 0%, #ffc863 100%);
    background: -webkit-linear-gradient(45deg, #ee38a6 0%, #ffc863 100%);
    background: linear-gradient(45deg, #ee38a6 0%, #ffc863 100%);
  }
}
.login-gradient-03 {
  position: relative;
  &:before {
    z-index: 0;
    opacity: .8;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffcdd2), color-stop(100%, #470df5));
    background: -webkit-linear-gradient(-315deg, #ffcdd2 0%, #470df5 100%);
    background: -webkit-linear-gradient(45deg, #ffcdd2 0%, #470df5 100%);
    background: linear-gradient(45deg, #ffcdd2 0%, #470df5 100%);
  }
}
.login-box {
  padding: 30px;
  height: 100%;
  /*  @include box-shadow(0px, 15px, 20px, rgba(0, 0, 0, 0.06));*/
  border-radius: 0px 10px 10px 0px;
  h2 {
    font-size: $fs-40;
    line-height: $l-height-40;
  }
  .list-unstyled.list-inline-item {
    a {
      &:hover {
        color: $black;
      }
    }
  }
}
.login-box-bg {
  position: relative;
  z-index: 1;
}
.login-box-main {
  display: table;
  width: 100%;
  .theme-bg {
    /*    @include box-shadow(0px, 15px, 50px, rgba(0, 0, 0, 0.2));*/
    position: relative;
    z-index: 1;
  }
  .nav {
    li {
      margin-bottom: 5px;
      a {
        padding-left: 30px;
        font-size: $fs-16;
        display: block;
        text-align: left;
        color: $black;
        border-left: 5px solid transparent;
        background: transparent;
        i {
          display: inline-block;
          padding-right: 6px;
          text-align: left;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.login-box-main-middle {
  display: table-cell;
  vertical-align: middle;
}
.login-box-left {
  /*  @include box-shadow(0px, 15px, 20px, rgba(0, 0, 0, 0.06));*/
  height: 100%;
  border-radius: 10px 0 0px 10px;
  .logo-small {
    margin-left: 30px;
  }
  li.active {
    a {
      border-left: 5px solid $theme-color;
      color: $theme-color;
    }
  }
  li {
    a {
      &:hover {
        border-left: 5px solid $theme-color;
        color: $theme-color;
      }
    }
  }
}
.login-box-theme {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    height: 30px;
    top: -29px;
    width: 100%;
    background: $theme-color;
    left: 0;
  }
  &:after {
    position: absolute;
    content: "";
    height: 30px;
    bottom: -29px;
    width: 100%;
    background: $theme-color;
    left: 0;
  }
}
.login-box-02 {
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
}
.login-box-02.white-overlay {
  background: rgba(255, 255, 255, 0.5);
}
.login-signup {
  a.text-black {
    &:hover {
      color: $white;
    }
  }
  .tab-border {
    .tab-content {
      padding: 0;
    }
  }
  .login-box-02 {
    border: 0;
  }
}
.login-14 {
  padding: 0 100px;
}
.login-15 {
  padding: 0 60px;
}
.blog-banner {
  height: 70vh;
  span {
    letter-spacing: 4px;
    margin-bottom: 20px;
    display: block;
  }
}
.blog-overlay {
  position: relative;
  text-align: left;
  z-index: 2;
  /*  @include transition (all, 0.5s, ease);*/
  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    content: "";
    left: 0;
    right: 0;
    z-index: 1;
  }
  .blog-name {
    padding-left: 30px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    span {
      /*       @include transition (all, 0.5s, ease);*/
    }
  }
  .blog-image {
    overflow: hidden;
    position: relative;
    img {
      /*      @include transition (all, 0.5s, ease);*/
    }
  }
  .blog-icon {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 2;
    .date {
      background: $dark-theme-bg;
      padding: 10px 15px;
      text-align: center;
      color: $white;
    }
    .link {
      font-size: $fs-20;
      padding: 10px 15px;
      text-align: center;
      color: $white;
    }
  }
  blockquote {
    border: 0;
    font-size: $fs-16;
    font-style: italic;
  }
  blockquote.quote {
    &:before {
      top: -40px;
    }
  }
  &:hover {
    .blog-image {
      img {
        -webkit-transform: scale(1.20);
        -moz-transform: scale(1.20);
        -ms-transform: scale(1.20);
        -o-transform: scale(1.20);
        transform: scale(1.20);
      }
    }
    &:before {
      z-index: 1;
    }
  }
  .tag {
    background: $theme-color;
    color: $white;
    padding: 2px 10px;
  }
}
.blog-overlay.white-bg {
  &:before {
    display: none;
  }
  blockquote {
    /*    @include transition (all, 0.5s, ease);*/
  }
  cite {
    /*    @include transition (all, 0.5s, ease);*/
  }
  .blog-icon {
    .link {
      color: $black;
    }
  }
  &:hover {
    background: $dark-theme-bg;
    a {
      color: $white;
    }
    span {
      color: $white;
      a {
        color: $theme-color;
      }
    }
    .blog-icon {
      .link {
        color: $white;
      }
    }
    blockquote {
      color: $white;
    }
    cite {
      color: $white;
    }
  }
  a {
    &:hover {
      color: $theme-color;
    }
  }
}
.blog-overlay.dark-theme-bg {
  &:before {
    display: none;
  }
  &:hover {
    background: $white;
    a {
      color: $black;
    }
    span {
      color: $black;
      a {
        color: $theme-color;
      }
    }
    .blog-icon {
      .date {
        color: $white;
      }
    }
  }
  a {
    &:hover {
      color: $theme-color;
    }
  }
}
.blog-overlay.theme-bg {
  &:before {
    display: none;
  }
}
.blog-02 {
  .isotope {
    margin: 0;
  }
  .masonry {
    margin: 0;
  }
}
.blog-fashion-banner {
  .blog-overlay {
    height: auto;
  }
}
.blog.blog-simple {
  .tag {
    background: $theme-color;
    color: $white;
    padding: 2px 10px;
  }
  .entry-meta {
    ul {
      li {
        display: inline-block;
        margin-right: 12px;
        i {
          color: $theme-color;
          padding-right: 6px;
        }
        a {
          color: #353535;
          line-height: 0px;
          padding-right: 5px;
          i {
            padding-right: 6px;
            color: $theme-color;
          }
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
}
.blog.blog-simple.blog-left {
  .blog-image {
    width: 48.9%;
    margin-right: 5%;
    float: left;
    position: relative;
  }
  .blog-name {
    width: 44%;
    float: left;
  }
}
.header.light.line-header {
  border-bottom: 0;
  box-shadow: none;
  .mega-menu {
    .menu-logo {
      >li {
        line-height: 0;
      }
    }
  }
}
.mega-menu {
  .menu-logo {
    img.logo-big {
      height: 60px;
    }
  }
}
.header.line-header {
  .mega-menu {
    >section.menu-list-items {
      .menu-bar {
        border-top: 5px double rgba(0, 0, 0, 0.1);
        border-bottom: 5px double rgba(0, 0, 0, 0.1);
        float: left;
        width: 100%;
      }
    }
  }
  .mega-menu.desktopTopFixed {
    >section.menu-list-items {
      .menu-bar {
        float: right;
        width: auto;
        border: 0;
      }
    }
  }
}
.header.magazine-header {
  .mega-menu.desktopTopFixed {
    .add-banner {
      display: none;
    }
    .menu-logo {
      padding: 0;
    }
  }
}
.header.logo-center.magazine-header {
  .mega-menu {
    .menu-logo {
      img {
        height: 34px;
      }
    }
  }
  .mega-menu.desktopTopFixed {
    .menu-logo {
      img {
        height: 28px;
      }
    }
  }
}
#main-slider {
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  .carousel-item {
    img {
      width: 100%;
    }
    .slider-content {
      z-index: 0;
      opacity: 0;
      -webkit-transition: opacity 500ms;
      -moz-transition: opacity 500ms;
      -o-transition: opacity 500ms;
      -ms-transition: opacity 500ms;
      transition: opacity 500ms;
    }
  }
  .carousel-item.active {
    .slider-content {
      z-index: 0;
      opacity: 1;
      -webkit-transition: opacity 100ms;
      -ms-transition: opacity 100ms;
      -moz-transition: opacity 100ms;
      -o-transition: opacity 100ms;
      transition: opacity 100ms;
    }
  }
  .slider-content {
    display: inline-block;
    left: 0;
    position: absolute;
    text-align: center;
    top: 55%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 100%;
    z-index: 2;
    h3 {
      font-size: 36px;
      margin: 20px 0px 0px;
      font-weight: $fw-6;
      line-height: $l-height-60;
      text-transform: uppercase;
    }
    h1 {
      font-size: $fs-50;
      color: $white;
      margin: 10px 0px 40px;
      font-weight: $fw-7;
      line-height: $l-height-50;
    }
    span {
      font-size: $fs-18;
      line-height: 20px;
      letter-spacing: 2px;
      font-weight: $fw-6;
      display: block;
    }
    .post-by {
      img {
        width: 50px;
        height: 50px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
    .slider-left {
      width: 60%;
      float: left;
    }
    .slider-right {
      float: right;
      width: 30%;
      vertical-align: top;
    }
  }
  .carousel-indicators {
    bottom: 30px;
  }
  .carousel-control.left {
    opacity: 1;
    filter: alpha(opacity=100);
    background-image: none;
    background-repeat: no-repeat;
    text-shadow: none;
    span {
      padding: 15px;
    }
  }
  .carousel-control.right {
    opacity: 1;
    filter: alpha(opacity=100);
    background-image: none;
    background-repeat: no-repeat;
    text-shadow: none;
    span {
      padding: 15px;
    }
  }
  .carousel-control {
    .fa-angle-left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 5;
      display: inline-block;
      left: 0px;
    }
    .fa-angle-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      z-index: 5;
      display: inline-block;
      right: 0px;
    }
    i {
      color: $white;
      line-height: 36px;
      font-size: 32px;
      padding: 15px 20px;
    }
  }
}
.carousel-indicators {
  li {
    cursor: pointer;
    width: 14px !important;
    height: 14px !important;
    border: 2px solid $white !important;
    margin: 1px !important;
  }
}
.carousel {
  .carousel-item.active {
    .animated1 {
      -webkit-animation: lightSpeedIn 1s ease-in 200ms both;
      -o-animation: lightSpeedIn 1s ease-in 200ms both;
      -ms-animation: lightSpeedIn 1s ease-in 200ms both;
      -moz-animation: lightSpeedIn 1s ease-in 200ms both;
      animation: lightSpeedIn 1s ease-in 200ms both;
    }
    .animated2 {
      -webkit-animation: bounceInRight 1s ease-in-out 500ms both;
      -o-animation: bounceInRight 1s ease-in-out 500ms both;
      -ms-animation: bounceInRight 1s ease-in-out 500ms both;
      -moz-animation: bounceInRight 1s ease-in-out 500ms both;
      animation: bounceInRight 1s ease-in-out 500ms both;
    }
    .animated3 {
      -webkit-animation: bounceInLeft 1s ease-in-out 500ms both;
      -o-animation: bounceInLeft 1s ease-in-out 500ms both;
      -ms-animation: bounceInLeft 1s ease-in-out 500ms both;
      -moz-animation: bounceInLeft 1s ease-in-out 500ms both;
      animation: bounceInLeft 1s ease-in-out 500ms both;
    }
    .animated4 {
      -webkit-animation: flipInX 1s ease-in 500ms both;
      -o-animation: flipInX 1s ease-in 500ms both;
      -ms-animation: flipInX 1s ease-in 500ms both;
      -moz-animation: flipInX 1s ease-in 500ms both;
      animation: flipInX 1s ease-in 500ms both;
    }
    .animated5 {
      -webkit-animation: bounceInLeft 1s ease-in-out 100ms both;
      -o-animation: bounceInLeft 1s ease-in-out 100ms both;
      -ms-animation: bounceInLeft 1s ease-in-out 100ms both;
      -moz-animation: bounceInLeft 1s ease-in-out 100ms both;
      animation: bounceInLeft 1s ease-in-out 100ms both;
    }
    .animated6 {
      -webkit-animation: bounceIn 1s ease-in 500ms both;
      -o-animation: bounceIn 1s ease-in 500ms both;
      -ms-animation: bounceIn 1s ease-in 500ms both;
      -moz-animation: bounceIn 1s ease-in 500ms both;
      animation: bounceIn 1s ease-in 500ms both;
    }
    .animated7 {
      -webkit-animation: fadeInDown 0.7s ease-in 1000ms both;
      -o-animation: fadeInDown 0.7s ease-in 1000ms both;
      -moz-animation: fadeInDown 0.7s ease-in 1000ms both;
      -ms-animation: fadeInDown 0.7s ease-in 1000ms both;
      animation: fadeInDown 0.7s ease-in 1000ms both;
    }
    .animated8 {
      -webkit-animation: fadeInUp 0.7s ease-in 1000ms both;
      -o-animation: fadeInUp 0.7s ease-in 1000ms both;
      -moz-animation: fadeInUp 0.7s ease-in 1000ms both;
      -ms-animation: fadeInUp 0.7s ease-in 1000ms both;
      animation: fadeInUp 0.7s ease-in 1000ms both;
    }
  }
}
.carousel-fade {
  .carousel-inner {
    .carousel-item {
      -webkit-transition-property: opacity;
      transition-property: opacity;
      opacity: 0;
    }
    .active.left {
      opacity: 0;
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .active.right {
      opacity: 0;
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .active {
      opacity: 1;
    }
    .next.left {
      opacity: 1;
    }
    .prev.right {
      opacity: 1;
    }
    .next {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .prev {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}
.play-video.border-video {
  width: 70px;
  height: 70px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: none !important;
  border: 5px solid rgba(255, 255, 255, 0.6) !important;
  a {
    width: 70px;
    height: 70px;
    line-height: $l-height-70;
    top: -4px;
    i {
      line-height: $l-height-60;
    }
  }
  &:after {
    display: none;
  }
  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.8) !important;
    color: $white;
  }
}
.video-attribute {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .length {
    background: rgba(0, 0, 0, 0.8);
    padding: 1px 8px;
    color: $white;
    border-radius: 2px;
    font-size: $fs-13;
  }
  .quality {
    background: #dd3333;
    padding: 0px 8px;
    color: $white;
    margin-left: 2px;
    border-radius: 2px;
    font-size: $fs-13;
  }
}
.popup-video-image.border-video {
  a {
    i {
      font-size: $fs-14;
      width: 70px;
      height: 70px;
      line-height: 62px;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: none;
      border: 5px solid rgba(255, 255, 255, 0.6);
      &:hover {
        border: 5px solid rgba(255, 255, 255, 0.8);
      }
    }
  }
}
.blog-box.blog-2.blog-border {
  .blog-info {
    border: 1px solid rgba(0, 0, 0, 0.1);
    .tag {
      border-bottom: 2px solid $theme-color;
    }
    span {
      i {
        color: rgba(0,0,0,0.5);
      }
    }
  }
}
.video-section {
  .blog-box.blog-2 {
    &:hover {
      box-shadow: none;
    }
  }
}
.top-view-video {
  padding: 30px;
  span {
    font-size: 12px;
  }
  .view-video-number {
    display: table-cell;
    width: 20px;
  }
  .view-video-name {
    padding-left: 16px;
    display: table-cell;
    vertical-align: top;
  }
}
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 4px;
}
.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.chart-wrapper {
  .nice-select {
    display: inline-block;
    float: none;
    height: 40px;
    line-height: $l-height-40;
    &:after {
      margin-top: -1px;
    }
  }
}
.media {
  .media-icon {
    span {
      font-size: $fs-40;
      line-height: 45px;
    }
  }
  span.media-step {
    font-size: $fs-60;
    line-height: $l-height-55;
  }
}
.secrvice-blog {
  margin-top: -110px;
}
.box-content {
  box-shadow: 0px 15px 30px rgb(0 0 0 / 10%);
}
.service-07 {
  .agency-02-about-content-right {
    margin-left: -100px;
  }
}
.service {
  ul {
    li {
      display: block;
      margin: 5px 0;
      a {
        display: block;
        padding: 12px 25px;
        color: #656565;
        text-transform: capitalize;
        &:hover {
          background-color: $theme-color;
          color: $white;
        }
      }
      a.active {
        background-color: $theme-color;
        color: $white;
      }
    }
  }
}
.iconbox {
  padding-left: 60px;
  i {
    font-size: $fs-18;
    line-height: $l-height-50;
  }
  a {
    height: 50px;
    width: 50px;
    text-align: center;
    color: $white;
    position: absolute;
    left: 15px;
    border-radius: 3px;
    &:hover {
      background: #353535;
      color: $white;
      border-color: #353535;
      /*      @include transition (all, 0.5s, ease);*/
    }
  }
  p {
    font-size: $fs-11;
  }
}
.contact-4 {
  .blockquote {
    border: none;
    font-size: $fs-30;
  }
  p {
    line-height: $l-height-24;
  }
}
.quote {
  &:before {
    color: $theme-color;
  }
}
.contact-5 {
  .feature-info {
    p {
      margin-bottom: 0;
    }
    padding: 40px;
  }
}
.blockquote-section.testimonial-title {
  blockquote {
    font-size: $fs-40;
  }
}
.masonry-item {
  .testimonial-widget {
    .testimonial-info {
      .testimonial-avtar {
        img {
          height: 50px;
          width: 50px;
          border-radius: 50%;
        }
      }
      .testimonial-name {
        span {
          font-size: $fs-14;
        }
      }
    }
  }
  .testimonial-info {
    &:before {
      display: none;
    }
  }
}
.leave-your-testimonial {
  .contact-form {
    .form-control {
      background-color: $white;
    }
  }
}
.search-no-result {
  padding: 130px 0px;
  i {
    font-size: 130px;
  }
  .bg-title {
    h2 {
      font-size: 420px;
      line-height: 300px;
      color: rgba(0, 0, 0, 0.03);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}
.no-result-search {
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
}
.cookies-policy {
  &:before {
    content: "";
    position: absolute;
    z-index: 3;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 0 17px 15px 17px;
    top: -15px;
    left: 45px;
    border-bottom-color: $gray-bg;
  }
}
.addresss-info.invoice-addresss {
  li {
    color: $text-dark-gray;
    margin-bottom: 10px;
    a {
      color: $theme-color;
    }
  }
}
.process-box-02 {
  h1 {
    font-size: $fs-90;
    line-height: $l-height-90;
  }
  .process-info {
    border-left: 3px solid rgba(0, 0, 0, 0.1);
  }
  .process-info.process-right {
    border-left: 0;
    border-right: 3px solid rgba(0, 0, 0, 0.1);
  }
}
.jobs-banner {
  h1 {
    font-size: $fs-80;
    line-height: $l-height-80;
    font-weight: $fw-4;
  }
  span {
    font-size: $fs-26;
    line-height: 26px;
    font-weight: $fw-4;
  }
  b {
    font-size: $fs-20;
    line-height: 20px;
    font-weight: $fw-5;
  }
  .button {
    padding: 12px 21px;
  }
}
.job-box {
  /*  @include box-shadow(0px, 0px, 50px, rgba(0, 0, 0, 0.05));*/
  padding: 20px;
}
.job-box.job-border {
  border: 1px solid #eeeeee;
  padding: 20px;
  box-shadow: none;
  /*  @include transition (all, 0.5s, ease);*/
  i {
    font-size: $fs-40;
    line-height: $l-height-40;
    margin-bottom: 20px;
  }
  &:hover {
    /*    @include box-shadow(0px, 0px, 50px, rgba(0, 0, 0, 0.05));*/
  }
}
.job-testimonial {
  padding: 0;
  .testimonial-info {
    &:before {
      font-size: 200px;
      left: 0;
    }
    font-size: 24px;
    line-height: 36px;
  }
}
.listing-banner {
  height: 60vh;
  h1 {
    font-size: $fs-70;
    line-height: $l-height-70;
    font-weight: $fw-4;
    margin-bottom: 20px;
  }
  p {
    font-size: 24px;
    line-height: $l-height-24;
    font-weight: $fw-4;
  }
  .listing-banner-categorie {
    i {
      font-size: $fs-30;
      width: 70px;
      height: 70px;
      text-align: center;
      line-height: $l-height-70;
      color: $white;
      border-radius: 50%;
      margin: 0 auto;
    }
    a {
      font-size: $fs-18;
      display: block;
      margin-top: 10px;
    }
  }
}
.listing-search {
  /*  @include box-shadow(0px, 0px, 50px, rgba(0, 0, 0, 0.05));*/
  background: $white;
  padding: 50px;
  position: relative;
  margin-top: -50px;
  border-radius: 3px;
}
.listing-post {
  .blog-name {
    .blog-name-left {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      color: $white;
      text-align: center;
      line-height: $l-height-50;
      float: left;
      margin-right: 20px;
    }
    .blog-name-right {
      display: table-cell;
    }
  }
  .listing-post-info {
    background: $white;
    padding: 20px;
    .listing-post-meta {
      ul {
        li {
          display: inline-block;
          margin-right: 5px;
          padding-right: 10px;
          border-right: 2px solid rgba(0,0,0,0.1);
          &:last-child {
            border-right: 0;
          }
          a {
            font-weight: $fw-8;
            color: $black;
          }
        }
      }
    }
  }
}
.listing-places {
  padding-bottom: 260px;
}
.listing-how-work {
  position: relative;
  background: $white;
  /*  @include box-shadow(0px, 0px, 50px, rgba(0, 0, 0, 0.05));*/
  padding: 70px;
  margin-top: -140px;
}
.sticky-top {
  z-index: 99;
}
.calendar-main {
  .modal {
    .btn {
      padding: 0.875rem .75rem;
    }
  }
}
.fc-button {
  background: #f8f9fa;
  border: none;
  color: $text-dark-gray;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important;
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
}
.fc-state-active {
  background: $theme-color;
  color: $white;
}
.fc-today-button {
  background: $theme-color;
  color: $white;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: $fs-13;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
  background: $theme-color;
}
.fc-day-grid-event {
  .fc-time {
    color: $white;
  }
  .fc-title {
    color: $white;
  }
}
.fc-event-dot {
  background: $theme-color;
}
.fc-day-header {
  span {
    padding: 15px 0;
    display: block;
    background: #f8f9fa;
    color: #1a1a1a;
  }
}
.demoFeed {
  li {
    list-style: none;
    display: inline-block;
    width: 25%;
    img {
      width: 100%;
      height: 100% !important;
    }
  }
}
.flickr-feed {
  li {
    list-style: none;
    display: inline-block;
    width: 25%;
    img {
      width: 100%;
      height: 100% !important;
    }
  }
}
.portfolio-creative {
  h1 {
    font-size: $fs-40;
    line-height: $l-height-50;
    font-weight: $fw-5;
    margin-bottom: 20px;
    font-family: $font-dosis;
  }
  del {
    background: none;
    color: #dadada;
  }
}
.business-banner-02 {
  height: 100vh;
  h1 {
    font-size: $fs-50;
    line-height: $l-height-50;
    font-weight: $fw-6;
  }
  .box-newsletter {
    padding: 50px;
    background: rgba(38, 38, 38, 0.82);
    .form-control {
      background: $white;
    }
  }
}
.skill-counter {
  position: relative;
  background: $white;
  margin-top: -100px;
  z-index: 999;
  padding: 60px;
  /*  @include box-shadow(0px, 0px, 40px, rgba(0, 0, 0, 0.05));*/
}
.business-banner {
  span {
    font-size: 36px;
    line-height: 36px;
  }
  h1 {
    font-family: $font-dosis;
    font-size: 100px;
    line-height: 100px;
    letter-spacing: 40px;
    font-weight: $fw-6;
    margin-top: 20px;
  }
  p {
    font-size: $fs-20;
    line-height: 30px;
    padding: 0 360px;
  }
}
.business-13-about-content {
  /*  @include box-shadow(0px, 0px, 60px, rgba(0, 0, 0, .1));*/
  padding: 100px;
  position: relative;
  margin-top: -80px;
  z-index: 4;
}
.business-date-box {
  position: relative;
  padding: 20px;
  .business-date-box-text {
    span {
      position: absolute;
      font-size: $fs-20;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      letter-spacing: 18px;
      vertical-align: top;
      right: -70px;
      top: 80px;
    }
  }
}
.business-date-box-text {
  position: relative;
  background: $white;
  padding: 40px;
  h6 {
    line-height: 30px;
  }
  h2 {
    font-size: 200px;
    line-height: 200px;
    display: inline-block;
    padding-right: 30px;
    position: relative;
  }
}
.business-banner-03 {
  h1 {
    font-size: $fs-80;
    line-height: $l-height-80;
    font-weight: $fw-4;
    margin-bottom: 30px;
  }
  span {
    font-size: $fs-20;
    line-height: 20px;
    font-weight: $fw-4;
    letter-spacing: 30px;
  }
}
.business-video {
  .business-video-box {
    min-height: 600px;
    .vertical-align {
      padding: 100px;
      width: 100%;
    }
  }
}
.business-feature.feature-text {
  padding: 50px;
  border-radius: 5px;
  position: relative;
  /*  @include box-shadow(0px, 10px, 60px, -13px rgba(0, 0, 0, 0.1));*/
}
.business-banner-04 {
  height: 94vh;
  h1 {
    font-size: $fs-80;
    line-height: $l-height-80;
    font-weight: $fw-7;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p {
    font-size: 17px;
    padding-right: 200px;
  }
  strong {
    font-size: $fs-20;
    margin-top: 20px;
  }
}
.business-feature-top {
  background: $white;
  /*  @include box-shadow(0px, 0px, 60px, rgba(0, 0, 0, 0.1));*/
  padding: 60px;
  position: relative;
  margin-top: -80px;
  z-index: 4;
}
.business-banner-05 {
  height: 94vh;
  h1 {
    font-size: $fs-50;
    line-height: $l-height-50;
    font-weight: $fw-6;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p {
    font-size: 17px;
    padding-right: 200px;
  }
}
.business-service-box {
  border: 1px solid #eeeeee;
  padding: 20px 20px;
  height: 100%;
  .title {
    padding: 67px 0;
  }
}
.business-big-testimonial {
  .testimonial-info {
    font-size: $fs-18;
    line-height: 34px;
  }
}
.business-banner-06 {
  height: 90vh;
  h1 {
    font-size: $fs-50;
    line-height: $l-height-60;
    font-weight: $fw-6;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  p {
    font-size: 17px;
    padding-right: 200px;
  }
}
.landing-banner {
  height: 94vh;
  span.label {
    background: $gray-bg;
    padding: 10px 20px;
    border: 1px solid rgba(132, 186, 63, 0.1);
    display: inline-block;
  }
  h1 {
    font-size: $fs-80;
    line-height: $l-height-90;
    font-weight: $fw-5;
    span {
      position: relative;
      &:before {
        position: absolute;
        background: rgba(132, 186, 63, 0.2);
        height: 10px;
        content: "";
        bottom: 12px;
        width: 100%;
      }
    }
  }
  p {
    font-size: $fs-20;
  }
}

.action-box-button .button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
}

.landing-block {
  .landing-block-content {
    padding: 50px;
    margin-left: 50px;
    margin-top: -50px;
    z-index: 1;
    position: relative;
    h3 {
      line-height: $l-height-40;
    }
    p {
      font-size: $fs-20;
    }
  }
}
.landing-testimonial {
  .testimonial-info {
    font-size: $fs-22;
    line-height: 44px;
  }
  .testimonial.clean {
    padding-left: 0;
  }
}
.landing-action-box {
  .action-box {
    border: 0px;
    border-top: 3px solid $gray-bg;
    padding-top: 50px;
  }
}
.landing-banner-02 {
  height: 74vh;
  span.label {
    padding: 10px 20px;
    display: inline-block;
  }
  h1 {
    font-size: $fs-50;
    line-height: $l-height-70;
    font-weight: $fw-8;
  }
  p {
    font-size: $fs-20;
  }
}
.landing-newsletter {
  .newsletter.fancy {
    /*    @include box-shadow(0px, 10px, 10px, -13px, rgba(0, 0, 0, 0.12));*/
    .form-control {
      background: $white;
      padding: 30px 200px 30px 40px;
      font-size: $fs-20;
      &:focus {
        box-shadow: none;
      }
    }
    button {
      padding: 32px 30px;
    }
  }
}
@media (min-width: 1220px) {
  body.boxed-layout {
    .wrapper {
      float: none;
      width: 1220px;
      margin: 0 auto;
      position: relative;
      background-color: $white;
      /*      @include box-shadow(0px, 0px, 60px, rgba(0, 0, 0, 0.1));*/
      margin-bottom: 0 !important;
      .portfolio-title.section-title {
        p {
          display: none;
        }
        span {
          display: none;
        }
      }
      .contact-3 {
        .g-map {
          padding-top: 140%;
        }
      }
      .contact-2 {
        .g-map {
          padding-top: 90%;
        }
      }
    }
    .wrapper.vertical-header {
      width: auto;
      box-shadow: none;
    }
    #footer-fixed {
      position: inherit !important;
      width: inherit;
      bottom: inherit !important;
      z-index: inherit;
    }
    .christmas-coming-soon {
      position: absolute;
    }
    .container {
      width: 1170px;
    }
  }
  body.frame-layout {
    .wrapper {
      margin: 0 auto;
      margin-top: 1.5%;
      margin-bottom: 1.5%;
      width: 97%;
      position: relative;
      /*      @include box-shadow(0px, 0px, 60px, rgba(0, 0, 0, 0.1));*/
      margin-bottom: 1.5% !important;
    }
    .header.default {
      left: 0;
      right: 0;
    }
    .header.transparent {
      left: 0;
      right: 0;
    }
    header.one-page {
      left: 0;
      right: 0;
    }
    .navbar.affix {
      top: 0;
    }
    header.one-page.light {
      .navbar {
        min-height: 10px !important;
      }
    }
    #footer-fixed {
      position: inherit !important;
      bottom: inherit !important;
      z-index: inherit;
    }
    .christmas-coming-soon {
      position: absolute;
    }
  }
}
@media print {
  .sidebar {
    display: none !important;
  }
  footer {
    display: none !important;
  }
  .admin-header {
    display: none !important;
  }
  header {
    display: none !important;
  }
  .page-title {
    display: none !important;
  }
  #back-to-top {
    display: none !important;
  }
  .action-box {
    display: none !important;
  }
  .content-wrapper {
    width: 100% !important;
    margin-left: 0;
  }
}
@media all and (-ms-high-contrast:none) {
  .st-menu {
    position: absolute;
  }
  img.bottom-img {
    top: 0;
  }
  .search-cart {
    display: table;
    .shpping-cart {
      float: none !important;
      vertical-align: middle;
    }
  }
  .search {
    display: table-cell !important;
  }
  .shpping-cart {
    display: table-cell !important;
  }
  .mega-menu {
    >section.menu-list-items {
      .search-cart {
        .search {
          float: none !important;
        }
      }
    }
  }
  .logo-center.header {
    .search {
      .search-btn {
        line-height: 0px;
      }
    }
  }
  .newsletter.fancy {
    button {
      padding: 14px 20px;
    }
  }
}
@media not all and (min-resolution:.001dpcm) {
  .newsletter.fancy {
    button {
      padding: 14px 20px;
    }
  }
}

.fs-42{
  font-size: $fs-42;
  line-height: 1;
}

.tag-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    color: #8f9397;
    display: inline-block;
    font-size: .8125rem;
    margin-bottom: 10px;
    margin-right: 5px;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &.active{
      background: linear-gradient(150deg,#97c74e,#2ab9a5);
      color: #ffff;
    }

    &:hover {
      background-color: #ddd;
    }
  }
}

@media (max-width: 995px) {
  .aside-blog{
    padding-bottom: 20px;
  }
}