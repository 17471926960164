

/*************************
       1700px
*************************/
@media (max-width: 1700px) {

	.portfolio-title {
		padding: 60px 60px 30px;
	}
	.our-blog {
		.blog-box-image {
			max-height: 286px;
		}
		.blog-box-info {
			max-height: 286px;
		}
	}
	.feature-step-2 {
		.feature-step-2-title-left {
			h1 {
				font-size: $fs-60;
				line-height: $l-height-50;
			}
		}
	}
	.objects-left {
		.objects-1 {
			left: -20px;
		}
	}
	.video-background-banner {
		.slider-content {
			padding: 20px 30px;
		}
	}
	.scroll-down {
		bottom: 30px;
	}
	.book-banner {
		.slider-content-image {
			.book {
				height: 500px;
			}
		}
	}
	.fish-banner {
		.slider-content {
			h1 {
				font-size: $fs-80;
				line-height: 80px;
			}
			.fish-banner-image {
				img {
					height: 500px;
				}
			}
		}
		.fish-banner-content {
			padding-top: 90px;
		}
	}
	.fish-about {
		&:before {
			background-position: -250px;
		}
	}
	.awards-bg {
		.awards {
			&:before {
				left: -20px;
			}
			&:after {
				right: -20px;
			}
		}
	}
	.hotel-banner-checkout {
		.checkout {
			.checkout-name {
				h3 {
					font-size: $fs-20;
				}
			}
		}
	}


}

@media (max-width: 1500px) {

	.portfolio-home {
		.section-title {
			p {
				font-size: $fs-16;
			}
		}
	}
	.our-blog {
		.blog-box-image {
			max-height: 262px;
		}
		.blog-box-info {
			max-height: 262px;
		}
	}
	.blog-box-image {
		.portfolio-caption {
			.port-tag {
				li {
					a {
						font-size: $fs-12;
					}
				}
			}
		}
	}
	.slider-fade-carousel {
		height: 100%;
	}
	.our-service {
		padding-bottom: 60px;
	}
	.objects-left {
		display: none;
	}
	.objects-right {
		display: none;
	}
	.fullWidth {
		.mega-menu {
			.drop-down.grid-col-5 {
				width: 51.666667%;
			}
		}
	}
	.fancy {
		.mega-menu {
			.drop-down.grid-col-5 {
				width: 51.666667%;
			}
		}
	}
	.resume-contact {
		.g-map {
			height: 642px;
		}
	}
	.plumber-banner {
		.slider-content-image {
			img {
				height: 600px;
			}
		}
	}
	.awards-bg {
		padding: 80px 30px;
	}
	.certi-bg {
		padding: 80px 30px;
	}
	.pizza-dish-main {
		overflow: hidden;
	}

}

@media (max-width: 1400px) {

	.portfolio-title {
		padding: 40px 40px 0px;
	}
	.portfolio-home {
		.portfolio-title.section-title {
			margin-bottom: 20px !important;
			.button.mt-30 {
				margin-top: 10px !important;
			}
		}
	}
	.our-blog {
		.blog-box-image {
			max-height: 240px;
		}
		.blog-box-info {
			max-height: 240px;
			p.pb-20.pt-30 {
				padding: 10px 0 !important;
			}
			span.text-white {
				display: none;
			}
		}
	}
	.custom-content-3.hello-content {
		.custom-content-3-image {
			img {
				margin-top: 150px;
				vertical-align: bottom;
				width: 100%;
			}
		}
	}
	.contact-3-info {
		.contact-add {
			text-align: center;
			i {
				float: none;
				text-align: center;
				display: block;
				margin: 0 auto;
			}
			p {
				display: block;
				margin-top: 15px;
			}
		}
	}
	.image-holder-2-bg {
		height: 700px;
	}
	.feature-step-2 {
		.feature-step-2-title-2-right {
			h2 {
				font-size: $fs-24;
				line-height: $l-height-30;
			}
			h3 {
				font-size: $fs-20;
				line-height: $l-height-24;
			}
		}
	}
	.feature-step-2-box {
		padding: 50px 30px;
	}
	.resume-page {
		.container-fluid {
			padding: 0 30px;
		}
	}
	.resume-contact {
		.contact-form-main {
			padding: 30px;
		}
	}
	.header.fancy.without-topbar {
		.menu {
			top: 16px;
		}
	}
	#rev_slider_16_1_forcefullwidth {
		.tp-parallax-wrap {
			margin-top: 20px;
		}
	}
	.awesome-features {
		.img-side {
			img {
				top: 20px;
			}
		}
	}
	.video-background-banner {
		.slider-content {
			padding: 30px 30px;
		}
		h1 {
			font-size: $fs-60;
			line-height: 60px;
		}
	}
	.slider-parallax.banner-personal {
		.slider-content {
			h1 {
				font-size: 110px;
				line-height: 120px;
				strong {
					font-size: 132px;
				}
			}
		}
	}
	.movie-banner {
		.slider-content {
			.banner-popup {
				width: 72%;
			}
		}
	}
	.blog-overlay {
		blockquote.quote {
			&:before {
				display: none;
			}
		}
	}
	.fish-banner {
		.slider-content {
			p {
				font-size: $fs-22;
			}
		}
	}
	.hotel-feature-step {
		.feature-step-2-box {
			padding: 70px 20px;
		}
	}
	.pizza-about {
		.pizza-about-content {
			padding-top: 0;
		}
	}
	.footer-simple {
		img#logo-footer {
			height: 30px;
		}
	}

}

@media (max-width: 1272px) {

	.our-blog {
		.blog-box-image {
			max-height: 185px;
			.portfolio-caption {
				.port-tag {
					li {
						margin-top: 0px;
					}
				}
				.button-border {
					span {
						margin-top: 10px;
						padding: 4px 10px;
						font-size: 11px;
					}
				}
			}
		}
		.blog-box-info {
			max-height: 185px;
			padding: 15px 20px;
			a.button.button-white {
				span {
					padding: 6px 16px;
				}
				i {
					padding: 11px;
				}
			}
			h1 {
				font-size: $fs-20;
				line-height: $l-height-20;
				margin-bottom: 0;
			}
			p {
				font-size: $fs-14;
			}
		}
	}
	.team-3 {
		.team-social-icon {
			a {
				i {
					font-size: $fs-12;
					height: 30px;
					line-height: 31px;
					width: 30px;
				}
			}
		}
	}
	.our-history {
		.history-content {
			padding: 125px 20px;
		}
	}
	.about-me {
		.maintenance-progress-bar {
			.progress-bar {
				margin: 0 15px;
			}
		}
	}
	.blog.timeline-sidebar {
		.timeline {
			li.timeline-inverted {
				.timeline-badge {
					left: -50px;
				}
			}
		}
	}
	.error-search-box {
		width: 91%;
	}
	.fullWidth {
		.mega-menu {
			.drop-down.grid-col-5 {
				width: 61.666667%;
			}
		}
	}
	.fancy {
		.mega-menu {
			.drop-down.grid-col-5 {
				width: 61.666667%;
			}
		}
	}
	.feature-step-2 {
		.feature-step-2-title-left {
			h1 {
				font-size: $fs-40;
				line-height: $l-height-30;
			}
		}
		.feature-step-2-title-2-right {
			h2 {
				font-size: $fs-22;
				line-height: $l-height-24;
			}
			h3 {
				font-size: $fs-18;
				line-height: $l-height-20;
			}
		}
	}
	.pricing-table.active {
		.pricing-top {
			a.button {
				padding: 12px 30px;
			}
		}
	}
	.book-banner {
		.slider-content-text {
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
				margin-top: 10px !important;
			}
			h2 {
				font-size: $fs-30;
				line-height: $l-height-30;
				margin-top: 10px !important;
			}
			p {
				font-size: $fs-18;
				line-height: $l-height-30;
				margin-top: 0px !important;
			}
			.button {
				margin-top: 3px !important;
				padding: 5px 10px;
			}
		}
		.slider-content-image {
			.book {
				height: 400px;
			}
		}
	}
	.fish-banner {
		.slider-content {
			h1 {
				font-size: $fs-60;
				line-height: 60px;
			}
			p {
				font-size: $fs-18;
			}
			.fish-banner-image {
				img {
					height: 400px;
				}
			}
		}
	}
	.hotel-banner-checkout {
		.checkout {
			.checkout-date {
				.button {
					span {
						font-size: $fs-20;
					}
				}
				.form-control {
					font-size: $fs-24;
				}
				.nice-select {
					span {
						font-size: $fs-24;
					}
				}
				label.mb-30 {
					margin-bottom: 15px !important;
				}
				>span {
					margin-bottom: 5px !important;
				}
			}
			.checkout-name {
				h3 {
					font-size: $fs-18;
				}
			}
		}
	}

}

@media (min-width: 1200px) {
	.container { max-width: 1170px; }
}

@media (max-width: 1200px) {
	/*home2*/
	/*home 9*/
	/*medical*/
	/*404 error*/
	/*************************************
             v1.0.3
**************************************/
	/*************************************
             v1.0.4
**************************************/
	/*************************************
             v1.0.7
**************************************/
	/*************************************
             v1.0.8
**************************************/
	/*************************************
             v1.0.9
**************************************/
	/*************************************
             v1.2
**************************************/
	/*************************************
             v1.5
**************************************/
	/*************************************
             v1.5
**************************************/
	/*************************************
             v3.2
**************************************/
	/*************************************
             v3.3
**************************************/
	/*************************************
             v3.6
**************************************/
	.login-2-social {
		li {
			margin: 0 3px;
		}
	}
	h2 {
		font-size: $fs-28;
	}
	h4 {
		font-size: $fs-18;
	}
	.title-effect {
		&::before {
			height: 25%;
			bottom: 4px;
		}
	}
	.testimonial-2 {
		.testimonial-center {
			width: 100%;
		}
	}
	.deal-banner {
		img {
			width: initial;
		}
		.countdown.small {
			margin: 0;
		}
	}
	.footer-widget-social.float-right {
		float: none !important;
	}
	.blog-info {
		h4 {
			font-size: $fs-15;
		}
		p {
			font-size: $fs-16;
			padding: 10px 0 20px;
		}
	}
	.blog-box.blog-1 {
		.blog-info {
			p {
				font-size: $fs-14;
			}
		}
	}
	.feature-text.box {
		padding: 30px;
	}
	.feature-text.fill-box {
		padding: 30px;
	}
	.nav.nav-tabs {
		li {
			a {
				margin-right: 5px;
				margin-bottom: 0px;
			}
		}
	}
	.happy-clients {
		.nav-tabs {
			li {
				img {
					height: 80px;
					width: 80px;
				}
			}
		}
	}
	.team {
		.team-social-icon {
			ul {
				li {
					a {
						i {
							height: 35px;
							line-height: 35px;
							width: 35px;
							font-size: $fs-14;
						}
					}
				}
			}
		}
		&:hover {
			.team-social-icon {
				bottom: 27%;
			}
		}
	}
	.feature-info {
		h5 {
			font-size: $fs-15;
		}
	}
	.contact-3 {
		.map-side {
			height: 878px;
		}
	}
	.contact-4 {
		.map-side {
			height: 553px;
		}
	}
	.service-blog {
		padding-top: 0;
	}
	.portfolio-home {
		.isotope.columns-3 {
			.grid-item {
				width: 50%;
				width: 50%;
			}
		}
	}
	.portfolio-title {
		padding: 60px 60px 30px;
	}
	.action-box {
		h3 {
			font-size: $fs-22;
		}
		p {
			font-size: $fs-15;
		}
		a.button {
			right: 0;
			position: relative;
			top: inherit;
			margin-top: 10px;
		}
		.action-box-text {
			padding-right: 0;
		}
	}
	.vertical-header {
		.container-fluid {
			padding: 0 30px;
		}
	}
	.team.team-round.full-border {
		.team-description {
			padding: 30px 0;
		}
	}
	.medical-tab {
		.nav.nav-tabs {
			li {
				a {
					margin-bottom: 0;
					padding: 12px 20px;
				}
			}
		}
	}
	.error-block {
		padding: 20px 0 70px 0px;
		.error-text {
			h2 {
				font-size: 320px;
			}
			span {
				font-size: 104px;
				right: 0;
			}
		}
	}
	.error-text-02 {
		h2 {
			font-size: 320px;
		}
		span {
			font-size: 104px;
			right: 0;
		}
	}
	.add-banner-2 {
		.add-banner-content {
			h2 {
				font-size: 42px;
				line-height: 42px;
			}
		}
	}
	.pricing-content {
		.pricing-table-list {
			ul {
				li {
					font-size: $fs-14;
					i {
						width: 18px;
					}
				}
			}
		}
		width: 90%;
	}
	.round {
		.nav.nav-tabs {
			li {
				a {
					padding: 12px 20px;
				}
			}
		}
	}
	#rev_slider_16_1_forcefullwidth {
		.tp-parallax-wrap {
			margin-top: 50px;
		}
	}
	#services {
		.feature-text.left-icon {
			.feature-icon {
				position: relative;
				text-align: left;
			}
			.feature-info {
				padding-left: 0;
			}
		}
		.feature-text {
			.feature-icon {
				span {
					padding-left: 0;
					margin-bottom: 10px;
				}
				i {
					padding-left: 0;
					margin-bottom: 10px;
				}
			}
		}
	}
	.login-social {
		li {
			margin: 3px 0px;
		}
	}
	.blog.blog-grid-3-column {
		.blog-detail {
			.social.float-right {
				float: none !important;
				display: block;
				margin-top: 5px;
			}
		}
	}
	.blog.blog-grid-2-sidebar {
		.blog-detail {
			.social.float-right {
				float: none !important;
				display: block;
				margin-top: 5px;
			}
		}
	}
	.blog.masnary-blog-3-columns {
		.blog-detail {
			.social.float-right {
				float: none !important;
				display: block;
				margin-top: 5px;
			}
		}
	}
	.blog.masonry-main {
		.blog-detail {
			.social.float-right {
				float: none !important;
				display: block;
				margin-top: 5px;
			}
		}
	}
	.timeline {
		.blog-detail {
			.social.float-right {
				float: none !important;
				display: block;
				margin-top: 5px;
			}
		}
	}
	.awesome-features {
		.img-side {
			img {
				top: 10%;
			}
		}
	}
	.footer.footer-topbar {
		.copyright {
			ul {
				li {
					padding: 0;
				}
			}
		}
	}
	.isotope.columns-4 {
		.grid-item {
			width: 33.333%;
		}
	}
	.mega-menu {
		.menu-links {
			>li {
				>a {
					padding: 0 10px;
				}
			}
		}
		.drop-down-multilevel {
			width: 200px;
		}
	}
	.blockquote-section {
		blockquote {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.christmas-countdown {
		.countdown {
			margin: 0px 5px;
			width: 130px;
			height: 130px;
			span {
				font-size: $fs-34;
			}
		}
	}
	.conference-about-content-right {
		padding: 92px 0;
	}
	.conference-about-content-left {
		padding: 110px 0 80px 0px;
	}
	.timer-banner {
		.slider-content {
			h1 {
				font-size: $fs-80;
				line-height: 80px;
			}
		}
	}
	.law-timeline {
		.timeline-centered {
			&:before {
				left: 62px;
			}
		}
	}
	.portfolio-parallax-banner {
		p {
			padding-right: 400px;
		}
		h2 {
			font-size: 90px;
			line-height: 90px;
		}
	}
	.coming-soon-aerial {
		.coming-soon-form.contact-form {
			input {
				width: 100%;
			}
		}
	}
	.coming-soon-big {
		padding: 0;
	}
	.error-05 {
		.error-bottom {
			.tree {
				left: -120px;
			}
			.back-to-home {
				right: 0;
			}
		}
		h3 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
		span {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
		p {
			font-size: $fs-22;
		}
	}
	.error-03 {
		.error-404 {
			h1 {
				font-size: $fs-100;
				line-height: $l-height-100;
			}
		}
		.content {
			h1 {
				font-size: 48px;
			}
			h2 {
				font-size: 42px;
			}
		}
	}
	.coming-soon-birds {
		.countdown {
			margin: 0px 10px;
		}
	}
	.about-05-split {
		.shop-split-content {
			padding: 20px 0;
		}
	}
	#main-slider {
		.slider-content {
			h1 {
				font-size: $fs-40;
			}
		}
	}
	.header.logo-center.magazine-header {
		.mega-menu {
			.menu-links {
				>li {
					>a {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}
		.menu-logo {
			padding: 14px 0;
		}
	}
	#main-slider.shop-04-banner {
		.slider-content {
			h1 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
			p {
				font-size: $fs-24;
				line-height: 27px;
			}
		}
	}
	#main-slider.shop-05-banner {
		.slider-content {
			h1 {
				font-size: $fs-70;
				line-height: $l-height-70;
			}
		}
	}
	.shop-05-top {
		.pt-100 {
			padding-top: 40px !important;
		}
	}
	.fish-about {
		&:before {
			background-position: -200px;
			background-size: 140%;
		}
	}
	.fish-gallery {
		.section-title {
			h2 {
				font-size: $fs-40;
				line-height: $l-height-70;
			}
		}
		.owl-carousel {
			.owl-nav {
				left: -280px;
				bottom: 0;
				left: 0;
			}
		}
	}
	.hotel-banner-checkout {
		.checkout {
			.checkout-name {
				h3 {
					font-size: $fs-14;
				}
			}
			.checkout-date {
				.button {
					span {
						top: 20%;
					}
				}
			}
		}
	}
	.pizza-feature-content {
		padding: 70px 30px;
	}
	.burger-banner {
		h1 {
			font-size: $fs-70;
			line-height: $l-height-70;
		}
		p {
			font-size: $fs-24;
		}
		&:hover {
			.burger-banner-ani {
				.burger-item-05 {
					top: 185px;
				}
				.burger-item-04 {
					top: 170px;
				}
				.burger-item-03 {
					top: 110px;
				}
				.burger-item-02 {
					top: 95px;
				}
			}
		}
	}
	.burger-banner-ani {
		width: 370px;
	}
	.burger-item-06 {
		top: 260px;
	}
	.burger-item-05 {
		top: 210px;
	}
	.burger-item-04 {
		top: 195px;
	}
	.burger-item-03 {
		top: 140px;
	}
	.burger-item-02 {
		top: 135px;
	}
	.burger-item-01 {
		top: 25px;
	}
	.skateboard-banner {
		.slider-content-image {
			img {
				height: 570px;
			}
		}
	}
	.action-box.gray-bg {
		a.button {
			right: 0;
		}
	}
	.action-box.black-bg {
		a.button {
			right: 0;
		}
	}
	.action-box.theme-bg {
		a.button {
			right: 0;
		}
	}
	.action-box.action-box-border {
		a.button {
			right: 0;
		}
	}
	.agency-feature {
		h3 {
			font-size: $fs-22;
		}
	}
	.plumber-banner {
		.slider-content-image {
			img {
				height: 450px;
			}
		}
	}
	.services-text-box-black {
		padding: 10px !important;
	}
	.phone-holder {
		width: 280px;
	}
	.highlights-phone.wht {
		.phone-holder {
			#fon {
				background-size: 100%;
			}
		}
	}
	.search-no-result {
		.bg-title {
			h2 {
				font-size: 350px;
			}
		}
	}
	.barber-title {
		span {
			font-size: 130px;
		}
	}
	.choose-us-bg {
		padding: 80px 20px;
	}
	.tattoo-banner {
		h1 {
			font-size: $fs-60;
			line-height: 80px;
		}
	}
	.marketing-step {
		.step-box {
			width: 180px;
			height: 180px;
			margin: 0 auto;
			h4 {
				line-height: 180px;
			}
		}
	}
	.business-banner {
		p {
			padding: 0 160px;
		}
	}
	.business-banner-03 {
		h1 {
			font-size: $fs-80;
			line-height: 80px;
		}
	}
	.business-video {
		.business-video-box {
			.vertical-align {
				padding: 50px;
			}
		}
	}
	.business-banner-04 {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-40;
		}
	}


}

@media only screen and (min-width: 992px) and (max-width: 1500px) {
	.vertical-header {
		.container {
			max-width: auto;
		}
	}

}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.container {
		max-width: 94%;
	}
	.feature-step-2 {
		.feature-step-2-title-2-right {
			h2 {
				padding-bottom: 5px;
				margin-bottom: 5px;
			}
			h3 {
				margin-bottom: 5px;
			}
			p {
				line-height: $l-height-20;
			}
		}
	}
	.listing-banner {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
	}
	.listing-places {
		padding-bottom: 230px;
	}


}


@media (max-width: 1025px) {
	/*error*/
	/*blog timeline*/
	/*process*/
	/*medical*/
	/*************************************
             v1.0.2
**************************************/
	/*************************************
             v1.0.6
**************************************/
	/*************************************
             v1.0.7
**************************************/
	/*************************************
             v1.0.9
**************************************/
	/*************************************
             v1.2
**************************************/
	/*************************************
             v1.3
**************************************/
	/*************************************
             v3.0
**************************************/
	.mega-menu {
		.menu-links {
			>li {
				>a {
					padding: 10px 8px;
				}
			}
		}
	}
	.portfolio-title {
		padding: 30px 30px 30px;
	}
	.error-search-box {
		width: 100%;
		text-align: center;
	}
	.clients-logo.line {
		width: 28.3333%;
		img {
			width: 90%;
		}
	}
	.blog {
		.timeline {
			>li {
				>.timeline-badge {
					right: -66px;
				}
			}
			>li.timeline-inverted {
				>.timeline-badge {
					left: -63px;
				}
			}
		}
	}
	.deal-banner {
		.caption {
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
	.action-box {
		p {
			font-size: $fs-13;
		}
		h3 {
			padding-right: 230px;
		}
	}
	.medical-tab {
		.nav.nav-tabs {
			li {
				a {
					padding: 12px 10px;
				}
			}
		}
	}
	.membership-pricing-table {
		table {
			tr {
				&:first-child {
					th {
						.pricing-plan-price {
							font-size: $fs-32;
						}
					}
				}
			}
		}
	}
	.awesome-features {
		.img-side {
			img {
				top: 13%;
			}
		}
	}
	.footer-social-big {
		ul {
			li {
				width: 66px;
			}
		}
	}
	.footer {
		.footer-social {
			li {
				padding: 0 1px;
			}
		}
		ul.list-inline-item.text-left {
			li {
				padding: 0 1px;
			}
		}
	}
	.login-bg {
		.login-social {
			li {
				a.fb {
					padding: 12px 14px;
				}
			}
		}
	}
	.isotope-filters {
		button+button {
			margin-left: 2px;
		}
	}
	.happy-clients {
		.nav-tabs {
			li {
				padding: 0 2px;
			}
		}
	}
	.header.fullWidth {
		.container-fluid {
			padding: 0 15px;
		}
	}
	.movie-banner {
		.slider-content {
			.banner-popup {
				width: 100%;
			}
		}
		.countdown.small {
			margin: 0;
		}
		.countdown.round.small {
			width: 90px;
			height: 90px;
		}
		.countdown {
			p {
				font-size: $fs-13;
			}
		}
	}
	.law-timeline {
		.timeline-centered {
			&:before {
				left: 56px;
			}
		}
	}
	.error-05 {
		.error-bottom {
			.board {
				left: 264px;
			}
		}
	}
	.blockquote-section {
		.action-box {
			h3 {
				padding: 0;
			}
		}
	}
	#main-slider.shop-06-banner {
		.slider-content {
			.slider {
				width: 70%;
			}
		}
	}
	.shop-08-product {
		.container-fluid {
			padding: 0 15px;
		}
	}
	.fish-banner {
		.slider-content {
			.fish-banner-image {
				img {
					height: 340px;
				}
			}
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
			}
			p {
				font-size: $fs-16;
			}
		}
		.fish-banner-content {
			padding-top: 50px;
		}
	}
	.fish-gallery {
		.section-title {
			h2 {
				font-size: $fs-40;
				line-height: 60px;
			}
		}
	}
	.hotel-banner-checkout {
		.checkout {
			.checkout-name {
				padding: 20px;
				h3 {
					font-size: $fs-14;
					margin-bottom: 0 !important;
				}
			}
			.checkout-date {
				.button {
					span {
						top: inherit;
						line-height: 54px;
					}
				}
			}
		}
	}
	.awards-bg {
		padding: 40px 20px;
	}
	.certi-bg {
		padding: 40px 20px;
	}
	.skateboard-box {
		h2 {
			font-size: 27px;
		}
	}
	.bitcoin-banner {
		h1 {
			font-size: $fs-70;
			line-height: $l-height-70;
		}
		h3 {
			margin: 0 15px;
			font-size: $fs-24;
		}
	}
	.bitcoin-custom-content {
		.counter {
			label {
				font-size: $fs-14;
			}
			span.icon {
				font-size: $fs-40;
			}
		}
	}
	.action-box-button .button {
		position: static;
		display: block;
		margin: 20px auto 0 auto;
		transform: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 999px) {
	.container {
		max-width: 96%;
	}

}

@media(max-width:991px) {

	.sm-mt-0 {
		margin-top: 0 !important;
	}
	.sm-mt-10 {
		margin-top: 10px !important;
	}
	.sm-mt-15 {
		margin-top: 15px !important;
	}
	.sm-mt-20 {
		margin-top: 20px !important;
	}
	.sm-mt-30 {
		margin-top: 30px !important;
	}
	.sm-mt-40 {
		margin-top: 40px !important;
	}
	.sm-mt-50 {
		margin-top: 50px !important;
	}
	.sm-mr-0 {
		margin-right: 0 !important;
	}
	.sm-mr-10 {
		margin-right: 10px !important;
	}
	.sm-mr-15 {
		margin-right: 15px !important;
	}
	.sm-mr-20 {
		margin-right: 20px !important;
	}
	.sm-mr-30 {
		margin-right: 30px !important;
	}
	.sm-mr-40 {
		margin-right: 40px !important;
	}
	.sm-mr-50 {
		margin-right: 50px !important;
	}
	.sm-mb-0 {
		margin-bottom: 0 !important;
	}
	.sm-mb-10 {
		margin-bottom: 10px !important;
	}
	.sm-mb-15 {
		margin-bottom: 15px !important;
	}
	.sm-mb-20 {
		margin-bottom: 20px !important;
	}
	.sm-mb-30 {
		margin-bottom: 30px !important;
	}
	.sm-mb-40 {
		margin-bottom: 40px !important;
	}
	.sm-mb-50 {
		margin-bottom: 50px !important;
	}
	.sm-ml-0 {
		margin-left: 0 !important;
	}
	.sm-ml-10 {
		margin-left: 10px !important;
	}
	.sm-ml-15 {
		margin-left: 15px !important;
	}
	.sm-ml-20 {
		margin-left: 20px !important;
	}
	.sm-ml-30 {
		margin-left: 30px !important;
	}
	.sm-ml-40 {
		margin-left: 40px !important;
	}
	.sm-ml-50 {
		margin-left: 50px !important;
	}
	.sm-pt-0 {
		padding-top: 0 !important;
	}
	.sm-pt-10 {
		padding-top: 10px !important;
	}
	.sm-pt-15 {
		padding-top: 15px !important;
	}
	.sm-pt-20 {
		padding-top: 20px !important;
	}
	.sm-pt-30 {
		padding-top: 30px !important;
	}
	.sm-pt-40 {
		padding-top: 40px !important;
	}
	.sm-pt-50 {
		padding-top: 50px !important;
	}
	.sm-pr-0 {
		padding-right: 0 !important;
	}
	.sm-pr-10 {
		padding-right: 10px !important;
	}
	.sm-pr-15 {
		padding-right: 15px !important;
	}
	.sm-pr-20 {
		padding-right: 20px !important;
	}
	.sm-pr-30 {
		padding-right: 30px !important;
	}
	.sm-pr-40 {
		padding-right: 40px !important;
	}
	.sm-pr-50 {
		padding-right: 50px !important;
	}
	.sm-pb-0 {
		padding-bottom: 0 !important;
	}
	.sm-pb-10 {
		padding-bottom: 10px !important;
	}
	.sm-pb-15 {
		padding-bottom: 15px !important;
	}
	.sm-pb-20 {
		padding-bottom: 20px !important;
	}
	.sm-pb-30 {
		padding-bottom: 30px !important;
	}
	.sm-pb-40 {
		padding-bottom: 40px !important;
	}
	.sm-pb-50 {
		padding-bottom: 50px !important;
	}
	.sm-pl-0 {
		padding-left: 0 !important;
	}
	.sm-pl-10 {
		padding-left: 10px !important;
	}
	.sm-pl-15 {
		padding-left: 15px !important;
	}
	.sm-pl-20 {
		padding-left: 20px !important;
	}
	.sm-pl-30 {
		padding-left: 30px !important;
	}
	.sm-pl-40 {
		padding-left: 40px !important;
	}
	.sm-pl-50 {
		padding-left: 50px !important;
	}
	.row-eq-height {
		display: block;
	}
	h2 {
		font-size: 25px;
		line-height: 35px;
	}
	h3 {
		font-size: $fs-20;
	}
	h4 {
		font-size: 17px;
	}
	h5 {
		font-size: $fs-15;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.default {
		position: relative;
		background: $black;
	}

	.mobile-version{
		height: 0;
	}

	.mega-menu {
		min-height: 60px;
		.menu-logo {
			>li {
				>a {
					padding: 0;
				}
			}
			padding: 10px 0  !important;
			img {
				height: 40px;
				margin: 0px 0;
			}
			img.logo-big {
				height: 36px;
			}
		}
		.menu-links {
			overflow: auto;
			padding: 10px;
			>li.active {
				.drop-down-multilevel {
					a {
						&:hover {
							color: $black;
						}
					}
				}
			}
			>li {
				>a {
					line-height: normal;
				}
			}
		}
		.drop-down-multilevel {
			li {
				&:hover {
					>a {
						i.fa {
							color: $black;
						}
					}
				}
			}
			a {
				display: block;
				padding: 10px 20px;
			}
			border-top: 0px !important;
			width: inherit;
			padding: 0;
		}
		.mobileTriggerButton {
			z-index: 1;
		}
		.drop-down {
			border-top: 0px !important;
			padding: 0;
		}
		.drop-down-tab-bar {
			border-top: 0px !important;
			padding: 0;
		}
		>section.menu-list-items {
			height: 60px !important;
		}
		.menu-mobile-collapse-trigger {
			height: 40px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 9999;
		}
	}
	.search-cart {
		span.icon {
			line-height: $l-height-50;
		}
		i.icon {
			line-height: $l-height-60 !important;
		}
		display: inline-block;
		position: absolute;
		right: 60px;
		top: 0;
		z-index: 999;
		.shpping-cart {
			vertical-align: top;
		}
		.search {
			+ {
				.shpping-cart {
					padding-left: 5px;
				}
			}
		}
		.side-menu-icon {
			vertical-align: top;
			padding-top: 20px;
		}
	}
	.fancy {
		.mega-menu {
			>section.menu-list-items {
				.menu-links {
					float: none;
					position: absolute;
					width: 100%;
					top: 100%;
				}
				height: 70px !important;
			}
		}
	}
	.header.fancy {
		.mega-menu {
			>section.menu-list-items {
				box-shadow: none;
				padding: 0;
			}
			.menu-mobile-collapse-trigger {
				&:before {
					background: $black;
				}
				&:after {
					background: $black;
				}
				span {
					background: $black;
				}
			}
			.menu-logo {
				padding: 16px 0px;
			}
			.menu-links {
				>li {
					>a {
						line-height: $l-height-20;
					}
				}
			}
		}
		.menu {
			position: relative;
			top: 0;
		}
		.topbar {
			+ {
				.menu {
					top: 0px;
				}
			}
			padding: 10px 0px 10px;
		}
		.search-cart {
			span.icon {
				line-height: 60px;
			}
			i.icon {
				line-height: 74px;
			}
			.search {
				height: 70px;
				padding-right: 0;
			}
		}
		.mega-menu.mobileTopFixed {
			section.menu-list-items {
				padding: 0 10px;
				box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
			}
		}
		.mega-menu.desktopTopFixed {
			.menu-logo {
				padding: 15px 0;
			}
		}
		.search {
			.search-btn {
				margin-top: 23px;
			}
		}
	}
	.header.light {
		.mega-menu {
			.menu-mobile-collapse-trigger {
				&:before {
					background: $black;
				}
				&:after {
					background: $black;
				}
				span {
					background: $black;
				}
			}
		}
		.mega-menu.mobileTopFixed {
			.menu-list-items {
				background: $white;
				box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}
	.header.logo-center {
		.mega-menu {
			min-height: 60px;
		}
		.menu-bar {
			border: 0px;
		}
		.mega-menu.desktopTopFixed {
			.menu-bar {
				width: 100%;
			}
		}
	}
	.header.left-menu {
		.menu-links {
			padding-left: 0;
		}
	}
	.header.transparent {
		position: relative;
		background: $black;
	}
	header.one-page {
		position: relative;
		background: $black;
	}
	.navbar {
		margin-bottom: 0;
		border-radius: 0;
		.navbar-brand {
			padding: 0;
			padding: 15px 0;
			img {
				height: 28px;
			}
		}
	}
	.navbar-header {
		float: none;
	}
	.navbar-left {
		float: none !important;
	}
	.navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
		margin-right: 0;
		margin-top: 14px;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
		padding-left: 22px;
		box-shadow: none;
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-nav {
		float: none !important;
		margin-top: 7.5px;
		float: none;
		>li {
			float: none;
			>a {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
	}
	.collapse.in {
		display: block !important;
	}
	.navbar-inverse {
		.navbar-collapse {
			background: $black;
			border: 0;
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
		}
		.navbar-form {
			background: $black;
			border: 0;
			position: absolute;
			width: 100%;
			left: 0;
			right: 0;
		}
		.navbar-nav {
			>li {
				padding: 15px 15px;
			}
		}
		.navbar-toggle {
			border: 0;
			&:focus {
				background: transparent;
			}
			&:hover {
				background: transparent;
			}
		}
	}
	.header.burger {
		padding: 10px 10px;
		position: relative;
		background: $white;
		.logo {
			img {
				height: 30px;
				margin-top: 10px;
			}
		}
		span.text-white {
			color: $black !important;
		}
	}
	.vertical-header {
		padding-left: 0;
		.header {
			left: inherit;
			padding-bottom: 150px;
		}
		.container-fluid {
			padding: 0 15px;
		}
	}
	.vertical-menu {
		.navbar-default {
			.navbar-nav {
				>li {
					>a {
						padding: 10px 15px 10px 40px;
					}
				}
			}
		}
		.navbar-nav {
			.open {
				.dropdown-menu {
					position: relative;
					background-color: #f4f5f6;
				}
			}
			>li {
				>.dropdown-menu {
					margin-bottom: 10px;
				}
			}
			margin: 0px;
		}
		.open {
			>.dropdown-menu {
				display: inline-block;
			}
		}
		.content-scroller {
			margin-left: 0px;
			padding-top: 50px;
		}
		.navbar-toggle {
			display: none;
		}
		.navbar-collapse.collapse {
			display: block !important;
			background: transparent;
		}
		.menu {
			.mega-menu {
				.menu-links {
					background: transparent;
					>li {
						>a {
							padding: 15px 23px;
							color: $white;
						}
						border-color: rgba(255, 255, 255, 0.2);
					}
				}
			}
		}
		.mega-menu {
			.menu-links {
				display: block !important;
			}
			.menu-mobile-collapse-trigger {
				display: none;
			}
		}
		.social-icons {
			li {
				float: none;
				display: inline-block;
				margin: 0 3px 8px 0;
			}
		}
		.menu-widgets {
			bottom: 0;
		}
	}
	#left-header {
		position: fixed;
		left: -230px;
		top: 0;
		width: 230px;
		height: 100%;
		z-index: 9999;
	}
	.but {
		span {
			position: absolute;
			right: 15px;
			top: 20px;
			font-size: $fs-24;
			color: #fff;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			-ms-transform: rotate(0deg);
			transform: rotate(0deg);
			transition: all 0.5s ease-in-out;
			-webkit-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
		}
	}
	.but.active {
		span {
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}
	.menu-responsive {
		b {
			color: $theme-color;
			font-size: $fs-18;
			text-transform: uppercase;
		}
		height: 60px;
		position: fixed;
		background: #000000;
		z-index: 9999;
		width: 100%;
		padding: 14px 15px;
		display: block;
	}
	.header.fancy.without-topbar {
		.menu {
			top: 0;
		}
	}
	.search {
		.search-btn {
			line-height: $l-height-30;
			margin-top: 16px;
		}
		.search-input {
			input {
				width: 100%;
			}
		}
	}
	.logo-center {
		.mega-menu {
			.menu-logo {
				padding: 14px 0;
			}
		}
		.mega-menu.desktopTopFixed {
			.menu-list-items {
				.menu-links {
					>li {
						>a {
							padding: 0px 8px;
							line-height: $l-height-40;
						}
					}
				}
			}
		}
	}
	.header.fullWidth {
		.container-fluid {
			padding: 0 20px;
		}
	}
	.header {
		.mega-menu.mobileTopFixed {
			.menu-list-items {
				background: $black;
			}
		}
	}
	.mega-menu.mobileTopFixed {
		.menu-logo {
			padding: 10px 0;
		}
		.search-cart {
			.shpping-cart {
				vertical-align: inherit;
			}
		}
	}
	.container-fluid {
		>.navbar-collapse {
			margin: 0;
		}
		>.navbar-header {
			margin: 0;
		}
	}
	.container {
		>.navbar-collapse {
			margin: 0;
		}
		>.navbar-header {
			margin: 0;
		}
	}
	.header.vertical-menu {
		.mega-menu.mobileTopFixed {
			display: none;
		}
	}
	.header.text-dark {
		.mega-menu {
			>section.menu-list-items {
				.menu-links {
					background: $black;
				}
			}
			.menu-links {
				>li {
					>a {
						color: $white;
					}
					border-color: rgba(255,255,255,0.1);
				}
			}
			.drop-down-multilevel {
				.drop-down-multilevel {
					border-color: rgba(255,255,255,0.1);
				}
			}
		}
		.mega-menu.desktopTopFixed {
			.menu-list-items {
				background: $black;
			}
		}
	}
	.header.magazine-header {
		.mega-menu.desktopTopFixed {
			.menu-logo {
				padding: 14px 0;
				width: 100%;
			}
		}
	}
	.header.logo-center.magazine-header {
		.mega-menu {
			.menu-links {
				>li {
					>a {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}
		.add-banner {
			display: none;
		}
		.menu-logo {
			width: inherit;
			margin-right: inherit;
			float: none;
		}
	}
	.st-effect-3 {
		.header.default.fullWidth {
			.search-cart {
				right: 50px;
				top: 3px;
			}
		}
	}
	.page-title {
		padding-top: 100px;
		text-align: left;
		.page-breadcrumb {
			float: none;
			text-align: left;
			width: 100%;
		}
		.page-title-name {
			width: 100%;
		}
	}
	.page-title.right {
		.page-title-name {
			text-align: right;
			float: none;
			width: 100%;
		}
		.page-breadcrumb {
			float: none;
			text-align: right;
		}
	}
	.portfolio-title.section-title {
		margin-bottom: 0;
	}
	.happy-clients {
		.tab-content {
			padding-bottom: 60px;
		}
		.mt-60 {
			margin-top: 20px !important;
		}
		.col-md-6.mt-60 {
			margin-top: 0 !important;
		}
	}
	.popup-video {
		margin-bottom: 30px;
	}
	.custom-content {
		padding: 30px;
	}
	.custom-content-3-image {
		display: none;
	}
	.inner-intro {
		padding-top: 120px;
	}
	.page-two-sidebar {
		.page-content {
			margin-top: 40px;
		}
	}
	.clients-logo.line {
		width: 32.3333%;
		float: none;
		display: inline-block;
		img {
			width: 100%;
		}
	}
	.contact.contact-2 {
		a.button {
			margin-bottom: 30px;
		}
	}
	.contact-3 {
		.contact-add {
			min-height: 310px;
		}
		.contact-3-info {
			height: auto;
		}
		.contact-map {
			iframe {
				height: 400px;
			}
		}
		.map-side {
			height: auto;
			position: inherit;
		}
		.g-map {
			height: 300px;
			padding: 0;
		}
	}
	.error-block {
		background-size: 90%;
		p {
			font-size: $fs-26;
		}
		.error-text {
			h2 {
				font-size: 260px;
			}
		}
	}
	.error-search-box {
		p {
			font-size: 19px;
			line-height: 35px;
		}
	}
	.map-side {
		position: inherit;
	}
	.text-right.action-box-button {
		text-align: left;
	}
	.maintenance-main {
		h1.mb-50 {
			font-size: $fs-34;
			margin-bottom: 10px !important;
		}
		i {
			font-size: $fs-60;
		}
	}
	.maintenance-form {
		width: 100%;
	}
	.maintenance-progress-bar {
		span {
			font-size: $fs-24;
		}
	}
	.feature-text.box {
		padding: 20px 10px;
		p {
			font-size: $fs-14;
		}
	}
	.feature-text.fill-box {
		padding: 20px 10px;
	}
	.feature-box-content {
		padding: 20px 15px 0;
	}
	.feature-box {
		a {
			padding: 0 15px;
		}
	}
	.skill-title {
		font-size: $fs-15;
	}
	.counter {
		.timer {
			font-size: $fs-38;
		}
		label {
			font-size: $fs-14;
		}
	}
	.team-3-box {
		.team-overlay {
			padding: 30px 15px 0;
		}
		padding: 10px;
	}
	.menu-bar {
		float: none;
	}
	.search-active {
		.search-cart {
			left: 0;
			width: 100%;
		}
		.mega-menu {
			.menu-mobile-collapse-trigger {
				z-index: 9;
			}
		}
	}
	.footer {
		p {
			font-size: $fs-13;
		}
		.divider {
			display: none;
		}
		#logo-footer {
			height: 40px;
		}
	}
	.feature-text {
		p {
			font-size: $fs-14;
		}
	}
	.accordion.accordion-2 {
		.acd-group.acd-active {
			.acd-heading {
				font-size: $fs-22;
			}
		}
	}
	.split-section {
		.img-side {
			display: none;
		}
	}
	.conference-split.split-section {
		.img-side {
			display: block;
		}
	}
	.our-service {
		padding-bottom: 60px;
	}
	.testimonial.boxed {
		padding: 50px 30px 75px;
	}
	.feature-step-2 {
		.feature-step-2-title-left {
			h1 {
				font-size: $fs-80;
			}
		}
	}
	.services-text-box-blue {
		padding: 50px 30px;
	}
	.services-text-box-black {
		padding: 50px 30px;
	}
	.highlights-phone {
		margin-top: 20px;
	}
	.mobile-slider {
		h5 {
			font-size: $fs-14;
		}
	}
	.service-blog {
		b {
			right: 0;
			font-size: 250px;
		}
		margin-bottom: 40px;
	}
	.service-blog.left {
		b {
			left: 0;
		}
	}
	.blog.blog-grid-3-column {
		.social.float-right {
			float: none;
			margin-top: 10px;
			display: block;
			width: 100%;
		}
	}
	.masonry.columns-3 {
		.masonry-item {
			width: 50%;
			width: 50%;
		}
	}
	.blog.timeline-sidebar {
		.timeline {
			li.timeline-inverted {
				.timeline-badge {
					left: -70px;
				}
			}
		}
	}
	.isotope.columns-3 {
		.grid-item {
			width: 50%;
		}
	}
	.isotope.columns-4 {
		.grid-item {
			width: 50%;
		}
	}
	.isotope.columns-5 {
		.grid-item {
			width: 50%;
		}
	}
	.masonry.columns-4 {
		.masonry-item {
			width: 50%;
		}
	}
	.masonry.columns-5 {
		.masonry-item {
			width: 50%;
		}
	}
	.port-post-social.float-right {
		float: none !important;
	}
	.process.left {
		.process-content {
			padding-left: 50px;
		}
	}
	img.bottom-img {
		display: none;
	}
	.resume-contact {
		.g-map {
			height: 300px !important;
			padding-top: 40%;
		}
		.contact-form-main {
			padding: 30px 15px;
		}
	}
	.contact-4 {
		.map-side {
			height: 300px !important;
		}
	}
	img#logo-footer {
		height: 30px;
	}
	.bottom-nav {
		.owl-nav {
			.owl-next {
				right: 44%;
			}
			.owl-prev {
				left: 44%;
			}
		}
	}
	.blockquote-right {
		margin: 0;
	}
	.buttons {
		.button {
			margin-bottom: 10px;
		}
		button {
			margin-bottom: 4px;
		}
	}
	.button {
		+ {
			.button {
				margin-left: 3px;
			}
		}
	}
	.countdown {
		margin: 0px 10px;
	}
	.countdown.medium {
		margin: 0 15px;
		span {
			font-size: $fs-50;
		}
	}
	.countdown.large {
		margin: 0 10px;
		span {
			font-size: $fs-60;
		}
		p {
			font-size: $fs-18;
		}
	}
	.countdown.round.medium {
		width: 140px;
		height: 140px;
		span {
			line-height: 58px;
		}
	}
	.countdown.round.large {
		width: 150px;
		height: 150px;
		span {
			line-height: 62px;
		}
	}
	.action-box {
		a.button {
			position: relative;
			top: inherit;
			margin-top: 20px;
		}
		h3 {
			padding-right: 0px;
		}
		a.popup-youtube {
			position: relative;
			top: inherit;
			margin-top: 20px;
			right: inherit;
		}
	}
	.action-box.theme-bg {
		a.button {
			right: inherit;
		}
	}
	.action-box.black-bg {
		a.button {
			right: inherit;
		}
	}
	.action-box.gray-bg {
		a.button {
			right: inherit;
		}
	}
	.agency-about {
		padding-bottom: 60px !important;
	}
	.our-sercive1 {
		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
	}
	.special-feature {
		top: 60px;
		margin-bottom: 0px;
		.row-eq-height.no-gutter {
			margin-top: 40px;
		}
		.row-eq-height {
			display: flex;
		}
		.feature-text {
			padding: 30px 20px;
			.feature-icon {
				span {
					padding-left: 0;
					margin-bottom: 10px;
				}
				i {
					padding-left: 0;
					margin-bottom: 10px;
				}
			}
		}
	}
	.page-section-1-pt {
		padding-top: 70px;
	}
	.page-section-1-pb {
		padding-bottom: 70px;
	}
	.page-section-1-ptb {
		padding: 70px 0;
	}
	.gym-about {
		background: $white !important;
	}
	.appointment {
		a.float-left {
			float: none !important;
		}
		margin-top: 0px;
		.appointment-bg {
			margin-top: 50px;
		}
	}
	.popup-video-banner {
		h1 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.video-background-banner {
		.slider-content {
			width: 100%;
		}
		h1 {
			font-size: $fs-60;
			line-height: 60px;
		}
	}
	.shop-split.split-section {
		padding: 0px;
		.img-side {
			display: block;
			right: inherit;
			padding: 0;
			position: relative !important;
			top: inherit;
			height: 100%;
			overflow: hidden;
			z-index: 100;
		}
		.img-holder {
			position: relative;
			top: inherit;
			width: inherit;
			height: inherit;
			padding: 130px;
		}
		.img-side.img-right {
			right: inherit;
		}
		.img-side.img-left {
			left: inherit;
		}
	}
	.shop-split-content {
		padding: 40px 0;
	}
	.add-banner-2 {
		.add-banner-content {
			padding: 60px 20px;
			h2 {
				font-size: $fs-28;
				line-height: $l-height-28;
			}
			h3 {
				font-size: $fs-26;
				line-height: 26px;
			}
		}
	}
	.add-banner-2.text-center {
		.add-banner-content {
			h2 {
				font-size: $fs-26;
				line-height: 29px;
			}
		}
	}
	.portfolio-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
		}
	}
	.personal-typer-banner {
		h1 {
			font-size: $fs-70;
			line-height: $l-height-70;
			span {
				font-size: 35px;
			}
		}
	}
	.deal-banner.maintenance-progress-bar {
		img {
			width: 100%;
		}
	}
	.page-section-ptb.mt-150 {
		margin-top: 0 !important;
	}
	.pricing-content {
		width: 90%;
		.pricing-table-list {
			ul {
				li {
					font-size: $fs-12;
				}
			}
		}
	}
	.process-list {
		padding-left: 30px;
		padding-right: 30px;
		.col-sm-12 {
			padding: 0;
		}
	}
	.interior-action-box {
		.action-box {
			margin-top: 20px;
		}
	}
	.pricing-table.active {
		.pricing-top {
			a.button {
				padding: 12px 40px;
				font-size: $fs-15;
			}
		}
	}
	.membership-pricing-table {
		table {
			tr {
				&:first-child {
					th {
						.pricing-plan-price {
							font-size: $fs-22;
						}
						.pricing-plan-name {
							font-size: $fs-16;
						}
					}
				}
			}
		}
	}
	.action-box.full-width {
		a.button {
			right: 0;
		}
	}
	.team.team-round {
		.team-description {
			padding-bottom: 0;
		}
	}
	#rev_slider_16_1_forcefullwidth {
		.tp-parallax-wrap {
			margin-top: 0px;
		}
	}
	.blog-box.blog-2 {
		.button.icon-color {
			margin-top: 10px;
			float: none !important;
		}
		.blog-info {
			padding: 10px;
		}
	}
	.medical-tab {
		.tab {
			.nav.nav-tabs {
				li {
					a {
						font-size: $fs-14;
						padding: 12px 6px;
					}
				}
			}
		}
	}
	.contact-2 {
		.g-map {
			padding-top: 30%;
		}
	}
	.single-portfolio-post {
		.port-information {
			.social.float-right {
				flex: none !important;
				display: inline-block;
				width: 100%;
			}
		}
	}
	.js-video.big {
		padding-top: 18px;
	}
	.typer-banner {
		h1 {
			font-size: $fs-70;
			line-height: $l-height-70;
		}
	}
	ul.page-breadcrumb {
		padding-top: 10px;
	}
	.blog {
		.timeline {
			>li {
				>.timeline-badge {
					right: -56px;
				}
			}
			>li.timeline-inverted {
				>.timeline-badge {
					left: -53px;
				}
			}
		}
	}
	.action-box.action-box-border {
		a.button {
			left: 0;
		}
	}
	.port-information {
		img {
			width: 100%;
		}
	}
	.text-left.mt-100 {
		margin-top: 0 !important;
	}
	.portfolio-03-about {
		padding-left: 0;
	}
	.slider-parallax.typer-banner.business {
		p {
			padding: 0;
		}
	}
	.blockquote-section {
		blockquote.quote {
			padding-top: 50px;
			&:before {
				line-height: $l-height-70;
			}
		}
		.blockquote-section-left {
			margin-left: 0;
		}
		.blockquote-section-right {
			margin-left: 0;
		}
		.action-box {
			padding: 40px 20px;
		}
	}
	.slider-parallax.banner-personal {
		.slider-content {
			span {
				font-size: 90px;
				line-height: 90px;
			}
			h1 {
				font-size: $fs-80;
				line-height: 80px;
				margin-top: -40px;
				strong {
					font-size: 96px;
					line-height: $l-height-70;
				}
			}
		}
	}
	.christmas-team {
		margin-top: 0 !important;
	}
	.christmas-event {
		.blog-date {
			text-align: left;
		}
		.button.mt-60 {
			margin-top: 20px !important;
		}
		.blog-image {
			width: 100px;
		}
	}
	.christmas-price {
		.mt-100.mb-100 {
			margin: 0 !important;
		}
	}
	.christmas {
		.coming-soon {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
				margin-bottom: 20px;
			}
		}
	}
	.timer-banner {
		.slider-content-middle {
			top: 50%;
		}
		.slider-content {
			h1 {
				font-size: $fs-50;
				line-height: $l-height-50;
				margin-bottom: 20px;
			}
		}
	}
	.conference-about-content-right {
		margin-left: 0px;
		padding: 20px 0 0;
	}
	.conference-about-content-left {
		padding: 50px 0 30px 0px;
	}
	.conference-about {
		img {
			width: 100%;
		}
	}
	.conference-split-content {
		padding: 0;
	}
	.conference-video {
		.mt-100.mb-100 {
			margin: 0 !important;
		}
	}
	.one-page.light {
		.navbar-inverse {
			.navbar-toggle {
				.icon-bar {
					background: $black;
				}
			}
		}
	}
	.architecture-about {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		.mt-60.mb-60 {
			margin: 0 !important;
		}
	}
	.architecture-portfolio {
		.text-right {
			text-align: left;
			margin-bottom: 30px;
		}
		.isotope-filters {
			text-align: left;
		}
		.mb-40 {
			margin-bottom: 0px !important;
		}
	}
	.agency-banner {
		.slider-content {
			padding: 0 0px 0 30px;
			h1 {
				padding: 50px 20px 20px 20px;
			}
		}
	}
	.movie-banner {
		.slider-content-middle {
			top: 50%;
		}
	}
	.one-page.transparent {
		background: $black;
	}
	.one-page {
		.social-icons {
			padding: 10px 0;
			right: 60px;
		}
	}
	.one-page.nav-center {
		.navbar-collapse.collapse {
			text-align: left;
		}
	}
	.law-banner {
		.slider-content {
			padding: 0;
		}
	}
	.law-timeline {
		.timeline-centered {
			&:before {
				display: none;
			}
			.timeline-entry {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.timeline-label {
			h3 {
				line-height: 12px;
			}
		}
	}
	.law-contact {
		padding: 0;
	}
	.one-page.dark-text {
		.navbar-inverse {
			.navbar-nav {
				>li {
					>a {
						color: $white;
					}
				}
			}
		}
	}
	.agency-02-about-content-right {
		margin-left: 0;
	}
	.agency-02-action-box {
		.action-box {
			margin-top: 40px;
		}
		.action-box.white-bg {
			padding: 50px 20px;
		}
		.popup-video-image {
			a {
				i {
					position: relative;
					top: 20px;
					transform: inherit;
				}
			}
			&:before {
				background: transparent;
			}
		}
	}
	.agency-feature {
		h3 {
			line-height: 60px;
			font-size: $fs-16;
		}
	}
	.portfolio-parallax-banner {
		p {
			padding: 0;
		}
		h2 {
			font-size: $fs-60;
			line-height: 60px;
		}
	}
	.agency-02-about {
		h3 {
			&:before {
				display: none;
				background: none;
			}
		}
	}
	.coming-soon-middle {
		position: inherit;
		top: inherit;
		transform: inherit;
		padding: 50px 20px;
	}
	.coming-soon-aerial {
		.container-fluid {
			padding: 30px;
		}
	}
	.coming-soon-big {
		.countdown.medium {
			span {
				font-size: $fs-80;
				line-height: 80px;
			}
			p {
				font-size: $fs-20;
				line-height: $l-height-20;
			}
		}
	}
	.error-05 {
		.button-home {
			display: block;
		}
		.back-to-home {
			display: none;
		}
		.penguin {
			display: none;
		}
		.error-bottom {
			.tree {
				display: none;
			}
		}
	}
	footer.error-03 {
		.social-icons {
			float: none;
			ul {
				display: inline-block;
				text-align: center;
			}
		}
	}
	footer.error-04 {
		.social-icons {
			float: none;
			ul {
				display: inline-block;
				text-align: center;
			}
		}
	}
	.coming-soon-effects {
		.coming-soon {
			h1 {
				font-size: $fs-40;
			}
		}
	}
	.about-05-split.page-section-ptb {
		padding: 0;
	}
	.login-fancy {
		padding: 40px 30px;
	}
	.login {
		.vertical-align {
			position: inherit;
			top: inherit;
			transform: inherit;
		}
		.height-100vh {
			height: inherit;
		}
	}
	.login.vertical-align {
		position: inherit;
		top: inherit;
		transform: inherit;
	}
	.login.height-100vh {
		height: inherit;
	}
	.login-box {
		.pos-bot {
			position: inherit;
			padding-bottom: 0 !important;
		}
	}
	.login-box-theme {
		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
	}
	.login-box-left {
		padding-bottom: 20px;
		.pos-bot {
			position: inherit;
			padding-bottom: 0 !important;
			margin-top: 20px;
		}
	}
	.login-14 {
		padding: 30px 40px;
		.pb-50 {
			padding-bottom: 0 !important;
		}
	}
	.login-15 {
		padding: 30px 40px;
		.pb-50 {
			padding-bottom: 0 !important;
		}
	}
	.about-mission {
		margin-top: 40px;
	}
	.about-mission-title {
		padding-bottom: 80px;
	}
	.blog-overlay {
		.blog-image {
			img {
				width: 100%;
			}
		}
	}
	#main-slider {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
			.slider-left {
				width: 70%;
			}
			.slider-right {
				width: 30%;
			}
		}
	}
	#main-slider.shop-04-banner {
		.slider-content {
			.slider {
				width: 100%;
			}
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
				margin-bottom: 10px !important;
			}
			p {
				font-size: $fs-18;
				margin: 10px 0 !important;
			}
			span {
				margin-bottom: 10px !important;
				font-size: $fs-14;
				padding: 6px 10px;
			}
			a.button {
				padding: 5px 14px;
			}
		}
	}
	.shop-05-top {
		.top-product {
			margin-top: 30px;
		}
	}
	#main-slider.shop-05-banner {
		.slider-content {
			h1 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
		}
	}
	#main-slider.shop-06-banner {
		.slider-content {
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
			}
			p {
				font-size: $fs-14;
				line-height: $l-height-20;
				margin-bottom: 20px !important;
			}
		}
	}
	.banner-shop-07 {
		span {
			font-size: $fs-80;
			line-height: $l-height-100;
		}
		h1 {
			font-size: $fs-80;
			line-height: 80px;
		}
	}
	.shop-08-product {
		.section-title.text-left {
			text-align: center;
		}
		.isotope-filters {
			text-align: center;
			float: left !important;
		}
	}
	.book-banner {
		.row.row-eq-height {
			display: flex;
			width: 100%;
		}
	}
	.plumber-banner {
		.row.row-eq-height {
			display: flex;
			width: 100%;
			.col-sm-12 {
				position: inherit;
			}
		}
		.slider-content-text {
			left: 0;
		}
		.slider-content-image {
			left: 20%;
		}
	}
	.product-feature {
		img {
			display: none;
		}
	}
	.toch-future {
		img {
			display: none;
		}
		text-align: center;
	}
	.fish-about {
		&:before {
			background: $white;
		}
	}
	.fish-gallery {
		p.text-white {
			color: $black;
			margin-top: 10px !important;
		}
		.fish-gallery-top {
			margin-top: 0;
		}
		.section-title {
			h2 {
				color: $white;
				padding-top: 40px;
			}
		}
	}
	.fish-banner {
		.slider-content {
			.fish-banner-image {
				img {
					height: inherit;
				}
			}
			h1 {
				font-size: $fs-38;
				line-height: 38px;
			}
			margin: 0 30px;
			a.button {
				padding: 0 15px;
			}
		}
		.fish-banner-image {
			margin-right: 0;
		}
	}
	.hotel-banner {
		.slider-content {
			h1 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
			p {
				font-size: $fs-20;
				line-height: $l-height-30;
			}
		}
	}
	.hotel-banner-checkout {
		.checkout {
			margin-top: 0;
			background: $dark-theme-bg;
			border-top: 0px;
			padding: 20px;
			.checkout-name {
				padding: 0;
				h3 {
					font-size: $fs-24;
				}
			}
			.checkout-date {
				margin-top: 20px;
				.button {
					span {
						line-height: 33px;
					}
				}
			}
		}
	}
	.pizza-about {
		.pos-bot {
			img {
				display: none;
			}
		}
		.pizza-about-content {
			padding-bottom: 50px;
		}
		h2 {
			font-size: $fs-30;
		}
	}
	#header.pizza-menu {
		.container-fluid {
			padding: 0 15px;
		}
		.mega-menu {
			.menu-logo {
				img {
					height: 40px;
				}
				padding: 5px 0;
			}
		}
	}
	header.one-page.light {
		.navbar-inverse {
			.navbar-collapse {
				background: $black;
			}
			.navbar-form {
				background: $black;
			}
		}
	}
	.menu-body {
		.menu-thumbnail {
			width: 100px;
			margin-bottom: 20px;
		}
	}
	.make-call {
		h2 {
			&:before {
				display: none;
			}
		}
	}
	.burger-welcome-content {
		padding-left: 0;
		padding-top: 30px;
	}
	.burger-about.page-section-ptb {
		padding-top: 0;
	}
	.burger-about-content-right {
		margin-left: 0;
	}
	.burger-custom-content {
		h2 {
			font-size: $fs-60;
			line-height: 60px;
		}
	}
	.burger-contact {
		.burger-contact-bg {
			left: 50px;
			height: 50px;
		}
	}
	.skateboard-box {
		margin-bottom: 150px;
		.skateboard-box-content {
			padding-left: 200px;
		}
	}
	.skateboard-video {
		.js-video {
			margin-bottom: 70px;
		}
	}
	.bitcoin-banner {
		.button {
			font-size: $fs-14;
			padding: 7px 15px;
			margin: 14px 0 !important;
		}
	}
	.chart-bitcoin {
		.chart-bitcoin-content {
			margin-top: 0;
			margin-bottom: 40px;
		}
	}
	.bitcoin-custom-content-02 {
		.button {
			margin-top: 30px;
		}
	}
	.skateboard-banner {
		.row.row-eq-height {
			display: flex;
			width: 100%;
			.col-sm-12 {
				position: inherit;
			}
		}
		.slider-content-text {
			left: 20px;
		}
		h1 {
			font-size: $fs-80;
			line-height: 80px;
		}
	}
	.skateboard-about {
		.about-image {
			height: 460px;
		}
	}
	.st-menu.right-side {
		.pos-bot {
			bottom: inherit;
		}
	}
	.skateboard-product {
		padding-top: 70px;
	}
	.book-counter {
		.counter {
			margin-top: 30px;
		}
	}
	.fish-company {
		padding-bottom: 40px;
	}
	.onepage-hover-01 {
		.mega-menu {
			.menu-mobile-collapse-trigger {
				&:before {
					background: $black;
				}
				&:after {
					background: $black;
				}
				span {
					background: $black;
				}
			}
		}
	}

	.service-04-video {
		padding: 0 !important;
	}
	.service-07 {
		.agency-02-about-content-right {
			margin-left: 0;
		}
	}
	.search-no-result {
		.bg-title {
			h2 {
				font-size: 270px;
			}
		}
	}
	.barber-header {
		.mega-menu {
			.topbar-social {
				display: none;
			}
			.topbar-telephone {
				display: none;
			}
			.menu-logo {
				>li {
					>a {
						img {
							height: 30px;
						}
					}
				}
			}
		}
	}
	.barber-header.logo-center {
		.mega-menu {
			.menu-logo {
				padding: 12px 0;
			}
		}
	}
	.barber-title {
		span {
			top: -45px;
			font-size: $fs-100;
		}
	}
	.barber {
		.about-history {
			margin: 40px 0px;
		}
	}
	.choose-us {
		background: $black !important;
		.section-title {
			margin-bottom: 80px;
		}
	}
	.choose-us-bg {
		padding: 40px 0px;
	}
	.choose-us-after {
		&:after {
			position: absolute;
			width: 100%;
			height: 100%;
			content: "";
			background: $gray-bg;
			right: -100%;
			top: 0;
		}
	}
	.testimonial {
		margin-top: 25px;
	}
	.promo-text {
		margin-bottom: 50px;
	}
	.feature-step {
		padding: 240px 30px;
	}
	.tattoo-banner {
		h1 {
			font-size: $fs-54;
			line-height: 76px;
		}
	}
	.price-tattoo {
		padding: 0;
	}
	.burger-dark {
		background: #000 !important;
		span.text-white {
			color: $white !important;
		}
	}
	.team {
		.barber-section-title {
			.w-50 {
				width: 100% !important;
			}
		}
	}
	.job-testimonial {
		.testimonial-info {
			font-size: $fs-18;
			line-height: $l-height-28;
			&:before {
				font-size: 120px;
			}
		}
	}
	.jobs-banner {
		h1 {
			font-size: $fs-60;
		}
		span {
			font-size: $fs-20;
		}
		.mt-50 {
			margin-top: 20px !important;
		}
	}
	.listing-banner {
		h1 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
		p {
			font-size: $fs-20;
			line-height: $l-height-20;
			margin-bottom: 20px !important;
		}
	}
	.marketing-tab {
		.tab-vertical {
			.tab-content {
				margin-left: 0;
			}
		}
		.tab {
			margin-top: 40px;
		}
	}
	.tab-vertical {
		.nav-tabs {
			width: 100%;
			float: none;
		}
	}
	.marketing-banner {
		.slider-content {
			h1 {
				font-size: $fs-32;
				line-height: $l-height-50;
			}
		}
	}
	.marketing-action-box {
		.popup-video-image {
			a {
				i {
					position: relative;
					top: 20px;
					transform: inherit;
				}
			}
			&:before {
				background: transparent;
			}
		}
	}
	.business-banner-02 {
		.box-newsletter {
			display: none;
		}
	}
	.business-13-about-content {
		margin-top: 40px;
		padding: 30px;
		.pl-130.pr-130 {
			padding: 0px !important;
		}
	}
	.business-date-box-text {
		h2 {
			font-size: 140px;
		}
	}
	.business-date-box {
		.business-date-box-text {
			span {
				letter-spacing: 7px;
				right: -60px;
			}
		}
	}
	.business-banner {
		h1 {
			font-size: $fs-60;
			line-height: $l-height-50;
			line-height: $l-height-20;
		}
		span {
			font-size: $fs-24;
		}
		p {
			padding: 0;
		}
	}
	.business-banner-03 {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
		span {
			font-size: $fs-16;
			letter-spacing: 6px;
		}
	}
	.business-video {
		.business-video-box {
			.vertical-align {
				padding: 50px;
			}
		}
	}
	.business-feature-top {
		padding: 30px;
	}
	.business-banner-04 {
		p {
			padding: 0;
		}
	}
}

@media(max-width:767px) {

	.xs-mt-0 {
		margin-top: 0 !important;
	}
	.xs-mt-10 {
		margin-top: 10px !important;
	}
	.xs-mt-15 {
		margin-top: 15px !important;
	}
	.xs-mt-20 {
		margin-top: 20px !important;
	}
	.xs-mt-30 {
		margin-top: 30px !important;
	}
	.xs-mt-40 {
		margin-top: 40px !important;
	}
	.xs-mr-0 {
		margin-right: 0 !important;
	}
	.xs-mr-10 {
		margin-right: 10px !important;
	}
	.xs-mr-15 {
		margin-right: 15px !important;
	}
	.xs-mr-20 {
		margin-right: 20px !important;
	}
	.xs-mr-30 {
		margin-right: 30px !important;
	}
	.xs-mr-40 {
		margin-right: 40px !important;
	}
	.xs-mb-0 {
		margin-bottom: 0 !important;
	}
	.xs-mb-10 {
		margin-bottom: 10px !important;
	}
	.xs-mb-15 {
		margin-bottom: 15px !important;
	}
	.xs-mb-20 {
		margin-bottom: 20px !important;
	}
	.xs-mb-30 {
		margin-bottom: 30px !important;
	}
	.xs-mb-40 {
		margin-bottom: 40px !important;
	}
	.xs-ml-0 {
		margin-left: 0 !important;
	}
	.xs-ml-10 {
		margin-left: 10px !important;
	}
	.xs-ml-15 {
		margin-left: 15px !important;
	}
	.xs-ml-20 {
		margin-left: 20px !important;
	}
	.xs-ml-30 {
		margin-left: 30px !important;
	}
	.xs-ml-40 {
		margin-left: 40px !important;
	}
	.xs-pt-0 {
		padding-top: 0 !important;
	}
	.xs-pt-10 {
		padding-top: 10px !important;
	}
	.xs-pt-15 {
		padding-top: 15px !important;
	}
	.xs-pt-20 {
		padding-top: 20px !important;
	}
	.xs-pt-30 {
		padding-top: 30px !important;
	}
	.xs-pt-40 {
		padding-top: 40px !important;
	}
	.xs-pr-0 {
		padding-right: 0 !important;
	}
	.xs-pr-10 {
		padding-right: 10px !important;
	}
	.xs-pr-15 {
		padding-right: 15px !important;
	}
	.xs-pr-20 {
		padding-right: 20px !important;
	}
	.xs-pr-30 {
		padding-right: 30px !important;
	}
	.xs-pr-40 {
		padding-right: 40px !important;
	}
	.xs-pb-0 {
		padding-bottom: 0 !important;
	}
	.xs-pb-10 {
		padding-bottom: 10px !important;
	}
	.xs-pb-15 {
		padding-bottom: 15px !important;
	}
	.xs-pb-20 {
		padding-bottom: 20px !important;
	}
	.xs-pb-30 {
		padding-bottom: 30px !important;
	}
	.xs-pb-40 {
		padding-bottom: 40px !important;
	}
	.xs-pl-0 {
		padding-left: 0 !important;
	}
	.xs-pl-10 {
		padding-left: 10px !important;
	}
	.xs-pl-15 {
		padding-left: 15px !important;
	}
	.xs-pl-20 {
		padding-left: 20px !important;
	}
	.xs-pl-30 {
		padding-left: 30px !important;
	}
	.xs-pl-40 {
		padding-left: 40px !important;
	}
	h1 {
		font-size: $fs-30;
	}
	h2 {
		font-size: $fs-26;
	}
	.page-section-ptb {
		padding: 50px 0;
	}
	.page-section-pt {
		padding: 50px 0 0;
	}
	.page-section-pb {
		padding: 0 0 50px;
	}
	.page-section-1-ptb {
		padding: 60px 0;
	}
	.header.fullWidth {
		.container-fluid {
			padding: 0 20px;
		}
	}
	.topbar-call.text-left {
		text-align: center;
		margin-bottom: 10px;
	}
	.topbar-social.text-right {
		text-align: center;
	}
	.custom-content {
		margin-top: 0px;
	}
	.testimonial-2 {
		padding-bottom: 80px;
	}
	.team-3-box {
		img {
			width: 100%;
		}
		margin-bottom: 30px;
	}
	.accordion.accordion-2 {
		.acd-group {
			.acd-heading {
				padding-left: 0px;
				padding-right: 0px;
			}
		}
	}
	.footer-logo {
		img {
			height: 60px;
			width: inherit;
		}
	}
	img#logo-footer {
		height: 40px;
	}
	.footer {
		.footer-nav.text-right {
			text-align: left;
			margin-top: 20px;
		}
		.footer-social {
			ul.text-right {
				text-align: left;
			}
		}
		.footer-widget {
			.footer-widget-social.text-right {
				text-align: left;
			}
		}
		.container-fluid {
			padding: 0 15px;
		}
	}
	.social.text-right {
		text-align: left;
		margin-top: 20px;
	}
	.footer-contact {
		margin-top: 0;
		box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
		.contact-box {
			margin-bottom: 20px;
		}
	}
	.our-clients.theme-bg {
		padding-bottom: 60px;
	}
	.copyright.mt-50 {
		margin-top: 10px !important;
	}
	.copyright {
		.text-right {
			text-align: left;
			margin-top: 15px;
		}
	}
	footer {
		.social-icons.float-right {
			float: left !important;
			width: 100%;
		}
	}
	.footer-widget.mt-60 {
		margin-top: 0 !important;
	}
	.footer-box {
		margin-bottom: 20px;
		margin-top: 30px;
	}
	.coming-soon-main {
		overflow: hidden;
		i {
			font-size: $fs-70;
		}
		p {
			font-size: $fs-18;
			line-height: 26px;
		}
		h1 {
			font-size: 42px;
			margin-bottom: 20px;
		}
	}
	.coming-soon-countdown {
		ul.countdown {
			li {
				span {
					font-size: $fs-60;
				}
				p {
					font-size: $fs-16;
				}
			}
		}
	}
	.error-search-box {
		input {
			width: 73%;
		}
	}
	.error-block {
		p {
			font-size: $fs-20;
		}
		h1 {
			font-size: $fs-80;
			padding: 0;
		}
		padding: 0;
		.error-text {
			padding: 0;
			display: none;
		}
	}
	.error-404-2 {
		.error-block {
			h1 {
				font-size: 290px;
			}
		}
	}
	.maintenance-progress-bar {
		.progress-bar {
			display: block;
			margin: 0 auto 90px;
		}
		margin: 30px 0 60px;
	}
	.maintenance-main {
		p {
			margin-bottom: 10px;
		}
	}
	.isotope.columns-3 {
		.grid-item {
			width: 50%;
			width: 100%;
		}
	}
	.isotope.columns-4 {
		.grid-item {
			width: 50%;
			width: 100%;
		}
	}
	.about {
		.feature-3 {
			margin-bottom: 30px;
		}
	}
	.feature-text.box {
		padding: 30px;
	}
	.feature-text.fill-box {
		padding: 30px;
	}
	.footer-widget {
		text-align: left;
	}
	.xs-text-left {
		text-align: left;
	}
	.xs-text-center {
		text-align: center;
	}
	.xs-text-right {
		text-align: right;
	}
	.pricing-table {
		margin: 0 0 30px;
	}
	.team {
		margin-bottom: 30px;
		.team-photo {
			img {
				width: 100%;
			}
		}
		.barber-section-title {
			bottom: 30px;
		}
	}
	.lead {
		font-size: $fs-14;
	}
	.inner-intro {
		text-align: center;
	}
	ul.page-breadcrumb {
		padding-top: 30px;
	}
	.feature-step-2-box {
		padding: 20px;
	}
	.feature-step-2 {
		.feature-step-2-title-left {
			h1 {
				font-size: $fs-50;
			}
		}
	}
	.our-app {
		background-image: none !important;
	}
	.valign {
		>[class*="col-"] {
			display: block;
		}
	}
	.highlights-phone {
		margin: 30px 0;
	}
	.masonry.columns-2 {
		.masonry-item {
			width: 100%;
		}
	}
	.masonry.columns-3 {
		.masonry-item {
			width: 100%;
			width: 100%;
		}
	}
	.blog {
		ul.timeline {
			&:before {
				left: 40px;
			}
			>li {
				margin-bottom: 20px;
				position: relative;
				width: 100%;
				float: left;
				clear: left;
				>.timeline-panel {
					width: calc(100% - 90px);
					width: -moz-calc(100% - 90px);
					width: -webkit-calc(100% - 90px);
					float: right;
					&:before {
						border-left-width: 0;
						border-right-width: 15px;
						left: -15px;
						right: auto;
					}
					&:after {
						border-left-width: 0;
						border-right-width: 14px;
						left: -14px;
						right: auto;
					}
				}
				>.timeline-badge {
					left: 12px;
					margin-left: 0;
					top: 16px;
				}
			}
		}
		.timeline {
			>li.timeline-inverted {
				float: left;
				clear: left;
				margin-top: 30px;
				margin-bottom: 30px;
				margin-top: 10px;
				>.timeline-badge {
					left: 12px;
				}
			}
			li.entry-date-bottom {
				text-align: left;
				a {
					font-size: $fs-13;
					padding: 8px 10px;
				}
			}
			li.entry-date {
				text-align: left;
				top: -20px;
				span {
					font-size: $fs-13;
					padding: 8px 10px;
				}
			}
			>li {
				&:nth-child(2) {
					margin-top: 0;
				}
			}
		}
		.timeline-panel {
			img {
				width: 100%;
			}
		}
	}
	.blog.timeline-sidebar {
		.timeline {
			li.timeline-inverted {
				.timeline-badge {
					left: 23px;
				}
			}
			>li.timeline-inverted {
				margin-bottom: 20px;
				&:nth-child(2) {
					margin-bottom: 0px;
				}
			}
			li {
				.blog-entry-grid {
					.grid-post {
						li {
							width: 100%;
						}
					}
				}
			}
			li.entry-date {
				top: -20px;
			}
		}
	}
	.login-2-social {
		li {
			margin-bottom: 10px;
		}
	}
	.progress-new {
		width: 100%;
	}
	.maintenance-form {
		input {
			width: 100%;
		}
	}
	.isotope.columns-2 {
		.grid-item {
			width: 100%;
		}
	}
	.isotope.columns-5 {
		.grid-item {
			width: 100%;
		}
	}
	.masonry.columns-4 {
		.masonry-item {
			width: 100%;
		}
	}
	.masonry.columns-5 {
		.masonry-item {
			width: 100%;
		}
	}
	.deal-banner {
		img {
			width: 100%;
		}
	}
	.process.left {
		.process-content {
			.process-icon {
				display: block;
				margin-bottom: 20px;
			}
			padding-left: 40px;
		}
		.process-step {
			top: 30px;
			left: -20px;
		}
	}
	.process.right {
		.process-content {
			padding-right: 40px;
			.process-icon {
				float: none;
				margin-bottom: 20px;
			}
		}
		.process-step {
			right: -20px;
		}
	}
	.process {
		.process-step {
			strong {
				width: 40px;
				height: 40px;
				line-height: $l-height-40;
			}
		}
	}
	.resume-page {
		.container-fluid {
			padding: 0 15px;
		}
	}
	.clients-list {
		ul {
			li {
				width: 50%;
			}
		}
	}
	.clients-list.column-4 {
		ul {
			li {
				width: 50%;
			}
		}
	}
	.clients-list.column-3 {
		ul {
			li {
				width: 50%;
			}
		}
	}
	.countdown.round {
		width: 100px;
		height: 100px;
		padding: 16px;
		span {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.countdown.round.medium {
		width: 100px;
		height: 100px;
		span {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		p {
			font-size: $fs-14;
		}
	}
	.countdown.round.large {
		width: 100px;
		height: 100px;
		span {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		p {
			font-size: $fs-14;
		}
	}
	.dataTables_paginate {
		.pagination {
			>li {
				display: inline-block;
				text-align: center;
			}
		}
	}
	.datatable-base {
		.table-responsive {
			border: 0;
		}
	}
	.feature-text.left-icon {
		.feature-icon {
			padding-left: 15px;
		}
	}
	.feature-text.right-icon {
		.feature-icon {
			padding-right: 15px;
		}
	}
	.tab-vertical {
		.nav-tabs {
			width: 100%;
		}
		.tab-content {
			width: 100%;
			margin-left: 0;
		}
	}
	.feature-text {
		.feature-icon {
			span {
				padding-left: 0;
			}
			i {
				padding-left: 0;
			}
		}
	}
	.bg-top-before {
		padding-top: 40px !important;
		&:before {
			display: none;
		}
	}
	.bg-top-before.pb-150 {
		padding-bottom: 40px !important;
	}
	.course {
		margin-bottom: 20px;
		img {
			width: 100%;
		}
	}
	.medical-tab {
		.tab {
			.nav.nav-tabs {
				li.active {
					a {
						&:before {
							display: none;
						}
					}
				}
				li {
					a.active {
						&:before {
							border: none;
						}
					}
				}
			}
		}
	}
	.mobile-app-about {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.product {
		.product-image {
			img {
				width: 100%;
			}
		}
	}
	.portfolio-banner {
		.slider-content {
			h1 {
				font-size: $fs-20;
				line-height: $l-height-20;
			}
			span {
				font-size: $fs-12;
				line-height: 18px;
			}
		}
	}
	.error-text-02 {
		display: none;
	}
	.interior-action-box {
		margin-top: 30px;
	}
	#rev_slider_11_1_wrapper {
		strong {
			font-size: 20px !important;
		}
	}
	#rev_slider_13_1_wrapper {
		i {
			font-size: 20px !important;
		}
	}
	.process-list {
		padding-left: 30px;
		padding-right: 30px;
	}
	.isotope {
		margin: 0;
	}
	.masonry {
		margin: 0;
	}
	.app-home {
		padding-bottom: 40px;
	}
	.services-text-box-green {
		padding: 50px 15px !important;
	}
	.services-text-box-blue {
		padding: 50px 15px !important;
	}
	.services-text-box-black {
		padding: 50px 15px !important;
	}
	.bottom-nav {
		.owl-nav {
			bottom: 20px;
		}
	}
	.blog-box.blog-2 {
		.button.icon-color {
			margin-top: 0px;
			float: right !important;
		}
		.blog-info {
			padding: 20px;
		}
	}
	.team.team-hover.team-border {
		.team-photo {
			img {
				width: 100%;
			}
		}
	}
	.owl-carousel {
		.owl-dots {
			bottom: -15px;
		}
	}
	.special-feature {
		.row-eq-height {
			display: block;
		}
	}
	.cafe-counter.mt-60 {
		margin-top: 0px !important;
	}
	.footer.footer-one-page {
		.footer-widget {
			text-align: center;
			.footer-widget-social.text-right {
				text-align: center;
			}
		}
	}
	.text-left.mt-100 {
		margin-top: 40px !important;
	}
	.slider-parallax.typer-banner.business {
		h1 {
			line-height: $l-height-30;
			font-size: $fs-40;
		}
	}
	.play-video-section {
		.content {
			padding: 0;
		}
	}
	.slider-parallax.banner-personal {
		.slider-content {
			span {
				font-size: $fs-70;
				line-height: $l-height-70;
			}
			h1 {
				font-size: $fs-60;
				line-height: 60px;
				margin-top: -20px;
				strong {
					font-size: 72px;
					line-height: 60px;
				}
			}
		}
	}
	.countdown {
		p {
			font-size: $fs-13;
		}
	}
	.christmas-about {
		.feature-text {
			margin-bottom: 50px;
		}
	}
	.christmas-form-content {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.happy-clients {
		.mb-40 {
			margin-bottom: 0 !important;
		}
	}
	.christmas-form-content.mt-100 {
		margin-top: 60px !important;
	}
	.christmas-team {
		.team {
			.team-photo {
				img {
					width: 100%;
				}
			}
		}
	}
	.timer-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
		}
	}
	.movie-banner {
		.slider-content-middle {
			padding: 0;
		}
	}
	.law-banner {
		.slider-content {
			h1 {
				line-height: $l-height-30;
				font-size: $fs-30;
			}
		}
	}
	.banner-agency-02 {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.agency-02-contact {
		.footer-contact {
			margin-top: -50px;
			margin-bottom: 30px;
		}
	}
	.agency-02-contact-title {
		.mb-50 {
			margin-bottom: 0 !important;
		}
	}
	.portfolio-parallax-banner {
		h2 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
	}
	.coming-soon-big {
		.countdown.medium {
			span {
				font-size: $fs-40;
				line-height: $l-height-40;
			}
			p {
				font-size: $fs-16;
				line-height: 16px;
			}
		}
	}
	.error-05 {
		h1 {
			font-size: $fs-80;
			line-height: 80px;
		}
	}
	.list-unstyled.pl-60 {
		padding-left: 0 !important;
	}
	.page-title.light-overlay {
		padding-top: 200px;
		padding-top: 200px;
	}
	.divider.pt-70 {
		padding-top: 30px !important;
	}
	.divider.pt-50 {
		padding-top: 30px !important;
	}
	.faq-box {
		margin-top: 30px;
	}
	.faq-page-title {
		.mt-50 {
			margin-top: 10px !important;
		}
	}
	#main-slider.shop-05-banner {
		.slider-content {
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
			}
		}
	}
	#main-slider.shop-06-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
				margin-bottom: 10px !important;
			}
			p {
				font-size: $fs-14;
				line-height: $l-height-20;
				margin: 10px 0 !important;
			}
			a.button {
				font-size: 10px;
				padding: 6px 10px;
			}
		}
	}
	.shop-07-product {
		.product.mt-80 {
			margin-top: 0 !important;
		}
	}
	.banner-shop-07 {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
	}
	.book-counter {
		.counter {
			margin-top: 30px;
		}
	}
	.book-custom-block {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.book-banner {
		.row.row-eq-height {
			display: block;
			.col-sm-12 {
				position: inherit;
			}
		}
		.slider-content-text {
			top: 100px;
			text-align: center;
			width: 100%;
			h2 {
				display: none;
			}
			p {
				display: none;
			}
		}
		.slider-content-image {
			bottom: 50px;
			left: -100px;
			display: block;
			text-align: center;
			width: 100%;
			.book {
				height: 230px;
			}
			.text {
				height: 180px;
				display: none;
			}
		}
	}
	.plumber-banner {
		.slider-content-image {
			img {
				height: 500px;
			}
			left: 10%;
		}
	}
	.plumber-contact-bg {
		margin-top: 60px;
	}
	.product-banner {
		.slider-content {
			h1 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
		}
	}
	.product-video {
		.product-video-box {
			.vertical-align {
				padding: 30px;
			}
			min-height: 400px;
		}
	}
	.fish-banner {
		height: inherit;
		padding: 230px 0;
		background: #e6ebf0 !important;
		.slider-content {
			.fish-banner-image {
				img {
					height: 230px;
					margin-top: 30px;
					margin-bottom: 30px;
				}
			}
			text-align: center;
		}
	}
	.fish-gallery {
		.fish-gallery-top {
			margin-top: 40px;
			padding-bottom: 60px;
		}
		.section-title {
			h2 {
				color: $white;
			}
		}
	}
	.fish-footer {
		.fish-image {
			margin-top: -60px;
		}
	}
	.one-page.light {
		.container-fluid {
			padding: 0 15px;
		}
	}
	.hotel-banner-checkout {
		.checkout-date {
			.form-control {
				margin-bottom: 0;
			}
		}
		.checkout {
			.checkout-date {
				padding-top: 0;
				height: inherit;
			}
		}
	}
	.make-call {
		h2 {
			font-size: $fs-30;
		}
		strong {
			font-size: $fs-30;
		}
		span {
			font-size: $fs-26;
			margin-bottom: 0;
		}
	}
	.burger-custom-content {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.burger-banner {
		.slider-content-middle {
			top: 140px;
		}
	}
	.skateboard-banner {
		h1 {
			font-size: $fs-70;
			line-height: $l-height-70;
		}
	}
	.bitcoin-about-bg {
		margin-top: 40px;
		padding: 50px 30px;
	}
	.currency-calculator {
		.feature-text {
			.feature-arrow {
				display: none;
			}
		}
	}
	.bitcoin-custom-conten-box {
		padding: 50px 20px;
	}
	.bitcoin-custom-content-02 {
		.counter {
			span {
				font-size: $fs-70;
				line-height: $l-height-70;
			}
			label {
				margin-bottom: 20px;
			}
		}
		p {
			font-size: $fs-14;
		}
	}
	.skateboard-about {
		padding-top: 100px;
	}
	.secrvice-blog {
		margin-top: -30px;
	}
	.search-no-result {
		padding: 40px 0px;
		.bg-title {
			h2 {
				font-size: 170px;
			}
		}
	}
	.barber-title {
		span {
			top: -57px;
			font-size: $fs-80;
		}
	}
	.barber {
		.about-history {
			margin: 40px 30px 30px 30px;
		}
	}
	.choose-us-bg {
		padding: 40px;
	}
	.barber-team {
		background-size: 100%;
	}
	.pricing-image {
		img {
			width: 100%;
		}
	}
	.team.team-list {
		.team-description {
			text-align: center;
		}
	}
	.tattoo-section-title {
		display: none;
	}
	.tattoo-section-title-02 {
		display: none;
	}
	.barber-section-title {
		.w-50 {
			width: 100% !important;
		}
		.w-25 {
			width: 100% !important;
		}
	}
	.jobs-banner {
		h1 {
			font-size: $fs-40;
			line-height: $l-height-30;
		}
	}
	.job-list {
		text-align: center;
		h5 {
			margin: 15px 0;
		}
	}
	.listing-banner-categorie {
		display: none;
	}
	.listing-search {
		margin-top: 60px;
		padding: 20px;
	}
	.marketing-banner {
		p {
			display: none;
		}
	}
	.marketing-service {
		.feature-text.left-icon {
			.feature-icon {
				padding-left: 0;
			}
		}
	}
	.our-history.timeline-left {
		.timeline {
			>li {
				>.timeline-badge {
					left: 3%;
				}
				>.timeline-panel {
					width: 100%;
					padding: 40px 20px 20px 45px;
				}
			}
			&:before {
				left: 3%;
			}
			>li.timeline-inverted {
				>.timeline-panel {
					&::before {
						display: none;
					}
				}
			}
		}
		>li {
			>.timeline-panel {
				&::before {
					display: none;
				}
			}
		}
	}
	.business-banner-02 {
		p {
			display: none;
		}
	}
	.business-date-box-text {
		h2 {
			margin-bottom: 50px;
		}
	}
	.business-banner-05 {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-34;
		}
		p {
			padding: 0;
		}
	}

	.action-box-button .button {
		margin: 20px auto 0 auto;
		display: block;
		width: fit-content;

		position: static;
		right: auto;
		top: auto;
		transform: none;
		margin-top: 10px;
	}

}

@media(max-width:600px) {
	h3 {
		font-size: $fs-22;
	}
	.search-box {
		width: 210px;
	}
	.section-title {
		p {
			font-size: $fs-16;
		}
		h2 {
			font-size: $fs-34;
			line-height: $l-height-34;
		}
	}
	.shpping-cart {
		.cart {
			width: 290px;
		}
		.cart-total {
			a {
				margin-bottom: 10px;
			}
		}
	}
	.portfolio-home {
		.isotope.columns-3 {
			.grid-item {
				width: 100%;
			}
		}
	}
	.portfolio-title {
		padding: 40px 30px;
	}
	.main-counter {
		.counter-info {
			span.counter {
				font-size: $fs-40;
			}
		}
	}
	.action-box {
		text-align: center;
		.action-box-button {
			text-align: center;
		}
	}
	.skills-2.col-2 {
		li {
			width: 100%;
			margin-bottom: 0;
			margin-right: 0;
			float: none;
		}
	}
	.skills-2.col-2.mr-60 {
		margin-right: 0 !important;
	}
	.error-block {
		padding: 30px 0px;
		h1 {
			font-size: $fs-70;
		}
	}
	.error-404-2 {
		.error-block {
			h1 {
				font-size: 200px;
				line-height: 200px;
			}
			p {
				font-size: $fs-16;
			}
		}
	}
	.maintenance-main {
		h1.mb-50 {
			font-size: $fs-28;
		}
	}
	.maintenance-2 {
		.maintenance-main {
			h1 {
				font-size: $fs-32;
			}
		}
	}
	.isotope.columns-3 {
		.grid-item {
			width: 100%;
			padding: 0 0px 15px 0;
			padding-right: 0;
		}
	}
	.isotope.columns-4 {
		.grid-item {
			width: 100%;
			padding-right: 0;
			padding-right: 0;
		}
	}
	.clients-logo.line {
		img {
			height: 65px;
		}
	}
	.contact-form {
		.section-field {
			width: 100%;
		}
	}
	.touch-in {
		.contact-box {
			margin: 20px 0;
		}
	}
	.our-history {
		.timeline {
			>li.timeline-inverted {
				>.timeline-panel {
					&::before {
						left: 40px;
					}
				}
				.timeline-badge {
					p {
						float: left;
						margin-right: 0;
						text-align: left;
					}
				}
			}
			>li {
				>.timeline-panel {
					&::before {
						left: 40px;
					}
					padding: 40px 20px 20px 75px;
					text-align: left;
					width: 100%;
				}
				>.timeline-badge {
					left: 41px;
					p {
						margin-left: 40px;
					}
				}
			}
			&:before {
				left: 40px;
			}
			.timeline-arrow {
				left: 34px;
				text-align: left;
			}
		}
		overflow: hidden;
	}
	.feature-text {
		.feature-icon {
			font-size: $fs-38;
		}
	}
	.skill {
		margin: 60px 0 20px;
	}
	.feature-text.box {
		padding: 30px;
	}
	.happy-clients {
		.nav-tabs {
			li {
				img {
					height: 50px;
					width: 50px;
				}
			}
		}
		.tab {
			.nav.nav-tabs {
				margin-bottom: 0;
			}
		}
	}
	.testimonial-info {
		p {
			font-size: $fs-15;
			margin: 25px 0 0;
			padding: 0 0 20px;
		}
	}
	.testimonial-2 {
		.testimonial-info {
			p {
				&::before {
					font-size: $fs-100;
				}
			}
		}
	}
	.social.float-right {
		float: none;
		margin-top: 10px;
		display: block;
		width: 100%;
	}
	.port-post-social.float-right {
		float: none !important;
	}
	.port-post-photo {
		display: block;
		float: none;
	}
	.port-post-info {
		padding-left: 0;
		margin-top: 20px;
		display: block;
	}
	.comments-1.comments-2 {
		padding-left: 40px;
		.comments-info {
			padding: 20px 0px 10px;
		}
		.comments-photo {
			margin-bottom: 0px;
		}
	}
	.comments-1 {
		.comments-photo {
			float: none;
			margin-bottom: 20px;
		}
	}
	.our-team {
		.team-details {
			.social {
				float: none !important;
				width: 100%;
				margin-bottom: 20px;
			}
			.title.float-left {
				float: none !important;
				display: block;
			}
			.social-icons.social-border.float-right {
				float: none !important;
				display: inline-block;
				margin-bottom: 10px;
			}
		}
	}
	.countdown.small {
		span {
			font-size: $fs-30;
		}
	}
	.countdown {
		span {
			font-size: $fs-30;
		}
	}
	.countdown.medium {
		span {
			font-size: $fs-30;
		}
		p {
			font-size: $fs-14;
		}
		margin: 0 10px;
	}
	.countdown.large {
		span {
			font-size: $fs-30;
		}
		p {
			font-size: $fs-14;
		}
		margin: 0 6px;
	}
	.countdown.gray-bg {
		margin: 0 1px;
		padding: 8px;
	}
	.countdown.countdown-border {
		padding: 8px;
		margin: 0 0px;
	}
	.countdown.round {
		margin-bottom: 10px;
		display: block;
		margin: 5px auto;
	}
	.countdown.round.small {
		padding: 16px;
	}
	.testimonial.bottom_pos {
		.author-info {
			margin-left: 0;
			margin-bottom: 30px;
		}
	}
	.pagination {
		>li {
			display: inline-block;
			margin-bottom: 3px;
		}
	}
	.owl-carousel {
		.owl-dots {
			bottom: 20px;
		}
	}
	.resume-contact {
		.footer-widget-social {
			a {
				i {
					margin-bottom: 4px;
				}
			}
		}
	}
	.popup-video-banner {
		h1 {
			font-size: $fs-36;
			line-height: $l-height-40;
		}
	}
	.kety-perry-list {
		padding-right: 20px;
	}
	.kety-play-icon {
		float: none;
		margin-top: 0;
	}
	.kety-name {
		padding-left: 0;
	}
	.team.team-list {
		.team-photo {
			float: none;
			width: 100%;
			margin-bottom: 30px;
			img {
				width: 100%;
			}
		}
		.team-description {
			padding-left: 0;
			padding-bottom: 0;
		}
	}
	.tab {
		.nav.nav-tabs {
			li {
				margin-bottom: 0px;
			}
		}
	}
	.testimonial.clean {
		padding: 0 10px 25px 40px;
	}
	.typer-banner {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-100;
		}
	}
	.portfolio-banner {
		.slider-content {
			width: 100%;
		}
	}
	.personal-typer-banner {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
			span {
				font-size: 25px;
			}
		}
	}
	.services-text-box-green {
		padding: 60px 15px !important;
	}
	.services-text-box-black {
		padding: 60px 15px !important;
	}
	.bottom-nav {
		.owl-nav {
			.owl-next {
				right: 42%;
			}
			.owl-prev {
				left: 42%;
			}
		}
	}
	.testimonial.light {
		padding: 85px 20px 45px 50px;
	}
	.row.mt-80 {
		margin-top: 30px !important;
	}
	.our-service-home {
		.row.mb-60 {
			margin-bottom: 10px !important;
		}
	}
	.contact-3 {
		.g-map {
			padding-top: 40%;
		}
	}
	.accordion.animated {
		.acd-group.acd-active {
			.acd-heading {
				font-size: $fs-22;
			}
		}
	}
	.progress-new {
		margin-bottom: 0;
	}
	.isotope.columns-5 {
		.grid-item {
			padding-right: 0;
		}
	}
	.shop-single {
		.tab {
			.nav.nav-tabs {
				li {
					a {
						padding: 12px 20px;
					}
					margin-bottom: 0;
				}
			}
		}
	}
	.st-menu.big-side {
		width: 270px;
		.pos-bot {
			position: relative;
		}
		.slide-footer-content {
			padding: 0 10px 0px 0;
		}
		.social-icons.width-half {
			ul {
				li {
					width: inherit;
					display: block;
					float: none;
				}
			}
		}
		.copy-right {
			.mb-70 {
				margin-bottom: 0 !important;
			}
		}
	}
	.blockquote-section {
		blockquote {
			font-size: $fs-30;
			line-height: $l-height-34;
		}
	}
	.custom-content-03 {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-34;
		}
	}
	.mt-100.mb-100 {
		margin: 0px 0 !important;
	}
	.slider-parallax.banner-personal {
		.slider-content {
			width: 100%;
		}
	}
	.christmas-clients {
		.mt-80 {
			margin-top: 0 !important;
		}
	}
	.timer-banner {
		.slider-content {
			h1 {
				font-size: 21px;
				line-height: $l-height-28;
				margin-bottom: 10px;
			}
		}
	}
	.conference-video {
		h3 {
			line-height: $l-height-30;
		}
	}
	.architecture-about {
		h2 {
			font-size: $fs-24;
			line-height: $l-height-30;
		}
	}
	.agency-banner {
		.slider-content {
			.agency-banner-right {
				display: block;
			}
			.agency-banner-left {
				display: block;
			}
			span {
				padding-top: 0;
				font-size: $fs-30;
				line-height: $l-height-20;
			}
			h1 {
				font-size: $fs-30;
				padding: 0;
				border: none;
				line-height: $l-height-20;
			}
		}
	}
	.slider-parallax.agency-banner {
		height: 80vh;
	}
	.movie-banner {
		.countdown.round.small {
			display: inline-block;
		}
	}
	.law-timeline {
		.timeline-centered {
			.timeline-entry {
				.timeline-entry-inner {
					.timeline-year {
						left: -45px;
					}
				}
			}
		}
	}
	.testimonial.left_pos {
		padding: 20px 10px 20px 50px;
		.testimonial-avatar {
			position: inherit;
			left: inherit;
			top: inherit;
			margin-top: 0;
			margin-bottom: 60px;
			margin-left: 0px;
		}
	}
	.agency-02-contact {
		.pl-50.pr-50 {
			padding: 15px !important;
		}
	}
	.agency-02-history-who {
		padding: 30px;
	}
	.banner-agency-02 {
		h1 {
			font-size: $fs-24;
		}
	}
	.portfolio-parallax-banner {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.coming-soon-big {
		.countdown.medium {
			span {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
			p {
				font-size: $fs-16;
				line-height: 16px;
			}
		}
	}
	.error-05 {
		h1 {
			font-size: $fs-60;
			line-height: 60px;
		}
		h3 {
			font-size: $fs-40;
		}
		span {
			font-size: $fs-40;
		}
	}
	.error-03 {
		.content {
			h2 {
				font-size: $fs-32;
				line-height: 32px;
			}
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
			}
		}
		.error-404 {
			h1 {
				font-size: $fs-70;
				line-height: $l-height-70;
			}
			h2 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
		}
	}
	.error-04 {
		h1 {
			font-size: 160px;
			line-height: 160px;
		}
	}
	.coming-soon-effects {
		.coming-soon {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
				font-size: $fs-30;
				line-height: $l-height-30;
			}
		}
	}
	.coming-soon {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.login-fancy {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.login-box {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.login-box-02 {
		padding: 20px;
	}
	.login-signup {
		.tab {
			.nav.nav-tabs {
				li {
					margin-bottom: 0;
				}
			}
		}
		.login-box-02 {
			padding: 30px;
		}
	}
	.about-mission {
		.popup-content {
			padding-right: 20px;
			width: 100%;
			br {
				display: none;
			}
		}
	}
	.blog.blog-simple.blog-left {
		.blog-image {
			width: 100%;
			margin: 0;
			float: none;
		}
		.blog-name {
			width: 100%;
			float: none;
		}
	}
	#main-slider {
		.slider-content {
			a {
				font-size: $fs-14;
			}
			h1 {
				font-size: $fs-20;
				line-height: $l-height-30;
				margin-bottom: 10px;
			}
		}
		.carousel-indicators {
			bottom: 0;
		}
		.carousel-control.left {
			display: none;
		}
		.carousel-control.right {
			display: none;
		}
	}
	.blog-boxed-banner {
		.blog-overlay {
			.blog-name {
				h4 {
					margin: 0 !important;
				}
			}
		}
	}
	#main-slider.shop-04-banner {
		.slider-content {
			a.button {
				font-size: 10px;
			}
			span {
				display: none;
			}
			h1 {
				font-size: $fs-24;
				line-height: $l-height-28;
			}
		}
	}
	#main-slider.shop-05-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
		}
	}
	.shop-05-deal {
		h2 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
	}
	#main-slider.shop-06-banner {
		.slider-content {
			.slider {
				width: 100%;
				h1 {
					font-size: $fs-20;
				}
			}
		}
	}
	.shop-block {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		p {
			font-size: $fs-16;
		}
	}
	.book-banner {
		.slider-content-text {
			.button.white {
				display: none;
			}
			width: inherit;
			left: 0;
		}
		.slider-content-image {
			.book {
				height: 200px;
			}
		}
	}
	.plumber-banner {
		h1 {
			font-size: $fs-60;
			line-height: 60px;
		}
		overflow: hidden;
		.slider-content-image {
			left: 0;
		}
	}
	.about-plumber-box {
		.about-plumber-box-content {
			padding: 20px;
		}
	}
	.about-plumber {
		.pl-30 {
			padding-left: 0 !important;
		}
	}
	.book-now {
		.book-now-box {
			width: 70%;
		}
	}
	.pizza-contact {
		padding: 0;
	}
	.burger-banner {
		h1 {
			font-size: $fs-40;
			line-height: $l-height-50;
		}
	}
	.skateboard-banner {
		h1 {
			font-size: $fs-50;
			line-height: 60px;
		}
		.slider-content-image {
			img {
				height: 500px;
			}
		}
	}
	.slider-parallax.skateboard-banner {
		height: 60vh;
	}
	.coming-soon-birds {
		.countdown {
			margin: 0px 6px;
		}
	}
	.pizza-menu {
		.pr-60 {
			padding-right: 0 !important;
		}
	}
	.masonry.columns-1 {
		.masonry-item {
			padding-right: 0;
		}
	}
	.masonry.columns-2 {
		.masonry-item {
			padding-right: 0;
		}
	}
	.masonry.columns-3 {
		.masonry-item {
			padding-right: 0;
		}
	}
	.masonry.columns-4 {
		.masonry-item {
			padding-right: 0;
		}
	}
	.masonry.columns-5 {
		.masonry-item {
			padding-right: 0;
			padding-right: 0;
		}
	}
	.isotope.columns-1 {
		.grid-item {
			padding-right: 0;
		}
	}
	.isotope.columns-2 {
		.grid-item {
			padding-right: 0;
		}
	}
	.conference-split.split-section {
		.img-side {
			display: none;
		}
	}
	.barber-section-title {
		h2 {
			font-size: $fs-20;
			line-height: $l-height-24;
		}
	}
	.barber-team {
		display: inline-block;
		background-position: center top;
		background-repeat: no-repeat;
		padding: 190px 0;
	}
	.spa-border {
		&:before {
			display: none;
		}
	}
	.emergency-cases-info {
		i {
			float: none;
			margin-bottom: 20px;
		}
		h1 {
			font-size: $fs-20;
		}
	}
	.business-banner {
		h1 {
			font-size: $fs-40;
			letter-spacing: 10px;
		}
	}
	.business-date-box-text {
		h2 {
			margin-bottom: 200px;
		}
	}
	.business-feature-top {
		.border-right {
			border-right: 0 !important;
		}
	}
	.business-banner-04 {
		h1 {
			font-size: $fs-40;
		}
	}
	.business-banner-06 {
		text-align: center;
		p {
			padding: 0;
			font-size: $fs-14;
		}
		.play-video.border-video {
			margin-top: 30px;
		}
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}

}

@media (min-width: 768px) {
	.text-md-start {
		text-align: left !important;
	}

	.text-md-end {
		text-align: right!important;
	}
}

@media(max-width: 767px) {
	.container {
		max-width: 100%;
	}

}

@media(max-width: 576px){
	.copy-right{
		order: 1;
	}

	.contacts{
		display: flex;
		flex-direction: column-reverse;
	}
}

@media(max-width:479px) {
	/*************************
     Margin top
*************************/
	/*************************
    Margin right
*************************/
	/*************************
    Margin bottom
*************************/
	/*************************
    Margin left
*************************/
	/*************************
    Padding top
*************************/
	/*************************
    Padding right
*************************/
	/*************************
    Padding bottom
*************************/
	/*************************
    Padding left
*************************/
	/*index*/
	/* header */
	/*action box*/
	/*coming soon*/
	/*error*/
	/*maintenance*/
	/*testimonial page*/
	/*blog*/
	/*shop*/
	/*mobile app*/
	/*one page*/
	/*side panel*/
	/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
	/*************************************
             v1.0.3
**************************************/
	/*************************************
             v1.0.4
**************************************/
	/*************************************
             v1.0.4
**************************************/
	/*************************************
             v1.0.7
**************************************/
	/*************************************
             v1.0.7
**************************************/
	/*************************************
             v1.0.9
**************************************/
	/*************************************
             v1.2
**************************************/
	/*************************************
             v1.2
**************************************/
	/*************************************
             v1.2
**************************************/
	/*************************************
             v3.3
**************************************/
	/*************************************
             v3.6
**************************************/
	.xx-mt-0 {
		margin-top: 0 !important;
	}
	.xx-mt-10 {
		margin-top: 10px !important;
	}
	.xx-mt-15 {
		margin-top: 15px !important;
	}
	.xx-mt-20 {
		margin-top: 20px !important;
	}
	.xx-mt-30 {
		margin-top: 30px !important;
	}
	.xx-mt-40 {
		margin-top: 40px !important;
	}
	.xx-mr-0 {
		margin-right: 0 !important;
	}
	.xx-mr-10 {
		margin-right: 10px !important;
	}
	.xx-mr-15 {
		margin-right: 15px !important;
	}
	.xx-mr-20 {
		margin-right: 20px !important;
	}
	.xx-mr-30 {
		margin-right: 30px !important;
	}
	.xx-mr-40 {
		margin-right: 40px !important;
	}
	.xx-mb-0 {
		margin-bottom: 0 !important;
	}
	.xx-mb-10 {
		margin-bottom: 10px !important;
	}
	.xx-mb-15 {
		margin-bottom: 15px !important;
	}
	.xx-mb-20 {
		margin-bottom: 20px !important;
	}
	.xx-mb-30 {
		margin-bottom: 30px !important;
	}
	.xx-mb-40 {
		margin-bottom: 40px !important;
	}
	.xx-ml-0 {
		margin-left: 0 !important;
	}
	.xx-ml-10 {
		margin-left: 10px !important;
	}
	.xx-ml-15 {
		margin-left: 15px !important;
	}
	.xx-ml-20 {
		margin-left: 20px !important;
	}
	.xx-ml-30 {
		margin-left: 30px !important;
	}
	.xx-ml-40 {
		margin-left: 40px !important;
	}
	.xx-pt-0 {
		padding-top: 0 !important;
	}
	.xx-pt-10 {
		padding-top: 10px !important;
	}
	.xx-pt-15 {
		padding-top: 15px !important;
	}
	.xx-pt-20 {
		padding-top: 20px !important;
	}
	.xx-pt-30 {
		padding-top: 30px !important;
	}
	.xx-pt-40 {
		padding-top: 40px !important;
	}
	.xx-pr-0 {
		padding-right: 0 !important;
	}
	.xx-pr-10 {
		padding-right: 10px !important;
	}
	.xx-pr-15 {
		padding-right: 15px !important;
	}
	.xx-pr-20 {
		padding-right: 20px !important;
	}
	.xx-pr-30 {
		padding-right: 30px !important;
	}
	.xx-pr-40 {
		padding-right: 40px !important;
	}
	.xx-pb-0 {
		padding-bottom: 0 !important;
	}
	.xx-pb-10 {
		padding-bottom: 10px !important;
	}
	.xx-pb-15 {
		padding-bottom: 15px !important;
	}
	.xx-pb-20 {
		padding-bottom: 20px !important;
	}
	.xx-pb-30 {
		padding-bottom: 30px !important;
	}
	.xx-pb-40 {
		padding-bottom: 40px !important;
	}
	.xx-pl-0 {
		padding-left: 0 !important;
	}
	.xx-pl-10 {
		padding-left: 10px !important;
	}
	.xx-pl-15 {
		padding-left: 15px !important;
	}
	.xx-pl-20 {
		padding-left: 20px !important;
	}
	.xx-pl-30 {
		padding-left: 30px !important;
	}
	.xx-pl-40 {
		padding-left: 40px !important;
	}
	.col-xx-12 {
		width: 100% !important;
	}
	.col-xx-6 {
		width: 50% !important;
	}
	h2 {
		font-size: $fs-22;
		line-height: $l-height-30;
	}
	.topbar {
		.topbar-call.text-left {
			li {
				margin-bottom: 5px;
			}
		}
	}
	.clients-logo.line {
		img {
			width: initial;
			height: auto;
		}
		width: 100%;
		border-left: none;
		&:nth-last-child(-n+3) {
			border-bottom: 1px solid #dddddd;
		}
	}
	.shpping-cart {
		.cart {
			width: 240px;
		}
	}
	.cart-item {
		.cart-name {
			a {
				font-size: $fs-12;
			}
		}
		.cart-close {
			top: 17px;
		}
	}
	.cart-price {
		del {
			font-size: $fs-12;
		}
		ins {
			font-size: $fs-14;
		}
	}
	.round {
		.nav.nav-tabs {
			li {
				a {
					margin-right: 1px;
					padding: 8px 15px;
				}
			}
		}
	}
	.st-effect-3 {
		.header.default.fullWidth {
			.search-cart {
				right: 45px;
				top: 3px;
				.search {
					padding-right: 0px;
				}
			}
		}
		.search-box {
			width: 190px;
		}
	}
	.search-cart {
		.search {
			+ {
				.shpping-cart {
					padding-left: 5px;
				}
			}
		}
	}
	.action-box-button {
		.button.border-white {
			margin-top: 10px;
		}
		.button.border.icon {
			margin-left: 0;
			margin-top: 15px;
		}
		.button.border {
			margin-left: 0;
			margin-top: 15px;
		}
	}
	.coming-soon-main {
		h1 {
			font-size: $fs-34;
		}
		p {
			margin-bottom: 45px;
			font-size: $fs-16;
		}
	}
	.coming-soon-countdown {
		ul.countdown {
			li {
				span {
					font-size: $fs-32;
				}
				width: 20%;
				p {
					font-size: $fs-13;
				}
			}
			margin: 20px 0;
		}
	}
	.coming-soon-form {
		width: 100%;
		input {
			width: 90%;
			margin-bottom: 0;
		}
		a.button-border {
			span {
				padding: 11px 8px;
			}
		}
	}
	.error-block {
		h1 {
			font-size: $fs-50;
		}
		p {
			font-size: $fs-18;
		}
		h1.mb-40 {
			margin-bottom: 10px !important;
		}
	}
	.error-search-box {
		p {
			font-size: $fs-16;
			line-height: 32px;
		}
		input {
			width: 60%;
		}
	}
	.maintenance-2 {
		.maintenance-main {
			h1 {
				font-size: $fs-22;
				margin-bottom: 10px;
			}
		}
	}
	.maintenance-contant.mt-150 {
		margin-top: 30px !important;
	}
	.maintenance-form.mt-100 {
		margin-top: 30px !important;
	}
	.our-history {
		.timeline {
			>li {
				>.timeline-panel {
					&::before {
						width: 22px;
						left: 25px;
					}
				}
			}
			>li.timeline-inverted {
				>.timeline-panel {
					&::before {
						left: 25px;
					}
				}
			}
		}
	}
	.happy-clients {
		.nav.nav-tabs {
			li {
				display: inline-block;
			}
		}
		.nav-tabs {
			li {
				padding: 0;
			}
		}
	}
	.nav.nav-tabs {
		li {
			display: block;
			float: none;
			margin-bottom: 10px;
			text-align: center;
		}
	}
	.tab-2 {
		.nav-tabs {
			float: none;
			width: 100%;
			li {
				margin-bottom: 0;
			}
		}
		.tab-content {
			margin-left: 0;
			padding: 30px 20px;
		}
	}
	.testimonial-3 {
		.owl-carousel {
			.owl-nav {
				bottom: -20px;
				.owl-prev {
					left: 50%;
					margin-left: -45px;
				}
				.owl-next {
					right: 50%;
					margin-right: -45px;
				}
			}
		}
	}
	.testimonial-info {
		p {
			padding: 0 10px 20px;
		}
	}
	.testimonial.boxed {
		padding: 30px 30px 75px;
	}
	.feature-step-2-title-2-right {
		width: 100%;
	}
	.feature-step-2-box {
		padding: 20px 10px;
		p {
			font-size: $fs-13;
		}
	}
	.service-blog {
		b {
			font-size: 220px;
		}
	}
	.blog {
		.timeline {
			>li {
				>.timeline-badge {
					font-size: $fs-15;
					height: 44px;
					line-height: 12px;
					width: 44px;
				}
			}
			li.entry-date {
				span {
					display: none;
				}
			}
			li.timeline-inverted {
				.timeline-panel {
					&::after {
						display: none;
					}
					&::before {
						display: none;
					}
				}
			}
		}
		ul.timeline {
			&:before {
				left: 27px;
			}
			>li {
				>.timeline-badge {
					left: 5px;
					display: none;
				}
				>.timeline-panel {
					width: 100%;
					&::before {
						display: none;
					}
					&::after {
						display: none;
					}
				}
			}
			&::before {
				display: none;
			}
		}
	}
	.blog.timeline-sidebar {
		.timeline {
			>li {
				width: 100%;
			}
		}
	}
	.offer-banner-1 {
		h1 {
			font-size: $fs-20;
		}
	}
	.deal-banner {
		.counter-deal {
			ul {
				li {
					padding: 7px 10px;
					margin-right: 2px;
				}
			}
		}
		.caption {
			h2 {
				font-size: $fs-32;
			}
		}
	}
	.deal-banner.maintenance-progress-bar {
		margin: 0;
	}
	.bottom-nav {
		.owl-nav {
			.owl-next {
				right: 36%;
			}
			.owl-prev {
				left: 36%;
			}
		}
	}
	.action-box.pattern {
		padding: 70px 15px;
	}
	.action-box {
		padding: 30px 15px;
	}
	.appointment-bg {
		padding: 40px 15px;
	}
	.appointment {
		a.float-left {
			display: block;
		}
	}
	.mobile-app-about {
		a {
			img {
				margin-bottom: 10px;
			}
		}
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.phone-holder {
		width: 260px;
	}
	.highlights-phone.wht {
		.phone-holder {
			#fon {
				background: transparent;
			}
		}
		.hgi {
			top: 0;
		}
	}
	.highlights-phone {
		height: 550px;
	}
	.typer-banner {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.popup-video-banner {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-40;
		}
	}
	.video-background-banner {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		.slider-content {
			padding: 30px 15px;
		}
	}
	.slide-footer {
		.social {
			ul {
				li {
					a {
						padding-right: 15px;
					}
				}
			}
		}
	}
	.st-menu {
		width: 280px;
	}
	.st-effect-2.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-3.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-4.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-5.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-6.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0) rotateY(-15deg);
			transform: translate3d(280px, 0, 0) rotateY(-15deg);
		}
	}
	.st-effect-7.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-8.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-9.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(0, 0, -280px);
			transform: translate3d(0, 0, -280px);
		}
	}
	.st-effect-10.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-10.st-menu {
		-webkit-transform: translate3d(0, 0, -280px);
		transform: translate3d(0, 0, -280px);
	}
	.st-effect-13.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.st-effect-14.st-menu-open {
		.st-pusher {
			-webkit-transform: translate3d(280px, 0, 0);
			transform: translate3d(280px, 0, 0);
		}
	}
	.no-csstransforms3d {
		.st-pusher {
			padding-left: 280px;
		}
	}
	.no-js {
		.st-pusher {
			padding-left: 280px;
		}
	}
	.personal-typer-banner {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
			margin-bottom: 0px;
			span {
				font-size: $fs-20;
				left: -10px;
				top: 0;
			}
		}
	}
	.image-content {
		width: 100%;
		margin-right: 0;
	}
	.testimonial.bottom_pos {
		padding: 60px 20px 40px 46px;
	}
	.owl-carousel {
		.owl-dots {
			bottom: 0px;
		}
	}
	.kety-name {
		a {
			font-size: $fs-14;
		}
	}
	.kety-link {
		a {
			margin-bottom: 5px;
		}
	}
	.blog.blog-single {
		.port-navigation {
			.port-photo {
				width: 104px;
			}
			.port-arrow {
				height: 62px;
				width: 30px;
				i {
					line-height: 62px;
				}
			}
		}
	}
	.single-portfolio-post {
		.port-navigation {
			.port-photo {
				width: 104px;
			}
			.port-arrow {
				height: 62px;
				width: 30px;
				i {
					line-height: 62px;
				}
			}
		}
	}
	.accordion.animated {
		.acd-group.acd-active {
			.acd-heading {
				font-size: $fs-20;
			}
		}
	}
	.our-service {
		.section-title {
			margin-bottom: 10px;
		}
	}
	.team.team-list {
		h5 {
			font-size: $fs-18;
		}
	}
	.contact-2 {
		.g-map {
			padding-top: 50%;
		}
	}
	.clients-box {
		.clients-photo {
			margin-bottom: 20px;
			width: 100%;
		}
	}
	.countdown.round {
		display: block;
		margin: 0 auto 20px;
	}
	.coming-soon-form.contact-form {
		input {
			width: 100%;
		}
	}
	.add-banner-2 {
		.add-banner-content {
			h2 {
				font-size: $fs-24;
			}
			h3 {
				font-size: $fs-20;
				line-height: $l-height-20;
			}
			.button.small {
				padding: 5px 18px;
			}
		}
	}
	.slider-parallax.typer-banner.business {
		h1 {
			font-size: $fs-24;
			line-height: $l-height-28;
		}
		p {
			font-size: $fs-18;
			line-height: $l-height-28;
			margin-top: 10px !important;
		}
	}
	.counter.big-counter {
		.timer {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.action-box.theme-bg {
		padding: 30px 0;
	}
	.slider-parallax.banner-personal {
		.slider-content {
			span {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
			h1 {
				font-size: $fs-40;
				line-height: $l-height-40;
				margin-top: -20px;
				strong {
					font-size: 48px;
					line-height: $l-height-50;
				}
			}
		}
	}
	.christmas-form-content {
		h2 {
			font-size: $fs-24;
			line-height: $l-height-24;
		}
	}
	.christmas-event {
		.tab {
			.nav.nav-tabs {
				li {
					a {
						font-size: $fs-14;
					}
				}
			}
		}
		.blog-date {
			span {
				font-size: $fs-28;
				display: inline-block;
			}
		}
	}
	.portfolio-home {
		.portfolio-title.section-title {
			.button.mt-30 {
				margin-top: 20px !important;
			}
		}
	}
	.countdown.small {
		margin: 10px 4px 0;
	}
	.section-title-movie {
		h2 {
			padding: 10px 30px;
		}
	}
	.movie-story {
		b {
			font-size: $fs-26;
		}
	}
	.movie-banner {
		.countdown.round.small {
			margin-bottom: 5px;
		}
	}
	.law-banner {
		.slider-content {
			h1 {
				font-size: $fs-22;
				line-height: 22px;
			}
			p {
				font-size: $fs-16;
			}
		}
	}
	.law-timeline {
		.timeline-centered {
			.timeline-entry {
				.timeline-entry-inner {
					.timeline-year {
						left: 0;
						top: 25px;
					}
				}
			}
		}
	}
	.portfolio-parallax-banner {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.coming-soon-big {
		.countdown.medium {
			span {
				font-size: $fs-24;
				line-height: $l-height-24;
			}
			p {
				font-size: $fs-14;
				line-height: 14px;
			}
		}
	}
	.error-05 {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
	}
	.error-03 {
		.content {
			h2 {
				font-size: $fs-28;
				line-height: $l-height-28;
			}
			h1 {
				font-size: $fs-32;
				line-height: 32px;
			}
			p {
				font-size: $fs-22;
			}
		}
		.error-404 {
			h1 {
				font-size: $fs-70;
				line-height: $l-height-70;
			}
			h2 {
				font-size: $fs-50;
				line-height: $l-height-50;
			}
		}
	}
	.error-04 {
		h1 {
			font-size: $fs-100;
			line-height: $l-height-100;
		}
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
		p {
			font-size: $fs-18;
		}
	}
	.error-06 {
		h1 {
			font-size: $fs-100;
			line-height: $l-height-100;
		}
		h2 {
			.error-07 {
				h2 {
					font-size: $fs-30;
					line-height: $l-height-30;
				}
			}
		}
	}
	.error-07 {
		h1 {
			font-size: $fs-100;
			line-height: $l-height-100;
		}
	}
	.error-09 {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.login.height-100vh {
		overflow: hidden;
	}
	.login {
		.remember-checkbox {
			a {
				display: block;
				float: inherit !important;
				margin-top: 10px;
			}
		}
	}
	.about-mission {
		.popup-content {
			padding: 30px 30px 10px 30px;
			h2 {
				font-size: $fs-14;
				line-height: $l-height-20;
			}
		}
		.popup-video-image {
			a {
				i {
					width: 40px;
					height: 40px;
					top: 10px;
					line-height: $l-height-40;
				}
			}
		}
	}
	.checkout-page {
		.pl-50.pr-50.pt-50.pb-50 {
			padding: 20px !important;
		}
		.pl-40 {
			padding: 0 !important;
		}
	}
	#main-slider {
		.slider-content {
			a {
				font-size: $fs-12;
			}
			h1 {
				font-size: $fs-16;
				line-height: $l-height-24;
				margin-bottom: 10px;
			}
			p {
				display: none;
			}
			.slider-right {
				.play-video.border-video {
					margin-top: 0 !important;
				}
			}
		}
	}
	#main-slider.shop-04-banner {
		.slider-content {
			h1 {
				font-size: $fs-20;
				line-height: $l-height-28;
			}
		}
	}
	#main-slider.shop-05-banner {
		.slider-content {
			h1 {
				font-size: $fs-24;
			}
		}
	}
	.shop-05-deal {
		h2 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
	}
	.book-counter {
		.counter {
			margin-top: 0;
		}
	}
	.book-order {
		.googleplay {
			img {
				height: inherit;
			}
		}
	}
	.plumber-banner {
		.slider-content-image {
			img {
				display: none;
				height: 250px;
			}
			left: 10%;
		}
		h1 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
		.slider-content-text {
			right: 0;
		}
	}
	.product-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
		}
	}
	.product-about {
		h4 {
			font-size: $fs-18;
			line-height: $l-height-30;
		}
		p {
			font-size: $fs-18;
			line-height: $l-height-30;
		}
	}
	.product-video {
		.product-video-box {
			min-height: 500px;
		}
	}
	.fish-banner {
		.slider-content {
			.fish-banner-image {
				img {
					height: 170px;
				}
			}
		}
	}
	.fish-gallery {
		.section-title {
			h2 {
				font-size: $fs-30;
			}
		}
	}
	.fish-footer {
		.fish-image {
			margin-top: 90px;
		}
	}
	.fish-gallery-testimonial {
		padding-bottom: 0;
	}
	.book-now {
		.book-now-box {
			width: 90%;
		}
	}
	.hotel-banner {
		.slider-content {
			h1 {
				font-size: $fs-30;
				line-height: $l-height-30;
			}
			p {
				font-size: $fs-16;
				line-height: $l-height-24;
			}
			span {
				font-size: $fs-16;
				line-height: $l-height-24;
			}
		}
	}
	.hotel-countdown-main {
		padding: 80px 20px;
	}
	.section-title {
		h2 {
			font-size: $fs-24;
		}
	}
	.hotel-banner-checkout {
		.checkout {
			.checkout-name {
				h3 {
					font-size: $fs-20;
				}
			}
		}
	}
	.burger-contact {
		.burger-contact-bg {
			left: 20px;
			top: 100px;
		}
	}
	.burger-contact-box {
		padding: 30px;
	}
	.burger-custom-content {
		h2 {
			font-size: $fs-30;
		}
	}
	.burger-banner-ani {
		width: 300px;
	}
	.burger-item-06 {
		top: 220px;
	}
	.burger-item-05 {
		top: 180px;
	}
	.burger-item-04 {
		top: 165px;
	}
	.burger-item-03 {
		top: 120px;
	}
	.burger-item-02 {
		top: 105px;
	}
	.burger-banner {
		&:hover {
			.burger-banner-ani {
				.burger-item-01 {
					top: 10px;
				}
				.burger-item-04 {
					top: 160px;
				}
				.burger-item-05 {
					top: 165px;
				}
			}
		}
	}
	.skateboard-box {
		.skateboard-box-content {
			padding-left: 109px;
		}
	}
	.skateboard-about {
		h2 {
			font-size: $fs-40;
			line-height: $l-height-40;
		}
	}
	.bitcoin-banner {
		h1 {
			font-size: $fs-50;
			line-height: $l-height-50;
		}
		h3 {
			font-size: $fs-14;
			span {
				font-size: $fs-16;
			}
		}
	}
	.skateboard-banner {
		.slider-content-image {
			img {
				height: 450px;
			}
		}
	}
	.footer-box {
		.box-link {
			display: block;
			padding-left: 0;
		}
	}
	.testimonial.left_pos {
		margin-left: 0;
	}
	.counter-law {
		padding: 20px 20px 20px 20px;
	}
	.tab {
		.nav.nav-tabs {
			li {
				a {
					margin-bottom: 5px;
				}
			}
		}
	}
	.rc-anchor-normal {
		width: 290px !important;
	}
	.portfolio-03-about {
		margin-top: 90px;
	}
	.maintenance-main {
		h1 {
			font-size: $fs-30;
		}
	}
	.modal-shop {
		padding: 50px 20px;
	}
	.plumber-contact-bg {
		padding: 0px;
	}
	.search-no-result {
		padding-top: 0px;
		i {
			font-size: $fs-60;
		}
		.bg-title {
			h2 {
				font-size: 110px;
			}
		}
	}
	.barber-banner {
		.slider-content {
			h1 {
				font-size: $fs-18;
				line-height: $l-height-24;
			}
			p {
				font-size: $fs-14;
				line-height: $l-height-20;
				letter-spacing: 3px;
			}
		}
	}
	.barber {
		.about-history {
			margin: 40px 0px 30px 0px;
		}
	}
	.choose-us-bg {
		padding: 40px 15px;
	}
	.listing {
		ul {
			li {
				font-size: $fs-14;
			}
		}
	}
	.promo-text {
		h2 {
			font-size: $fs-22;
			line-height: 26px;
		}
	}
	.spa-meet {
		.team {
			.team-description {
				.team-info {
					h3 {
						font-size: $fs-28;
					}
				}
			}
		}
	}
	.spa-promotion {
		.promo {
			h2 {
				font-size: $fs-16;
				line-height: $l-height-20;
			}
		}
	}
	.feature-step {
		padding: 130px 30px;
	}
	.tattoo-banner {
		h1 {
			font-size: $fs-34;
			line-height: $l-height-50;
		}
	}
	.jobs-banner {
		.button {
			display: none;
		}
	}
	.marketing-tab {
		.tab {
			.nav.nav-tabs {
				li {
					a {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.marketing-banner {
		.slider-content {
			h1 {
				font-size: $fs-28;
				line-height: 36px;
			}
		}
	}
	.marketing-testimonial {
		.p-5 {
			padding: 15px !important;
		}
		.testimonial.clean {
			padding-left: 10px;
		}
	}
	.location {
		h2 {
			margin-bottom: 0 !important;
		}
		.button {
			padding: 6px 20px;
		}
	}
	.office-1.p-5 {
		padding: 30px !important;
	}
	.media {
		.media-icon {
			span {
				font-size: $fs-28;
			}
		}
	}
	.portfolio-creative {
		h1 {
			font-size: $fs-30;
		}
	}
	.skill-counter {
		padding: 20px;
	}
	.business-banner-02 {
		h1 {
			font-size: $fs-32;
			line-height: $l-height-30;
		}
	}
	.business-banner-03 {
		h1 {
			font-size: $fs-30;
			line-height: $l-height-30;
		}
		span {
			margin-bottom: 20px !important;
		}
		.button.large {
			padding: 7px 10px;
			font-size: $fs-12;
		}
	}
	.business-video {
		.business-video-box {
			.vertical-align {
				padding: 20px;
			}
		}
	}
	.business-feature.feature-text {
		margin: 10px 0px !important;
	}
	.business-banner-04 {
		p {
			display: none;
		}
		h1 {
			font-size: $fs-30;
		}
	}
	.business-feature-top {
		padding: 0;
	}
	.business-banner-05 {
		h1 {
			font-size: $fs-22;
		}
		p {
			font-size: $fs-14;
		}
	}
	.business-banner-06 {
		p {
			display: none;
		}
		h1 {
			margin-bottom: 0;
			font-size: $fs-24;
		}
	}

}
