.mfp-container {
	cursor: auto !important;
}
.modal-simple-content {
	background: $white;
	padding: 50px 30px;
	border-radius: 3px;
	margin: 0 auto;
	max-width: 600px;
}
.modal-shop {
	background: $black;
	padding: 50px 60px;
	border-radius: 3px;
	margin: 0 auto;
	max-width: 650px;
	h2 {
		font-size: $fs-60;
		line-height: $l-height-60;
		color: $white;
		font-weight: 500;
		margin-top: 15px;
	}
	span {
		display: block;
	}
	p {
		color: rgba(255,255,255,0.5);
	}
	.button {
		padding: 12px 20px;
	}
}
.modal-subscribe {
	background: $white;
	margin: 0 auto;
	max-width: 500px;
	.subscribe-icon {
		height: 300px;
		background: #84ba3f;
		display: block;
		overflow: hidden;
		i {
			color: rgba(0, 0, 0, 0.2);
			font-size: 270px;
			transform: rotate(-30deg);
			-o-transform: rotate(-30deg);
			-ms-transform: rotate(-30deg);
			-moz-transform: rotate(-30deg);
		}
	}
	.subscribe-content {
		padding: 30px;
		p {
			color: $black;
		}
	}
}
.modal-login {
	background: $white;
	border-radius: 3px;
	margin: 0 auto;
	max-width: 600px;
	.login-bg {
		.login-title {
			border-radius: 0;
		}
	}
}
.modal-register {
	background: $white;
	border-radius: 3px;
	margin: 0 auto;
	max-width: 600px;
	.register-title {
		padding: 40px 30px;
		text-align: center;
		img {
			height: 40px;
		}
	}
	.register-form {
		padding: 50px;
	}
}
.modal-video {
	background: $white;
	padding: 50px 30px;
	border-radius: 3px;
	margin: 0 auto;
	max-width: 600px;
}
.image-source-link {
	color: #98C3D1;
}
.mfp-with-zoom {
	.mfp-container {
		opacity: 0;
		-webkit-backface-visibility: hidden;
/*		@include transition (all, 0.3s, ease);*/
	}
}
.mfp-with-zoom.mfp-bg {
	opacity: 0;
/*	@include transition (all, 0.3s, ease);*/
}
.mfp-with-zoom.mfp-ready {
	.mfp-container {
		opacity: 1;
	}
}
.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}
.mfp-with-zoom.mfp-removing {
	.mfp-container {
		opacity: 0;
	}
}
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}
