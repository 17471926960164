
.section-title {
	margin-bottom: 40px;
	position: relative;
	.title {
		margin-top: 0px;
		position: relative;
	}
	.subtitle {
		font-size: $fs-12;
		letter-spacing: 1px;
		font-weight: $fw-7;
		margin-bottom: 10px;
		text-transform: uppercase;
	}
	p {
		margin-bottom: 0px;
		font-size: 20px;
		font-weight: $fw-4;
	}
	span.theme-bg {
		background: $theme-color;
		color: $white;
		padding: 0 15px;
	}
	&:nth-child(odd) {
		.title-effect {
			&::before {
				background: $theme-color;
			}
		}
	}
}
.section-title.line {
	.title {
		padding-bottom: 20px;
		&:before {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 80px;
			height: 3px;
			background: $theme-color;
			content: "";
		}
	}
}
.section-title.center {
	.title {
		&:before {
			left: 50%;
			margin-left: -35px;
		}
	}
}
.section-title.right {
	.title {
		&:before {
			right: 0;
			left: inherit;
			margin-left: inherit;
		}
	}
}
.section-title.dark-bg {
	.title {
		color: $white;
	}
	.subtitle {
		color: $white;
	}
	p {
		color: $white;
	}
	span.theme-bg {
		background: $white;
		color: $theme-color;
	}
}
.section-title.dark-bg.line {
	.title {
		&:before {
			background: $white;
		}
	}
}
.title-effect {
	z-index: 99;
	position: relative;
	display: inline-block;
	transition: transform 0.5s, color 0.5s;
	transition-timing-function: cubic-bezier(0.2,1,0.3,1);
	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 50%;
		left: 0;
		bottom: 0;
		opacity: 0.2;
		transform: scale3d(0,1,1);
		transform-origin: 0% 50%;
		transition: transform 0.5s;
		transition-timing-function: cubic-bezier(0.2,1,0.3,1);
	}
}
section {
	&:hover {
		.title-effect {
			&::before {
				transform: scale3d(1,1,1);
			}
			&:nth-child(even) {
				color: $theme-color;
			}
		}
		.title-effect.dark {
			&:nth-child(even) {
				color: $white;
			}
		}
	}
	&:focus {
		.title-effect {
			&::before {
				transform: scale3d(1,1,1);
			}
			&:nth-child(even) {
				color: $theme-color;
			}
		}
		.title-effect.dark {
			&:nth-child(even) {
				color: $white;
			}
		}
	}
	&:nth-child(odd) {
		.title-effect.dark {
			&::before {
				background: $white;
			}
		}
	}
}
.section-title.line-dabble {
	.title {
		position: relative;
		padding-bottom: 15px;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			z-index: 1;
			background: rgba(234, 234, 234, 1);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 40px;
			height: 2px;
			z-index: 2;
			background: $theme-color;
		}
	}
}

