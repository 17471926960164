.pager {
	margin: 0;
}
.affix {
	left: 0;
	right: 0;
}
.btn-primary {
	background-color: $theme-color;
	border-color: $theme-color;
	&:hover {
		background-color: $black;
		border-color: $black;
		color: $white;
	}
	&:focus {
		background-color: $black;
		border-color: $black;
		color: $white;
	}
	.badge {
		color: $theme-color;
	}
	&:active.focus {
		background-color: $theme-color;
		border-color: $theme-color;
	}
	&:active {
		&:focus {
			background-color: $theme-color;
			border-color: $theme-color;
		}
		&:hover {
			background-color: $theme-color;
			border-color: $theme-color;
		}
	}
}
.btn-primary.focus {
	background-color: $black;
	border-color: $black;
	color: $white;
}
.dropdown-menu {
	>.active {
		>a {
			background-color: $theme-color;
			&:focus {
				background-color: $theme-color;
			}
			&:hover {
				background-color: $theme-color;
			}
		}
	}
}
select.input-sm {
	height: 50px;
}
.nice-select {
	&:active {
		border-color: $theme-color !important;
	}
	&:focus {
		border-color: $theme-color !important;
	}
}
.nice-select.open {
	border-color: $theme-color !important;
}
.list-group-item.active {
	>.badge {
		color: $theme-color;
	}
}
.nav-pills {
	>.active {
		>a {
			>.badge {
				color: $theme-color;
			}
		}
	}
}
.nav {
	.open {
		>a {
			border-color: $theme-color;
			&:focus {
				border-color: $theme-color;
			}
			&:hover {
				border-color: $theme-color;
			}
		}
	}
}
.btn-primary.active.focus {
	background-color: $theme-color;
	border-color: $theme-color;
}
.btn-primary.active {
	&:focus {
		background-color: $theme-color;
		border-color: $theme-color;
	}
	&:hover {
		background-color: $theme-color;
		border-color: $theme-color;
	}
}
.open {
	>.dropdown-toggle.btn-primary.focus {
		background-color: $theme-color;
		border-color: $theme-color;
	}
	>.dropdown-toggle.btn-primary {
		&:focus {
			background-color: $theme-color;
			border-color: $theme-color;
		}
		&:hover {
			background-color: $theme-color;
			border-color: $theme-color;
		}
	}
}
.bootstrap-datetimepicker-widget {
	table {
		td.active {
			background: $theme-color;
			&:hover {
				background: $theme-color;
			}
		}
		td {
			span.active {
				background-color: $theme-color;
			}
		}
	}
}
.input-group-prepend {
	&:last-child {
		border: 0;
	}
	border-left: 1px solid #e0e0e0 !important;
	padding: 16px 26px 16px 22px;
	background: $gray-bg-02;
	border-radius: 3px;
}
.datetimepicker {
	.form-control {
		&:focus {
			box-shadow: none;
			background-color: $gray-bg-02;
		}
	}
}
.modal1 {
	.mfp-close {
		display: none;
	}
}
.navbar {
	>.container {
		.navbar-brand {
			margin-left: 0;
		}
	}
	>.container-fluid {
		.navbar-brand {
			margin-left: 0;
		}
	}
}
.dropdown-item.active {
	background-color: $theme-color;
}
.dropdown-item {
	&:active {
		background-color: $theme-color;
	}
}


/*************************
    Process step
*************************/
.stepwizard-step {
	p {
		margin-top: 10px;
	}
	button[disabled] {
		opacity: 1 !important;
		filter: alpha(opacity=100) !important;
	}
	display: table-cell;
	text-align: center;
	position: relative;
}
.stepwizard-row {
	display: table-row;
	&:before {
		top: 24px;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 100%;
		height: 1px;
		background-color: #ccc;
	}
}
.stepwizard {
	display: table;
	width: 100%;
	position: relative;
}
.btn-circle {
	width: 50px;
	height: 50px;
	text-align: center;
	padding: 6px 0;
	font-size: $fs-12;
	line-height: 1.428571429;
	border-radius: 3px;
}


/*************************
    responsive-utilities
*************************/
.responsive-utilities {
	.is-visible {
		color: #468847;
		background-color: #dff0d8 !important;
	}
	.is-hidden {
		color: #ccc;
		background-color: #f9f9f9 !important;
	}
}
.hidden-on {
	.col-6 {
		.visible {
			color: #468847;
			background-color: #dff0d8;
			border: 1px solid #d6e9c6;
		}
	}
}
.visible-on {
	.col-6 {
		.visible {
			color: #468847;
			background-color: #dff0d8;
			border: 1px solid #d6e9c6;
		}
	}
}


/*************************************
          animations
 **************************************/
.bg {
	padding: 30px;
	border-radius: 3px;
	margin-bottom: 30px;
}
.bg-01 {
	background: #fe80c0;
}
.bg-02 {
	background: #b24a7d;
}
.bg-03 {
	background: #76bcff;
}
.bg-04 {
	background: #8d708e;
}
.bg-05 {
	background: #807fff;
}
.bg-06 {
	background: #e74c3c;
}
.bg-07 {
	background: #16a085;
}
.bg-08 {
	background: #f1c40f;
}
.bg-09 {
	background: #27ae60;
}
.bg-10 {
	background: #1abc9c;
}
.bg-11 {
	background: #9b59b6;
}
.bg-12 {
	background: #3498db;
}
.bg-13 {
	background: #ff6d3a;
}
.bg-14 {
	background: #2ecc71;
}
.bg-15 {
	background: #32cdc7;
}
.bg-16 {
	background: #009a9a;
}


/*************************
	   columns
*************************/
.columns {
	.gray-bg {
		padding: 15px;
		border-radius: 3px;
	}
}

/*************************
  data table
*************************/
.table {
	margin-bottom: 0;
	td {
		vertical-align: middle;
	}
	th {
		vertical-align: middle;
	}
}
.table-responsive {
	>.table-bordered {
		border: 1px solid #dee2e6;
	}
}
.table-bordered {
	tbody {
		tr {
			td {
				border-color: $border-color;
				font-size: $fs-14;
				padding: 12px 20px;
			}
		}
	}
	thead {
		tr {
			th {
				border-color: $border-color;
				font-size: $fs-14;
				padding: 12px 20px;
			}
		}
	}
}
.table-1 {
	thead {
		background: $theme-color;
		color: $white;
		text-align: center;
		tr {
			th {
				border: medium none;
				font-weight: normal;
				text-align: center;
			}
		}
	}
	tbody {
		tr {
			td {
				color: #7e8890;
				font-size: $fs-14;
				padding: 12px 0;
				text-align: center;
			}
		}
	}
}
.table-2.table {
	thead {
		background: transparent;
		color: $theme-color;
		font-weight: 600;
		text-align: center;
		tr {
			th {
				border: 1px solid $theme-color !important;
				text-align: center;
			}
		}
	}
}
.table-3.table {
	thead {
		background: #dfdfdf;
		color: $black;
		font-weight: 600;
		text-align: center;
		tr {
			th {
				border: none;
				text-align: center;
			}
		}
	}
}


/*************************
	Typography
*************************/
.typography {
	del {
		font-size: $fs-14;
	}
	.mark {
		background: $theme-color;
		color: $white;
	}
}
s {
	font-size: $fs-14;
}
u {
	font-size: $fs-14;
}
mark {
	background: $theme-color;
	color: $white;
	background: $theme-color;
	color: $white;
	padding: 0 5px;
	margin-right: 3px;
}
del {
	background: $theme-color;
	color: $white;
	padding: 0 5px;
	margin-right: 3px;
}



/*************************
   alerts and callouts
*************************/
.alerts-and-callouts {
	font-size: $fs-14;
	.bs-callout {
		padding: 20px;
		margin: 20px 0;
		border: 1px solid $border-color;
		border-left-width: 5px;
		border-radius: 3px;
		h4 {
			margin-top: 0;
			margin-bottom: 5px;
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
		code {
			border-radius: 3px;
		}
	}
	.bs-callout+.bs-callout {
		margin-top: -5px;
	}
	.bs-callout-default {
		border-left-color: #777;
		h4 {
			color: #777;
		}
	}
	.bs-callout-primary {
		border-left-color: #428bca;
		h4 {
			color: #428bca;
		}
	}
	.bs-callout-success {
		border-left-color: #5cb85c;
		h4 {
			color: #5cb85c;
		}
	}
	.bs-callout-danger {
		border-left-color: #d9534f;
		h4 {
			color: #d9534f;
		}
	}
	.bs-callout-warning {
		border-left-color: #f0ad4e;
		h4 {
			color: #f0ad4e;
		}
	}
	.bs-callout-info {
		border-left-color: #5bc0de;
		h4 {
			color: #5bc0de;
		}
	}
}


@media screen and (max-width: 767px) {
.animated {
	margin-top: 30px;
}
.data-table {
	.table {
		tbody {
			tr {
				td {
					padding: 12px 10px;
				}
			}
		}
	}
}
.tab-2 {
	#tabs {
		.tabs {
			width: 100%;
		}
	}
}
.nav-tabs {
	li {
		a {
			font-size: $fs-14;
		}
	}
}
.tab-1 {
	li {
		margin-right: 2px;
	}
}

}

 /*************************
        isotope
*************************/
.isotope-item{z-index:2}.isotope-hidden.isotope-item{pointer-events:none;z-index:1}.isotope,.isotope .isotope-item{-webkit-transition-duration:.8s;-moz-transition-duration:.8s;-ms-transition-duration:.8s;-o-transition-duration:.8s;transition-duration:.8s}.isotope{-webkit-transition-property:height,width;-moz-transition-property:height,width;-ms-transition-property:height,width;-o-transition-property:height,width;transition-property:height,width}.isotope .isotope-item{-webkit-transition-property:-webkit-transform,opacity;-moz-transition-property:-moz-transform,opacity;-ms-transition-property:-ms-transform,opacity;-o-transition-property:-o-transform,opacity;transition-property:transform,opacity}.isotope .isotope-item.no-transition,.isotope.no-transition,.isotope.no-transition .isotope-item{-webkit-transition-duration:0s;-moz-transition-duration:0s;-ms-transition-duration:0s;-o-transition-duration:0s;transition-duration:0s}

 /*************************
        lists panels
*************************/
.list-group-item.active {
	background-color: $theme-color;
	border-color: $theme-color;
	&:focus {
		background-color: $theme-color;
		border-color: $theme-color;
	}
	&:hover {
		background-color: $theme-color;
		border-color: $theme-color;
	}
}
.panel-primary {
	border-color: $theme-color;
	>.panel-heading {
		background-color: $theme-color;
		border-color: $theme-color;
	}
}
.badge-primary {
	background: $theme-color !important;
}
.nav-pills {
	.nav-link.active {
		background: $theme-color !important;
	}
	.show {
		>.nav-link {
			background: $theme-color !important;
		}
	}
}
.bg-primary {
	background: $theme-color !important;
}
.border-primary {
	border-color: $theme-color !important;
}


 /*************************
        lists panels
*************************/
.modal {
	z-index: 99999;
	background-color: rgba(32,32,32,.8)!important;
}

.modal-price{
	max-width: 900px;
}
.modal-backdrop {
	z-index: 9999;
}
.modal-dialog {
	margin: 100px auto;
}
.modal-header {
	border: 0;
	padding: 30px 30px 0 30px;
	.close {
		position: relative;
		z-index: 99;
	}
}
.modal-footer {
	border: 0;
	padding: 30px 30px 30px 30px;
	text-align: left;
}
.modal-body {
	padding: 30px 30px 0 30px;
}


 /*************************
       extra class
*************************/
.image-content {
	float: left;
	width: 50%;
	margin: 0 20px 20px 0;
}
select.form-control-sm {
	&:not([size]) {
		&:not([multiple]) {
			height: calc(1.8125rem + 22px);
		}
	}
}
.dataTables_length {
	.form-control {
		padding: 15px 8px 15px 20px;
	}
}
div.dataTables_wrapper {
	div.dataTables_paginate {
		margin-top: 15px;
	}
	div.dataTables_info {
		margin-top: 15px;
	}
}

