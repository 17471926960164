.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .table-custom {
        p {
            display: none;
        }

        thead {
            background: #dfdfdf;
            color: #323232;
            font-weight: 600;
            text-align: center;
        }

        .table{
            table {
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;
                border-collapse: collapse;
                border: 1px solid #dee2e6;

                thead th {
                    vertical-align: bottom;
                    border-bottom: 2px solid #dee2e6;
                }

                th, td {
                    vertical-align: middle;
                }

                td, th {
                    padding: 0.75rem;
                    vertical-align: top;
                    border: 1px solid #dee2e6;
                }
            }
        }
    }

    .table-striped{
        tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
}

table {
    width: 100%;
    border-collapse: collapse;
}
