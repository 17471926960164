
/* Dots */
.owl-carousel {
	.owl-item {
		img {
			width: auto;
		}
	}
	.owl-nav {
		cursor: pointer;
		z-index: 999;
		width: 100%;
		.owl-prev {
			display: block;
			position: absolute;
			text-indent: inherit;
			width: auto;
			cursor: pointer;
/*			@include transition (all, 0.2s, ease);*/
			left: 10px;
			display: block;
			position: absolute;
			text-indent: inherit;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			margin: 0 !important;
			display: inline-block;
			margin: 14px 2px 0;
		}
		.owl-next {
			display: block;
			position: absolute;
			text-indent: inherit;
			width: auto;
			cursor: pointer;
			/*@include transition (all, 0.2s, ease);*/
			right: 10px;
			display: block;
			position: absolute;
			text-indent: inherit;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			margin: 0 !important;
			display: inline-block;
			margin: 14px 2px 0;
		}
		i {
			font-size: $fs-20;
			width: 36px;
			height: 36px;
			line-height: $l-height-36;
			display: inline-block;
			color: $white;
			border-radius: 3px;
			background: $theme-color;
			border: 0px solid #3d3d3d;
			font-weight: normal;
			text-align: center;
		/*	@include transition (all, 0.25s, ease);*/
			&:hover {
				background: #333;
				color: $white;
			}
		}
	}
	.owl-controls {
		.owl-dot {
			margin: 0;
			display: inline-block;
		}
	}
	.owl-dots {
		display: inline-block;
		margin-top: 20px;
		text-indent: inherit;
		width: 100%;
		cursor: pointer;
		text-align: center;
		.owl-dot {
			span {
				background: #ddd;
				display: inline-block;
				width: 12px;
				min-height: 12px;
				margin-top: 5px;
				border-radius: 3px;
/*				@include transition (all, 0.5s, ease);*/
				cursor: pointer;
			}
			&:hover {
				span {
					background: $theme-color;
					min-height: 17px;
					margin-top: 0;
				}
			}
		}
		.owl-dot.active {
			span {
				background: $theme-color;
				min-height: 17px;
				margin-top: 0;
			}
		}
	}
	.owl-dot {
		display: inline-block;
		margin: 14px 2px 0;
	}
}
.bottom-nav {
	.owl-nav {
		bottom: 0;
		top: inherit;
		.owl-next {
			right: 46%;
			bottom: -20px;
			top: inherit;
			transform: inherit;
		}
		.owl-prev {
			left: 46%;
			bottom: -20px;
			top: inherit;
			transform: inherit;
		}
	}
}
.bottom-center-dots {
	.owl-dots {
		bottom: 30px !important;
		position: absolute;
		.owl-dot {
			span {
				background: $white;
			}
		}
	}
}
.bottom-right-dots {
	.owl-dots {
		bottom: 30px !important;
		position: absolute;
		right: 30px;
		width: auto;
		.owl-dot {
			span {
				background: $white;
			}
		}
	}
}

