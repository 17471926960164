.image-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.content-big-image {
  text-align: center;
  color: white;
}

.title-big-image {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 20px;
  color: #FFFFFF;
  font-family: Montserrat, sans-serif;
}

.accent-big-image {
  margin-bottom: 10px;
  font-size: 67px;
  font-weight: 600;
  color: #354a6b;
  font-family: Montserrat;
  padding: 0;
}

.text-big-image {
  font-size: 40px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  margin-bottom: 10px;
  text-align: center;
}

.button-big-image {
  display: inline-block;
  padding: 12px 30px;
  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #354a6b;
  border-radius: 3px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button-big-image:hover {
  background-color: #ffffff;
  color: #000000;
}

@media (max-width: 1200px) {
  .title-big-image {
    font-size: 16px;
    letter-spacing: 15px;
  }

  .accent-big-image {
    font-size: 60px;
  }

  .text-big-image {
    font-size: 35px;
  }

  .button-big-image {
    padding: 10px 25px;
    font-size: 11px;
  }
}

@media (max-width: 992px) {
  .title-big-image {
    font-size: 14px;
    letter-spacing: 10px;
  }

  .accent-big-image {
    font-size: 50px;
  }

  .text-big-image {
    font-size: 30px;
  }

  .button-big-image {
    padding: 8px 20px;
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .title-big-image {
    font-size: 12px;
    letter-spacing: 5px;
  }

  .accent-big-image {
    font-size: 40px;
    margin-bottom: 5px;
  }

  .text-big-image {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .button-big-image {
    padding: 6px 15px;
    font-size: 9px;
  }
}

@media (max-width: 576px) {
  .title-big-image {
    font-size: 10px;
    letter-spacing: 2px;
  }

  .accent-big-image {
    font-size: 30px;
    margin-bottom: 2px;
  }

  .text-big-image {
    font-size: 20px;
    margin-bottom: 2px;
  }

  .button-big-image {
    padding: 5px 10px;
    font-size: 8px;
  }
}
