.tooltip-content {
  position: relative;
  display: inline-block;
  float: right;

  .tooltip-content-text {
    visibility: hidden;
    opacity: 0;
    width: 140px;
    background: none !important;
    background-color: black !important;
    color: #fff;
    text-align: center;
    border-radius: 6px !important;
    min-width: 120px !important;
    height: 28px !important;
    line-height: 18px !important;
    padding: 5px;
    position: absolute;
    z-index: 9999;
    bottom: 80%;
    left: 38%;
    transform: translateX(-54%);
    white-space: nowrap;
    transition: opacity 0.3s ease;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-54%);
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  &:hover .tooltip-content-text {
    visibility: visible;
    opacity: 1;
  }
}
