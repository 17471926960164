
.tab {
	.nav.nav-tabs {
		border: none;
		margin-bottom: 20px;
		li {
			margin-bottom: 0px;
			float: none;
			display: inline-block;
			a {
				border: none;
				line-height: normal;
				border-radius: 0px;
				padding: 12px 30px;
				background: $gray-bg-02;
				color: $text-black;
				border: 1px solid $border-color;
				i {
					padding-right: 5px;
				}
			}
		}
		>li {
			>a.active {
				background: $theme-color;
				color: $white;
				border-color: transparent;
			}
		}
	}
}
.nav.nav-tabs {
	>li {
		>a.active {
			&:focus {
				background: $theme-color;
				color: $white;
				border-color: transparent;
			}
			&:hover {
				background: $theme-color;
				color: $white;
				border-color: transparent;
			}
		}
	}
}
.nav-border {
	.nav.nav-tabs {
		li {
			a {
				margin-right: 0;
				border: 1px solid $border-color;
				background: transparent;
			}
		}
		>li {
			>a.active {
				color: $white;
				background: $theme-color;
				border-color: $theme-color;
				&:focus {
					color: $white;
					background: $theme-color;
					border-color: $theme-color;
				}
				&:hover {
					color: $white;
					background: $theme-color;
					border-color: $theme-color;
				}
			}
		}
	}
}
.tab-border {
	.nav.nav-tabs {
		margin-bottom: 0;
		>li {
			>a.active {
				color: $theme-color;
				background: $white !important;
				border-color: $border-color;
				border-bottom: 1px solid $white;
				&:focus {
					color: $theme-color;
					background: $white !important;
					border-color: $border-color;
					border-bottom: 1px solid $white;
				}
				&:hover {
					color: $theme-color;
					background: $white !important;
					border-color: $border-color;
					border-bottom: 1px solid $white;
				}
			}
		}
	}
	.tab-content {
		padding: 20px;
		border: 1px solid $border-color;
		margin-top: -1px;
	}
}
.nav-center {
	.nav.nav-tabs {
		text-align: center;
		display: block;
	}
}
.nav-right {
	.nav.nav-tabs {
		text-align: right;
		display: block;
	}
	.tab-content {
		text-align: right;
	}
}
.round {
	.nav.nav-tabs {
		li {
			a {
				border: none;
				line-height: normal;
				margin-right: 10px;
				border-radius: 90px;
				padding: 12px 30px;
				background: $gray-bg-02;
				color: $text-black;
			}
		}
	}
}
.shadow {
	.nav.nav-tabs {
		>li {
			>a.active {
				color: $theme-color;
				background: $white !important;
				border-color: $border-color;
				border-bottom: 0;
				box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
				&:focus {
					color: $theme-color;
					background: $white !important;
					border-color: $border-color;
					border-bottom: 0;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
				}
				&:hover {
					color: $theme-color;
					background: $white !important;
					border-color: $border-color;
					border-bottom: 0;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
}
.tab-vertical {
	.nav-tabs {
		float: left;
		width: 25%;
	}
	.nav.nav-tabs {
		li {
			display: block;
			width: 100%;
			a {
				border-right: 0;
				border-top: 0;
				padding: 14px 30px;
			}
			&:first-child {
				a {
					border-top: 1px solid $border-color;
				}
			}
		}
		>li {
			>a.active {
				border-bottom: 1px solid $border-color;
				&:focus {
					border-bottom: 1px solid $border-color;
				}
				&:hover {
					border-bottom: 1px solid $border-color;
				}
			}
		}
	}
	.tab-content {
		background-color: $white;
		border: 1px solid #e9e9e9;
		padding: 40px 30px;
		margin-left: 25%;
		line-height: $l-height-30;
		margin-top: 0;
		p {
			line-height: $l-height-30;
		}
	}
}
.vertical-right {
	.nav-tabs {
		float: right;
	}
	.nav.nav-tabs {
		li {
			a {
				border-right: 1px solid $border-color;
				border-left: 0;
			}
		}
	}
	.tab-content {
		margin-right: 24.8%;
		margin-left: inherit;
		text-align: right;
	}
}
.tab-dropdown {
	.tab-content {
		margin-top: 30px;
	}
}
.nav-pills {
	>li {
		>a.active {
			background: $theme-color;
			color: $white;
			&:focus {
				background: $theme-color;
				color: $white;
			}
			&:hover {
				background: $theme-color;
				color: $white;
			}
		}
	}
}
.tab.nav-bt {
	.nav {
		li {
			a {
				margin-right: 0px !important;
				border-top: 2px solid transparent;
				border-left: 0;
				border-right: 0;
				border-bottom: 0;
			}
		}
	}
	.nav.nav-tabs {
		>li {
			>a.active {
				background: $gray-bg-02;
				border-color: transparent;
				color: $text-black;
				border-top: 2px solid $theme-color;
			}
		}
	}
}
.tab.nav-bl {
	.nav {
		li {
			a {
				margin-right: 0px !important;
			}
		}
	}
	.nav.nav-tabs {
		>li {
			>a {
				border-left: 4px solid transparent;
				border-right: none;
				border-bottom: none;
				border-top: none;
			}
			>a.active {
				background: $gray-bg-02;
				border-color: transparent;
				color: $text-black;
				border-left: 4px solid $theme-color;
				border-bottom: none !important;
			}
		}
	}
}
.tab.nav-br {
	.nav {
		li {
			a {
				margin-right: 0px !important;
			}
		}
	}
	.nav.nav-tabs {
		>li {
			>a {
				border-right: 4px solid transparent;
				border-left: none;
				border-bottom: none;
				border-top: none;
			}
			>a.active {
				background: $gray-bg-02;
				border-color: transparent;
				color: $text-black;
				border-right: 4px solid $theme-color;
				border-bottom: none !important;
			}
		}
	}
}

