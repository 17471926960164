.nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: $gray-bg-02;
	border-radius: 3px;
	border: solid 1px $gray-bg-02;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 50px;
	line-height: 47px;
	outline: none;
	padding-left: 20px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
/*	@include transition (all, 0.2s, ease);*/
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	width: auto;
	&:hover {
		border-color: #d0dae5;
	}
	&:active {
		border-color: #88bfff;
		border-color: $white !important;
	}
	&:focus {
		border-color: #88bfff;
		border-color: $white !important;
	}
	&:after {
		border-bottom: 2px solid #90a1b5;
		border-right: 2px solid #90a1b5;
		content: '';
		display: block;
		height: 5px;
		margin-top: -4px;
		pointer-events: none;
		position: absolute;
		right: 22px;
		top: 50%;
		-webkit-transform-origin: 66% 66%;
		-ms-transform-origin: 66% 66%;
		transform-origin: 66% 66%;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
/*		@include transition (all, 0.15s, ease);*/
		width: 5px;
	}
	.list {
		max-height: 300px;
		overflow-y: auto;
		min-width: 100px;
		background-color: #fff;
		border-radius: 3px;
/*		@include box-shadow(0px, 0px, 1px, rgba(68, 88, 112, 0.11));*/
		box-sizing: border-box;
		margin-top: 4px;
		opacity: 0;
		padding: 0;
		pointer-events: none;
		position: absolute;
		top: 100%;
		left: 0;
		-webkit-transform-origin: 50% 0;
		-ms-transform-origin: 50% 0;
		transform-origin: 50% 0;
		-webkit-transform: scale(0.75) translateY(-21px);
		-ms-transform: scale(0.75) translateY(-21px);
		transform: scale(0.75) translateY(-21px);
		-webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
		transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
		z-index: 9;
		&:hover {
			.option {
				&:not(:hover) {
					background-color: transparent !important;
				}
			}
		}
	}
	.option {
		cursor: pointer;
		font-weight: 400;
		line-height: 40px;
		list-style: none;
		min-height: 40px;
		outline: none;
		padding-left: 18px;
		padding-right: 29px;
		text-align: left;
/*		@include transition (all, 0.2s, ease);*/
		&:hover {
			background-color: #f6f7f9;
		}
	}
	.option.focus {
		background-color: #f6f7f9;
	}
	.option.selected.focus {
		background-color: #f6f7f9;
	}
	.option.selected {
		font-weight: bold;
	}
	.option.disabled {
		background-color: transparent;
		color: #90a1b5;
		cursor: not-allowed;
	}
}
.nice-select.open {
	border-color: #88bfff;
	border-color: $white !important;
	&:after {
		-webkit-transform: rotate(-135deg);
		-ms-transform: rotate(-135deg);
		transform: rotate(-135deg);
	}
	.list {
		opacity: 1;
		pointer-events: auto;
		-webkit-transform: scale(1) translateY(0);
		-ms-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
	}
}
.nice-select.disabled {
	border-color: #e7ecf2;
	color: #90a1b5;
	pointer-events: none;
	&:after {
		border-color: #cdd5de;
	}
}
.nice-select.wide {
	width: 100%;
	.list {
		left: 0 !important;
		right: 0 !important;
	}
}
.nice-select.right {
	float: right;
	.list {
		left: auto;
		right: 0;
	}
}
.nice-select.small {
	font-size: 12px;
	height: 36px;
	line-height: 34px;
	&:after {
		height: 4px;
		width: 4px;
	}
	.option {
		line-height: 34px;
		min-height: 34px;
	}
}

