

.page-title {
	display: block;
	padding-top: 250px;
	padding-bottom: 100px;
	position: relative;
	.container {
		height: 100%;
		position: relative;
	}
	h1 {
		color: $white;
		font-weight: $fw-6;
	}
	h2 {
		color: $white;
		font-weight: $fw-6;
	}
	p {
		font-size: $fs-18;
		color: $white;
	}
	.page-title-name {
		display: inline-block;
		width: 60%;
	}
	.page-breadcrumb {
		float: right;
		width: 40%;
		text-align: right;
	}
}
.page-title.bg-overlay-black-60 {
	&:before {
		z-index: 0;
	}
}
ul.page-breadcrumb {
	li {
		span {
			color: $white;
			font-size: 14px;
		}
		color: $white;
		display: inline-block;
		list-style: none;
		i {
			font-size: 14px;
			padding: 0 7px;
		}
		a {
			color: $white;
			font-size: 14px;
			text-transform: capitalize;
			&:hover {
				color: $theme-color;
			}
		}
	}
	padding-top: 20px;
}
.page-title.gray-bg {
	h1 {
		color: $black;
	}
	p {
		color: $black;
	}
	ul.page-breadcrumb {
		li {
			a {
				color: $black;
				&:hover {
					color: $theme-color;
				}
			}
			color: $black;
			span {
				color: $theme-color;
			}
		}
	}
}
.page-title.center {
	text-align: center;
	.page-title-name {
		display: block;
		width: inherit;
	}
	.page-breadcrumb {
		float: none;
		width: inherit;
		text-align: center;
	}
}
.page-title.right {
	clear: both;
	.page-title-name {
		display: inline-block;
		float: right;
		text-align: right;
	}
	.page-breadcrumb {
		float: left;
		text-align: left;
	}
}
.page-title.small {
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	h1 {
		font-size: 22px;
		line-height: 24px;
		margin-bottom: 0px;
		margin-top: 8px;
	}
	.page-breadcrumb {
		padding-top: 10px;
	}
}
.page-title.pattern {
	h1 {
		color: $black;
	}
	p {
		color: $black;
	}
	ul.page-breadcrumb {
		li {
			a {
				color: $black;
				&:hover {
					color: $theme-color;
				}
			}
			color: $black;
			span {
				color: $theme-color;
			}
		}
	}
}
.page-title.light-overlay {
	position: relative;
	padding-top: 360px;
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		content: "";
		background: url(../../images/white-overlay.png);
		background-repeat: repeat-x;
		background-position: bottom;
	}
	h1 {
		color: $black;
	}
	p {
		color: $black;
	}
	ul.page-breadcrumb {
		li {
			a {
				color: $black;
				&:hover {
					color: $theme-color;
				}
			}
			color: $black;
			span {
				color: $theme-color;
			}
		}
	}
}
.page-title.dark-overlay {
	position: relative;
	padding-top: 360px;
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		right: 0;
		content: "";
		background: url(../../images/dark-overlay.png);
		background-repeat: repeat-x;
		background-position: bottom;
	}
}

