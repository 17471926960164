.clients-list {
	overflow: hidden;
	ul {
		li {
			float: left;
			position: relative;
			width: 20%;
			padding: 20px;
		}
	}
}
.clients-list.clients-border {
	ul {
		li {
			&:before {
				height: 100%;
				top: 0;
				left: -1px;
				border-left: 1px solid $border-color;
				content: '';
				position: absolute;
			}
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 0;
				top: auto;
				left: 0;
				bottom: -1px;
				border-bottom: 1px solid $border-color;
			}
		}
	}
}
.clients-list.column-4 {
	ul {
		li {
			float: left;
			position: relative;
			width: 25%;
			padding: 10px;
		}
	}
}
.clients-list.column-3 {
	ul {
		li {
			float: left;
			position: relative;
			width: 33.333%;
			padding: 10px;
		}
	}
}
.clients-list.column-2 {
	ul {
		li {
			float: left;
			position: relative;
			width: 50%;
			padding: 10px;
		}
	}
}
.clients-list.grayscale {
	img {
		-webkit-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		filter: grayscale(100%);
/*		@include transition (all, 0.5s, ease);*/
		&:hover {
			-webkit-filter: grayscale(0%);
			-o-filter: grayscale(0%);
			-moz-filter: grayscale(0%);
			filter: grayscale(0%);
		}
	}
}

