.text-end {
  text-align: right !important;
}

.copyright-link {
  color: #7198d4;
}

.footer-title {
  display: block;
}

.cookie-link {
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.title-theme {
  font-family: $font-hedding;
  color: $text-black;
  font-size: $fs-22;
  font-style: normal;
  font-weight: $fw-5;
  line-height: $l-height-26;
}

.pricing-title-h3 {
  font-size: 28px;
}

.pricing-prize-h2 {
  color: #363636;
}

.pricing-button {
  text-align: center;
}

@media (max-width: 1200px) {
  .title-theme {
    font-size: $fs-18;
  }
}

@media(max-width: 991px) {
  .title-theme {
    font-size: 17px;
  }
}

#cookie_note {
  display: none;
}

@media (min-width: 576px) {
  #cookie_note.show {
    display: flex;
  }
}

@media (max-width: 575px) {
  #cookie_note.show {
    display: block;
    text-align: left;
  }
}