.slotholder {
  filter: none !important;
}

.tp-half-one {
  opacity: 1 !important;
  visibility: visible !important;
  transform: inherit !important;
}

/*slider 01*/
#rev_slider_267_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

#rev_slider_267_1 .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1)
}

#rev_slider_267_1 .zeus.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative
}

#rev_slider_267_1 .zeus.tparrows.tp-leftarrow:before {
  content: "\e824"
}

#rev_slider_267_1 .zeus.tparrows.tp-rightarrow:before {
  content: "\e825"
}

#rev_slider_267_1 .zeus .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%
}

#rev_slider_267_1 .zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  -webkit-transform: translatex(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s
}

#rev_slider_267_1 .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
  -webkit-transform: translatex(100%)
}

#rev_slider_267_1 .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  -webkit-transform: translatex(0);
  opacity: 1
}

#rev_slider_267_1 .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1
}

/*slider 02*/
#rev_slider_268_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

#rev_slider_268_1 .dione.tparrows {
  color: #000;
  height: 100%;
  width: 90px;
  background: transparent;
  background: rgba(0, 0, 0, 0);
  line-height: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

#rev_slider_268_1 .dione.tparrows:hover {
  background: rgba(0, 0, 0, 0.45)
}

#rev_slider_268_1 .dione .tp-arr-imgwrapper {
  width: 90px;
  left: 0px;
  position: absolute;
  height: 100%;
  top: 0px;
  overflow: hidden
}

#rev_slider_268_1 .dione.tp-rightarrow .tp-arr-imgwrapper {
  left: auto;
  right: 0px
}

#rev_slider_268_1 .dione .tp-arr-imgholder {
  background-position: center center;
  background-size: cover;
  width: 90px;
  height: 100%;
  top: 0px;
  visibility: hidden;
  transform: translatex(-50px);
  -webkit-transform: translatex(-50px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  opacity: 0;
  left: 0px
}

#rev_slider_268_1 .dione.tparrows.tp-rightarrow .tp-arr-imgholder {
  right: 0px;
  left: auto;
  transform: translatex(50px);
  -webkit-transform: translatex(50px)
}

#rev_slider_268_1 .dione.tparrows:before {
  color: $black;
  position: absolute;
  line-height: 30px;
  margin-left: -22px;
  top: 50%;
  left: 50%;
  font-size: 30px;
  margin-top: -15px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

#rev_slider_268_1 .dione.tparrows.tp-rightarrow:before {
  margin-left: 6px
}

#rev_slider_268_1 .dione.tparrows:hover:before {
  transform: translatex(-20px);
  -webkit-transform: translatex(-20px);
  opacity: 0
}

#rev_slider_268_1 .dione.tparrows.tp-rightarrow:hover:before {
  transform: translatex(20px);
  -webkit-transform: translatex(20px)
}

#rev_slider_268_1 .dione.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  opacity: 1;
  visibility: visible
}

/*slider 03*/
#rev_slider_269_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

/*slider 04*/
#rev_slider_270_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.hermes.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 100
}

.hermes.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 110px;
  text-align: center;
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

.hermes.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.hermes.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.hermes.tparrows.tp-leftarrow:hover:before {
  transform: translatex(-20px);
  -webkit-transform: translatex(-20px);
  opacity: 0
}

.hermes.tparrows.tp-rightarrow:hover:before {
  transform: translatex(20px);
  -webkit-transform: translatex(20px);
  opacity: 0
}

.hermes .tp-arr-allwrapper {
  overflow: hidden;
  position: absolute;
  width: 180px;
  height: 140px;
  top: 0px;
  left: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  -webkit-perspective: 1000px;
  perspective: 1000px
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
  right: 0px;
  left: auto
}

.hermes.tparrows:hover .tp-arr-allwrapper {
  visibility: visible
}

.hermes .tp-arr-imgholder {
  width: 180px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 110px;
  transform: translatex(-180px);
  -webkit-transform: translatex(-180px);
  transition: all 0.3s;
  transition-delay: 0.3s
}

.hermes.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(180px);
  -webkit-transform: translatex(180px)
}

.hermes.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px)
}

.hermes .tp-arr-titleholder {
  top: 110px;
  width: 180px;
  text-align: left;
  display: block;
  padding: 0px 10px;
  line-height: 30px;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: rgb(255, 255, 255);
  font-weight: 600;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotatex(-90deg);
  transform: rotatex(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-sizing: border-box
}

.hermes.tparrows:hover .tp-arr-titleholder {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotatex(0deg);
  transform: rotatex(0deg)
}

/*slider 05*/
#rev_slider_271_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.erinyen.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%
}

.erinyen.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative
}

.erinyen.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.erinyen.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.erinyen .tp-title-wrap {
  position: absolute;
  z-index: 1;
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  min-height: 70px;
  line-height: 70px;
  top: 0px;
  margin-left: 0px;
  border-radius: 35px;
  overflow: hidden;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  visibility: hidden;
  opacity: 0
}

.erinyen.tparrows:hover .tp-title-wrap {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible
}

.erinyen.tp-rightarrow .tp-title-wrap {
  right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-transform-origin: 100% 50%;
  border-radius: 35px;
  padding-right: 20px;
  padding-left: 10px
}

.erinyen.tp-leftarrow .tp-title-wrap {
  padding-left: 20px;
  padding-right: 10px
}

.erinyen .tp-arr-titleholder {
  letter-spacing: 3px;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: translatex(200px);
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 13px;
  line-height: 70px;
  white-space: nowrap;
  padding: 0px 20px;
  margin-left: 11px;
  opacity: 0
}

.erinyen .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover
}

.erinyen .tp-arr-img-over {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.51)
}

.erinyen.tp-rightarrow .tp-arr-titleholder {
  transform: translatex(-200px);
  margin-left: 0px;
  margin-right: 11px
}

.erinyen.tparrows:hover .tp-arr-titleholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition-delay: 0.1s;
  opacity: 1
}

/*slider 06*/
#rev_slider_272_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.custom.tparrows {
  cursor: pointer;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 100
}

.custom.tparrows:hover {
  background: #000
}

.custom.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: #fff;
  display: block;
  line-height: 40px;
  text-align: center
}

.custom.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.custom.tparrows.tp-rightarrow:before {
  content: "\e825"
}

/*slider 07*/
#rev_slider_273_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.erinyen.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%
}

.erinyen.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative
}

.erinyen.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.erinyen.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.erinyen .tp-title-wrap {
  position: absolute;
  z-index: 1;
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  min-height: 70px;
  line-height: 70px;
  top: 0px;
  margin-left: 0px;
  border-radius: 35px;
  overflow: hidden;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  visibility: hidden;
  opacity: 0
}

.erinyen.tparrows:hover .tp-title-wrap {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  visibility: visible
}

.erinyen.tp-rightarrow .tp-title-wrap {
  right: 0px;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-transform-origin: 100% 50%;
  border-radius: 35px;
  padding-right: 20px;
  padding-left: 10px
}

.erinyen.tp-leftarrow .tp-title-wrap {
  padding-left: 20px;
  padding-right: 10px
}

.erinyen .tp-arr-titleholder {
  letter-spacing: 3px;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: translatex(200px);
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 13px;
  line-height: 70px;
  white-space: nowrap;
  padding: 0px 20px;
  margin-left: 11px;
  opacity: 0
}

.erinyen .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover
}

.erinyen .tp-arr-img-over {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.51)
}

.erinyen.tp-rightarrow .tp-arr-titleholder {
  transform: translatex(-200px);
  margin-left: 0px;
  margin-right: 11px
}

.erinyen.tparrows:hover .tp-arr-titleholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition-delay: 0.1s;
  opacity: 1
}

/*slider 10*/
#rev_slider_275_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

#rev_slider_275_1 .zeus .tp-bullet {
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  width: 13px;
  height: 13px;
  border: 2px solid rgb(255, 255, 255)
}

#rev_slider_275_1 .zeus .tp-bullet:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease
}

#rev_slider_275_1 .zeus .tp-bullet:hover:after,
#rev_slider_275_1 .zeus .tp-bullet.selected:after {
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

#rev_slider_275_1 .zeus .tp-bullet-image,
#rev_slider_275_1 .zeus .tp-bullet-imageoverlay {
  width: 135px;
  height: 60px;
  position: absolute;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  bottom: 13px;
  margin-bottom: 10px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  box-sizing: border-box;
  background-size: cover;
  background-position: center center;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 4px
}

#rev_slider_275_1 .zeus .tp-bullet-title,
#rev_slider_275_1 .zeus .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%)
}

#rev_slider_275_1 .zeus .tp-bullet-title {
  color: rgb(255, 255, 255);
  text-align: center;
  line-height: 15px;
  font-size: 13px;
  font-weight: 600;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  bottom: 45px;
  width: 135px;
  vertical-align: middle
}

#rev_slider_275_1 .zeus .tp-bullet:hover .tp-bullet-title,
#rev_slider_275_1 .zeus .tp-bullet:hover .tp-bullet-image,
#rev_slider_275_1 .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px) translateX(-50%);
  transform: translateY(0px) translateX(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet-image,
#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
  bottom: auto;
  margin-right: 10px;
  margin-bottom: 0px;
  right: 13px;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet-title,
#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet-title {
  bottom: auto;
  right: 100%;
  margin-right: 10px
}

#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-title,
#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image,
#rev_slider_275_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-imageoverlay {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-image,
#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
  bottom: auto;
  margin-left: 10px;
  margin-bottom: 0px;
  left: 13px;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet:hover .tp-bullet-image {
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title,
#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateX(0px) translateY(-50%);
  -webkit-transform: translateX(0px) translateY(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
  bottom: auto;
  left: 100%;
  margin-left: 10px
}

#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-image,
#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
  bottom: auto;
  top: 13px;
  margin-top: 10px;
  margin-bottom: 0px;
  left: 0px;
  transform: translateY(0px) translateX(-50%);
  -webkit-transform: translateX(0px) translateX(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-image {
  transform: scale(1) translateY(0px) translateX(-50%);
  -webkit-transform: scale(1) translateY(0px) translateX(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title,
#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transform: translateY(0px) translateX(-50%);
  -webkit-transform: translateY(0px) translateX(-50%)
}

#rev_slider_275_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
  bottom: auto;
  top: 13px;
  margin-top: 20px
}

/*index-cafe*/
#rev_slider_9_1 .uranus.tparrows {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0)
}

#rev_slider_9_1 .uranus.tparrows:before {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 40px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

#rev_slider_9_1 .uranus.tparrows:hover:before {
  opacity: 0.75
}

/*index-construction*/
#rev_slider_19_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

#rev_slider_19_1 .metis.tparrows {
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box
}

#rev_slider_19_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75)
}

#rev_slider_19_1 .metis.tparrows:before {
  color: rgb(0, 0, 0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

#rev_slider_19_1 .metis.tparrows:hover:before {
  transform: scale(1.5)
}

/*index-consulting*/
#rev_slider_264_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.hermes.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 100
}

.hermes.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 110px;
  text-align: center;
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

.hermes.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.hermes.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.hermes.tparrows.tp-leftarrow:hover:before {
  transform: translatex(-20px);
  -webkit-transform: translatex(-20px);
  opacity: 0
}

.hermes.tparrows.tp-rightarrow:hover:before {
  transform: translatex(20px);
  -webkit-transform: translatex(20px);
  opacity: 0
}

.hermes .tp-arr-allwrapper {
  overflow: hidden;
  position: absolute;
  width: 180px;
  height: 140px;
  top: 0px;
  left: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  -webkit-perspective: 1000px;
  perspective: 1000px
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
  right: 0px;
  left: auto
}

.hermes.tparrows:hover .tp-arr-allwrapper {
  visibility: visible
}

.hermes .tp-arr-imgholder {
  width: 180px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 110px;
  transform: translatex(-180px);
  -webkit-transform: translatex(-180px);
  transition: all 0.3s;
  transition-delay: 0.3s
}

.hermes.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(180px);
  -webkit-transform: translatex(180px)
}

.hermes.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px)
}

.hermes .tp-arr-titleholder {
  top: 110px;
  width: 180px;
  text-align: left;
  display: block;
  padding: 0px 10px;
  line-height: 30px;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: rgb(255, 255, 255);
  font-weight: 600;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotatex(-90deg);
  transform: rotatex(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-sizing: border-box
}

.hermes.tparrows:hover .tp-arr-titleholder {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotatex(0deg);
  transform: rotatex(0deg)
}

/*index-interior-design*/
#rev_slider_261_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

.hebe.tparrows {
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100
}

.hebe.tparrows:hover {
}

.hebe.tparrows:before {
  font-family: "revicons";
  font-size: 30px;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  line-height: 70px;
  text-align: center;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 2;
  position: relative;
  background: rgba(255, 255, 255, 1);
  min-width: 70px;
  min-height: 70px
}

.hebe.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.hebe.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.hebe.tparrows:hover:before {
  color: #000
}

.tp-title-wrap {
  position: absolute;
  z-index: 0;
  display: inline-block;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  min-height: 60px;
  line-height: 60px;
  top: -10px;
  margin-left: 0px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transform: scalex(0);
  -webkit-transform: scalex(0);
  transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%
}

.hebe.tp-rightarrow .tp-title-wrap {
  right: 0px;
  -webkit-transform-origin: 100% 50%
}

.hebe.tparrows:hover .tp-title-wrap {
  transform: scalex(1);
  -webkit-transform: scalex(1)
}

.hebe .tp-arr-titleholder {
  position: relative;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 12px;
  line-height: 90px;
  white-space: nowrap;
  padding: 0px 20px 0px 90px
}

.hebe.tp-rightarrow .tp-arr-titleholder {
  margin-left: 0px;
  padding: 0px 90px 0px 20px
}

.hebe.tparrows:hover .tp-arr-titleholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition-delay: 0.1s;
  opacity: 1
}

.hebe .tp-arr-imgholder {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 100%;
  display: block;
  background-size: cover;
  background-position: center center;
  top: 0px;
  right: -90px
}

.hebe.tp-rightarrow .tp-arr-imgholder {
  right: auto;
  left: -90px
}

/*index-medical*/
#rev_slider_289_1 .metis.tparrows {
  background: rgba(255, 255, 255, 1);
  padding: 10px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 60px;
  height: 60px;
  box-sizing: border-box
}

#rev_slider_289_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.75)
}

#rev_slider_289_1 .metis.tparrows:before {
  color: rgb(0, 0, 0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

#rev_slider_289_1 .metis.tparrows:hover:before {
  transform: scale(1.5)
}

/*index shop 02*/
.hermes.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 100
}

.hermes.tparrows:before {
  font-family: "revicons";
  font-size: 15px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 110px;
  text-align: center;
  transform: translatex(0px);
  -webkit-transform: translatex(0px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s
}

.hermes.tparrows.tp-leftarrow:before {
  content: "\e824"
}

.hermes.tparrows.tp-rightarrow:before {
  content: "\e825"
}

.hermes.tparrows.tp-leftarrow:hover:before {
  transform: translatex(-20px);
  -webkit-transform: translatex(-20px);
  opacity: 0
}

.hermes.tparrows.tp-rightarrow:hover:before {
  transform: translatex(20px);
  -webkit-transform: translatex(20px);
  opacity: 0
}

.hermes .tp-arr-allwrapper {
  overflow: hidden;
  position: absolute;
  width: 180px;
  height: 140px;
  top: 0px;
  left: 0px;
  visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  -webkit-perspective: 1000px;
  perspective: 1000px
}

.hermes.tp-rightarrow .tp-arr-allwrapper {
  right: 0px;
  left: auto
}

.hermes.tparrows:hover .tp-arr-allwrapper {
  visibility: visible
}

.hermes .tp-arr-imgholder {
  width: 180px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 110px;
  transform: translatex(-180px);
  -webkit-transform: translatex(-180px);
  transition: all 0.3s;
  transition-delay: 0.3s
}

.hermes.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(180px);
  -webkit-transform: translatex(180px)
}

.hermes.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0px);
  -webkit-transform: translatex(0px)
}

.hermes .tp-arr-titleholder {
  top: 110px;
  width: 180px;
  text-align: left;
  display: block;
  padding: 0px 10px;
  line-height: 30px;
  background: #000;
  background: rgba(0, 0, 0, 0.75);
  color: rgb(255, 255, 255);
  font-weight: 600;
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: rotatex(-90deg);
  transform: rotatex(-90deg);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  box-sizing: border-box
}

.hermes.tparrows:hover .tp-arr-titleholder {
  -webkit-transition-delay: 0.6s;
  transition-delay: 0.6s;
  -webkit-transform: rotatex(0deg);
  transform: rotatex(0deg)
}

/*index gym*/
#rev_slider_260_1 .zeus.tparrows {
  cursor: pointer;
  min-width: 70px;
  min-height: 70px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1)
}

#rev_slider_260_1 .zeus.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 70px;
  text-align: center;
  z-index: 2;
  position: relative
}

#rev_slider_260_1 .zeus.tparrows.tp-leftarrow:before {
  content: "\e824"
}

#rev_slider_260_1 .zeus.tparrows.tp-rightarrow:before {
  content: "\e825"
}

#rev_slider_260_1 .zeus .tp-title-wrap {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  border-radius: 50%
}

#rev_slider_260_1 .zeus .tp-arr-imgholder {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  transform: translatex(-100%);
  -webkit-transform: translatex(-100%);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s
}

#rev_slider_260_1 .zeus.tp-rightarrow .tp-arr-imgholder {
  transform: translatex(100%);
  -webkit-transform: translatex(100%)
}

#rev_slider_260_1 .zeus.tparrows:hover .tp-arr-imgholder {
  transform: translatex(0);
  -webkit-transform: translatex(0);
  opacity: 1
}

#rev_slider_260_1 .zeus.tparrows:hover .tp-title-wrap {
  transform: scale(1);
  -webkit-transform: scale(1);
  opacity: 1
}

/*mobile app*/
.hesperiden.tparrows {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  position: absolute;
  display: block;
  z-index: 100;
  border-radius: 50%
}

.hesperiden.tparrows:hover {
  background: rgba(0, 0, 0, 1)
}

.hesperiden.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: rgb(255, 255, 255);
  display: block;
  line-height: 40px;
  text-align: center
}

.hesperiden.tparrows.tp-leftarrow:before {
  content: "\e82c";
  margin-left: -3px
}

.hesperiden.tparrows.tp-rightarrow:before {
  content: "\e82d";
  margin-right: -3px
}

/*construction*/
#rev_slider_263_1_wrapper .tp-loader.spinner2 {
  background-color: #FFFFFF !important;
}

/*spa*/
#rev_slider_292_1_wrapper .tp-loader.spinner4 {
  background-color: #e9457a !important;
}
