
/*Font family*/
$font-base: 	'Montserrat', sans-serif;
$font-hedding:  'Montserrat', sans-serif;
$font-dosis:  'Dosis', sans-serif;

/*Colors*/
$theme-color:    #84ba3f;
$text-black:     #363636;
$text-gray: 	 #999999;
$text-dark-gray: #626262;

$white:  		#ffffff;
$black: 	    #262626;
$black-02: 	    #282828;
$gray-bg:       #f7f7f7;
$dark-gray-bg:  #666666;
$dark-theme-bg: #050801;
$gray-bg-02: 	#f6f7f8;

$border-color: #eeeeee;
$border-table: #e0e0e0;

/*font size*/
$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-30: 30px;
$fs-32: 32px;
$fs-34: 34px;
$fs-36: 36px;
$fs-38: 38px;
$fs-40: 40px;
$fs-42: 42px;
$fs-44: 44px;
$fs-46: 46px;
$fs-48: 48px;
$fs-50: 50px;
$fs-52: 52px;
$fs-54: 54px;
$fs-56: 56px;
$fs-58: 58px;
$fs-60: 60px;
$fs-70: 70px;
$fs-80: 80px;
$fs-90: 90px;
$fs-100: 100px;

/*Font weights*/
$fw-1: 100;
$fw-2: 200;
$fw-3: 300;
$fw-4: 400;
$fw-5: 500;
$fw-6: 600;
$fw-7: 700;
$fw-8: 800;
$fw-9: 900;

/*line-height*/
$l-height-14: 14px;
$l-height-15: 15px;
$l-height-18: 18px;
$l-height-20: 20px;
$l-height-22: 22px;
$l-height-24: 24px;
$l-height-26: 26px;
$l-height-28: 28px;
$l-height-30: 30px;
$l-height-32: 32px;
$l-height-34: 34px;
$l-height-36: 36px;
$l-height-38: 38px;
$l-height-40: 40px;
$l-height-45: 45px;
$l-height-50: 50px;
$l-height-55: 55px;
$l-height-60: 60px;
$l-height-65: 65px;
$l-height-70: 70px;
$l-height-75: 75px;
$l-height-80: 80px;
$l-height-85: 85px;
$l-height-90: 90px;
$l-height-95: 95px;
$l-height-100: 100px;

/*Responsive widths*/
$mobile-portrait-sm: 360px;
$mobile-portrait: 480px;
$mobile-landscape: 577px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$laptop: 1200px;
$desktop: 1440px;
$desktop-lg: 1600px;
