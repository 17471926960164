@import "variables";

@import "plugins/bootstrap.min";
@import "plugins/mega_menu";
@import "plugins/animate";
@import "plugins/font-awesome.min";
@import "plugins/bootstrap-datetimepicker.min";
@import "plugins/dataTables.bootstrap4.min";
@import "plugins/magnific-popup";
@import "plugins/mediaelementplayer";
@import "plugins/owl.carousel.min";
@import "plugins/slick-theme";
@import "plugins/themify-icons";
@import "plugins/fullcalendar";

@import "typography";

@import "shortcodes/accordion";
@import "shortcodes/action-box";
@import "shortcodes/blockquote";
@import "shortcodes/bootstrap_typography";
@import "shortcodes/button";
@import "shortcodes/clients";
@import "shortcodes/contact-form";
@import "shortcodes/countdown";
@import "shortcodes/counter";
@import "shortcodes/divider";
@import "shortcodes/dropcap";
@import "shortcodes/feature_text";
@import "shortcodes/list-style";
@import "shortcodes/nice_select";
@import "shortcodes/owl_carousel";
@import "shortcodes/page_title";
@import "shortcodes/pie_chart";
@import "shortcodes/pricing";
@import "shortcodes/progress_bar";
@import "shortcodes/section_title";
@import "shortcodes/sectionSocial";
@import "shortcodes/social_icons";
@import "shortcodes/tabs";
@import "shortcodes/team";
@import "shortcodes/testimonial";
@import "shortcodes/tooltip";
@import "shortcodes/side_panel";
@import "shortcodes/onload_modal";
@import "shortcodes/youtubeVideo";
@import "shortcodes/bigImage";

@import "header";
@import "helpers";
@import "layout";
/*@import "mixins";*/
@import "revslider-button";
@import "shop";
@import "table";
@import "list";
@import "responsive";
@import "custom";

@import "plugins/skin-steelblue";
