.section-social {
  width: 100%;
  height: 300px;
  background-color: #354A6B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 40px 0;
}

h2 {
  color: #ffffff;
  margin: 0;
  padding: 10px 0;
  text-align: center;
}

.social-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.social-container i {
  font-size: 30px;
  color: #ffffff;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.action-button {
  background-color: #fff;
  color: #354A6B;
  border: 2px solid #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.action-button:hover {
  background-color: #354A6B;
  color: #fff;
}

@media (max-width: 768px) {
  .main-social {
    height: auto;
    padding: 10px;
    gap: 40px;
  }

  h2 {
    font-size: 18px;
    padding: 5px 0;
  }

  .social-container {
    gap: 60px;
  }

  .social-container i {
    font-size: 25px;
  }

  .button-container {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .main-social {
    gap: 30px;
    padding: 10px;
  }

  .social-container {
    gap: 40px;
  }

  .social-container i {
    font-size: 20px;
  }

  .action-button {
    padding: 8px 16px;
  }

  .button-container {
    margin-bottom: 15px;
  }
}
