
.testimonial {
	position: relative;
	background: #f9f9f9;
	padding: 85px 50px 45px 70px;
	margin: 0 0px 4px 0;
	margin-top: 45px;
	color: #656565;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
}
.testimonial-avatar {
	position: absolute;
	left: 50%;
	margin-left: -40px;
	top: -45px;
	width: 100px;
	height: 100px;
	img {
		border: 4px solid $theme-color;
		max-width: 100%;
		border-radius: 50%;
	}
}
.testimonial-info {
	position: relative;
	&:before {
		position: absolute;
		top: -15px;
		left: -35px;
		color: $theme-color;
		content: "\201C";
		font-family: Georgia, serif;
		font-size: $fs-100;
	}
}
.author-info {
	font-size: 16px;
	color: $black;
	text-transform: uppercase;
	margin-top: 20px;
	position: relative;
	strong {
		font-weight: $fw-5;
	}
	span {
		font-weight: normal;
		color: $theme-color;
		text-transform: none;
	}
}
.testimonial.light {
	background: $white;
}
.text-white {
	.testimonial {
		color: $white;
		.author-info {
			color: $white;
		}
	}
}
.testimonial.text-white {
	color: $white;
}
.testimonial.clean {
	background: none;
	box-shadow: none;
	padding-bottom: 0;
	padding: 0 50px 25px 70px;
	margin-top: 0;
	.testimonial-avatar {
		position: relative;
		top: 0;
		margin-bottom: 20px;
		margin-left: -65px;
	}
}
.testimonial.text-white.clean {
	.author-info {
		color: $theme-color;
		span {
			color: $white;
		}
		&:before {
			background: $white;
		}
	}
	.testimonial-info {
		&:before {
			color: $white;
		}
	}
}
.theme-bg {
	.testimonial.clean {
		padding-bottom: 10px;
		margin-bottom: 0;
		.author-info {
			color: $black;
		}
		.testimonial-avatar {
			img {
				border-color: $white;
			}
		}
	}
}
.testimonial.left_pos {
	margin-left: 40px;
	padding: 60px 40px 40px 100px;
	text-align: left;
	.testimonial-avatar {
		left: 0;
		margin-top: -50px;
		top: 50%;
	}
	.author-info {
		&:before {
			margin-left: 0;
			left: 0;
		}
	}
}
.testimonial.bottom_pos {
	padding: 60px 40px 40px 60px;
	text-align: left;
	margin-top: 0;
	margin-bottom: 70px;
	.testimonial-avatar {
		bottom: -50px;
		top: inherit;
		left: 0px;
		margin-left: 50px;
	}
	.author-info {
		margin-left: 125px;
		&:before {
			margin-left: 0;
			left: 0;
		}
	}
}
.testimonial.theme-bg {
	background: $theme-color;
	color: $white;
	.testimonial-info {
		&:before {
			color: $white;
		}
	}
	.author-info {
		span {
			color: $white;
		}
		&:before {
			background: $white;
		}
	}
	.testimonial-avatar {
		img {
			border-color: $white;
			color: $white;
		}
	}
}

